export const masterNodeScheme = {
    http: "http",
    https: "https"
};

export const masterNodeUrls = {
    local: "localhost:4200",
    develop: "identity-dev.ces-cloud.de",
    production: "identity.cesentry.eu"
};

export const masterNodeAPIKeys = {
    local: "1234567890",
    develop: "1234567890",
    production: "1234567890"
};

export const masterNodeBasePath = {
  local: "/CESMaster/api/v1/",
  develop: "/CESMaster/api/v1/",
  production: "/CESMaster/api/v1/"
}
