import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import {ClientDto} from "../../../../shared/entities/client/ClientDto";
import {ClientRequestDto} from "../../../../shared/entities/client/ClientRequestDto";
import {Observable} from "rxjs";
import {ToastService} from "../../../../shared/notification/toast/toast.service";

export class ClientService extends CESService {

  constructor(router: Router, httpClient: HttpClient, notification: ToastService) {
    super(router, httpClient, notification)
  }

  async getClient(): Promise<Observable<ClientDto>> {
    return this.httpClient.get<ClientDto>(await this.buildUrl(undefined, '/client'));
  }

  async updateClient(clientDto: ClientDto): Promise<Observable<ClientRequestDto | undefined>> {
    return this.httpClient.patch<ClientRequestDto | undefined>(await this.buildUrl(undefined, '/client'), clientDto);
  }
}
