import {ClientVersionDto} from "../Version/ClientVersionDto";

export class StatusResponseDto extends ClientVersionDto {
  currentState: string;
  currentStateNumber: number;
  error: string;
  usb: boolean;

  constructor(clientIdent: string, platform: string, version: string, currentState: string, currentStateNumber: number, error: string, usb: boolean) {
    super(clientIdent, platform, version);
    this.currentState = currentState;
    this.currentStateNumber = currentStateNumber;
    this.error = error;
    this.usb = usb;
  }

  static new(client: ClientVersionDto, currentState: string, currentStateNumber: number, error: string, usb: boolean): StatusResponseDto {
    return new this(client.clientIdent, client.platform, client.version, currentState, currentStateNumber, error, usb);
  }

  static emptyStatusResponseDto(): StatusResponseDto {
    return StatusResponseDto.new(ClientVersionDto.emptyClientVersionDto(), "", 0, "",false);
  }

  get clientVersionDto(): ClientVersionDto {
    return new ClientVersionDto(this.clientIdent, this.platform, this.version);
  }

}
