import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'valueFormatter'
})
export class ValueFormatterPipe implements PipeTransform {

  transform(value: number, formatting: FormatEntry[]): string {

    formatting.sort(this.sortByValue)

    for(let i=0; i<formatting.length; i++) {
      if(value < formatting[i].upperBound) {
        return formatting[i].format
      }
    }

    return formatting[formatting.length-1].format;
  }

  private sortByValue(a: FormatEntry, b: FormatEntry): number {
    return a.upperBound - b.upperBound
  }
}

/** Contains upper bound and formatting options */
export interface FormatEntry {
  /** Upper bound to be checked to apply format if actual value is smaller (<) */
  upperBound: number,
  /** Class names or style options to be returned by pipe */
  format: string
}

export function FormatEntriesFrom(mapping: {[value: number]:string}): FormatEntry[] {
  let entries: FormatEntry[] = []

  for(let key in mapping) {
    let value = parseFloat(key)
    entries.push({upperBound: value, format: mapping[value]})
  }

  return entries
}
