import {UserSessionInfoDto} from "./user/UserSessionInfoDto";

export class RefreshTokenDto {
  userInfo:  UserSessionInfoDto | undefined;
  url: string;
  apiKey: string;
  durationToWait: number;

  constructor(userInfo: UserSessionInfoDto | undefined, url: string, apiKey: string, durationToWait: number) {
    this.userInfo = userInfo;
    this.url = url;
    this.apiKey = apiKey;
    this.durationToWait = durationToWait;
  }

  static emptyRefreshTokenDto(): RefreshTokenDto {
    return new this(undefined, "", "", 240);
  }

}
