import {LookupMapping} from "./lookup";

export const TIMEZONES: LookupMapping[] = [
  {
    "id": 1,
    "value": "Etc/GMT-12"
  },
  {
    "id": 2,
    "value": "Pacific/Midway"
  },
  {
    "id": 3,
    "value": "US/Hawaii"
  },
  {
    "id": 4,
    "value": "US/Alaska"
  },
  {
    "id": 5,
    "value": "US/Pacific"
  },
  {
    "id": 6,
    "value": "US/Mountain"
  },
  {
    "id": 7,
    "value": "US/Arizona"
  },
  {
    "id": 8,
    "value": "US/Central"
  },
  {
    "id": 9,
    "value": "Canada/Saskatchewan"
  },
  {
    "id": 10,
    "value": "America/Chicago"
  },
  {
    "id": 11,
    "value": "America/Belize"
  },
  {
    "id": 12,
    "value": "US/Eastern"
  },
  {
    "id": 13,
    "value": "US/East-Indiana"
  },
  {
    "id": 14,
    "value": "America/Bogota"
  },
  {
    "id": 15,
    "value": "Canada/Atlantic"
  },
  {
    "id": 16,
    "value": "America/Caracas"
  },
  {
    "id": 17,
    "value": "America/Santiago"
  },
  {
    "id": 18,
    "value": "Canada/Newfoundland"
  },
  {
    "id": 19,
    "value": "America/Sao_Paulo"
  },
  {
    "id": 20,
    "value": "America/Buenos_Aires"
  },
  {
    "id": 21,
    "value": "America/Godthab"
  },
  {
    "id": 22,
    "value": "America/Nuuk"
  },
  {
    "id": 23,
    "value": "Atlantic/Azores"
  },
  {
    "id": 24,
    "value": "Atlantic/Cape_Verde"
  },
  {
    "id": 25,
    "value": "Europe/Dublin"
  },
  {
    "id": 26,
    "value": "Africa/Casablanca"
  },
  {
    "id": 27,
    "value": "Europe/Belgrade"
  },
  {
    "id": 28,
    "value": "Europe/Sarajevo"
  },
  {
    "id": 29,
    "value": "Europe/Brussels"
  },
  {
    "id": 30,
    "value": "Europe/Amsterdam"
  },
  {
    "id": 31,
    "value": "Africa/Bangui"
  },
  {
    "id": 32,
    "value": "Europe/Bucharest"
  },
  {
    "id": 33,
    "value": "Africa/Cairo"
  },
  {
    "id": 34,
    "value": "Europe/Helsinki"
  },
  {
    "id": 35,
    "value": "Europe/Athens"
  },
  {
    "id": 36,
    "value": "Asia/Jerusalem"
  },
  {
    "id": 37,
    "value": "Africa/Harare"
  },
  {
    "id": 38,
    "value": "Europe/Moscow"
  },
  {
    "id": 39,
    "value": "Asia/Kuwait"
  },
  {
    "id": 40,
    "value": "Africa/Nairobi"
  },
  {
    "id": 41,
    "value": "Asia/Baghdad"
  },
  {
    "id": 42,
    "value": "Asia/Tehran"
  },
  {
    "id": 43,
    "value": "Asia/Dubai"
  },
  {
    "id": 44,
    "value": "Asia/Baku"
  },
  {
    "id": 45,
    "value": "Asia/Kabul"
  },
  {
    "id": 46,
    "value": "Asia/Yekaterinburg"
  },
  {
    "id": 47,
    "value": "Asia/Karachi"
  },
  {
    "id": 48,
    "value": "Asia/Kolkata"
  },
  {
    "id": 49,
    "value": "Asia/Katmandu"
  },
  {
    "id": 50,
    "value": "Asia/Dhaka"
  },
  {
    "id": 51,
    "value": "Asia/Colombo"
  },
  {
    "id": 52,
    "value": "Asia/Almaty"
  },
  {
    "id": 53,
    "value": "Asia/Rangoon"
  },
  {
    "id": 54,
    "value": "Asia/Bangkok"
  },
  {
    "id": 55,
    "value": "Asia/Krasnoyarsk"
  },
  {
    "id": 56,
    "value": "Asia/Shanghai"
  },
  {
    "id": 57,
    "value": "Asia/Kuala_Lumpur"
  },
  {
    "id": 58,
    "value": "Asia/Taipei"
  },
  {
    "id": 59,
    "value": "Australia/Perth"
  },
  {
    "id": 60,
    "value": "Asia/Irkutsk"
  },
  {
    "id": 61,
    "value": "Asia/Seoul"
  },
  {
    "id": 62,
    "value": "Asia/Tokyo"
  },
  {
    "id": 63,
    "value": "Asia/Yakutsk"
  },
  {
    "id": 64,
    "value": "Australia/Darwin"
  },
  {
    "id": 65,
    "value": "Australia/Adelaide"
  },
  {
    "id": 66,
    "value": "Australia/Canberra"
  },
  {
    "id": 67,
    "value": "Australia/Brisbane"
  },
  {
    "id": 68,
    "value": "Australia/Hobart"
  },
  {
    "id": 69,
    "value": "Asia/Vladivostok"
  },
  {
    "id": 70,
    "value": "Pacific/Guam"
  },
  {
    "id": 71,
    "value": "Asia/Magadan"
  },
  {
    "id": 72,
    "value": "Pacific/Fiji"
  },
  {
    "id": 73,
    "value": "Pacific/Auckland"
  }
];

export const DST: LookupMapping[] = [
  {
    "id": 1,
    "value": "summer"
  },
  {
    "id": 2,
    "value": "winter"
  }
];
