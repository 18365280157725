export class BindingProcessError {
  private _blockTillReset: boolean;
  private _error: string;
  private _msg: string;
  private _code: number | string;

  constructor(blockTillReset: boolean, error: string, msg: string, code: string | number | undefined) {
    this._blockTillReset = blockTillReset;
    this._code = code == undefined ? 0 : code;
    this._error = error;
    this._msg = msg;
  }

  static emptyBindingProcessError(): BindingProcessError {
    return new this(false, '', '', undefined);
  }

  get blockTillReset(): boolean {
    return this._blockTillReset;
  }

  set blockTillReset(value: boolean) {
    this._blockTillReset = value;
  }

  get error(): string {
    return this._error;
  }

  set error(value: string) {
    this._error = value;
  }

  get msg(): string {
    return this._msg;
  }

  set msg(value: string) {
    this._msg = value;
  }

  get code(): number | string {
    return this._code;
  }

  set code(value: number | string) {
    this._code = value;
  }

}
