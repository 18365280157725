import {LookupMapping} from "./lookup";

export const KEY_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "operating_key"
  },
  {
    "id": 2,
    "value": "app_key"
  }
];
