import {environment} from "../../../../../environments/environment";
import {NodeConfiguration, NodeConfigurationScheme} from "../../../interfaces/nodeConfiguration";
import {LocalStorageSchemaService} from "../../json-schema-service/local-storage-schema.service";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class NodeManager {
  private static instance: NodeManager;
  public localStorageDataNode: string = "data-node-config";
  private schemaService: LocalStorageSchemaService;
  private _currentUsedSystem: string = '';

  static getInstance(): NodeManager {
    if (this.instance == null) {
      this.instance = new NodeManager();
    }
    return this.instance;
  }

  private constructor() {
    this.schemaService = new LocalStorageSchemaService();
  }

  get currentUsedSystem(): string {
    return this._currentUsedSystem;
  }

  set currentUsedSystem(value: string) {
    this._currentUsedSystem = value;
  }

  get masterNodeConfiguration(): NodeConfiguration {
    return {
      scheme: environment.masterNodeScheme,
      host: environment.masterNodeUrl,
      path: environment.masterNodeBasePath,
      apiKey: environment.masterNodeKey,
      isDataNode: false,
    } as NodeConfiguration;
  }

  get dataNodeConfiguration(): NodeConfiguration | undefined {
    const storage = this.schemaService.localStorage(this.localStorageDataNode);
    if (storage.isValid) {
      return NodeConfigurationScheme(storage.data as NodeConfiguration);
    }
    this.currentUsedSystem = '';
    return undefined;
  }

  getDataNodeUrl(path: string): string {
    if (!path.startsWith('/') && !this.dataNodeConfiguration?.host!.endsWith('/')) {
      path = `/${path}`;
    } else if (path.startsWith('/') && this.dataNodeConfiguration?.host!.endsWith('/')) {
      path = path.substring(1);
    }
    return `${this.dataNodeConfiguration?.scheme!}://${this.dataNodeConfiguration?.host!}${this.dataNodeConfiguration?.path}${path}`;
  }

  getBaseUrl(path: string): string {
    if (!path.startsWith('/') && !this.dataNodeConfiguration?.host!.endsWith('/')) {
      path = `/${path}`;
    } else if (path.startsWith('/') && this.dataNodeConfiguration?.host!.endsWith('/')) {
      path = path.substring(1);
    }
    return `${this.dataNodeConfiguration?.scheme!}://${this.dataNodeConfiguration?.host!}${path}`;
  }

  overrideMasterNodeConfiguration(config: NodeConfiguration) {
    if (config.path.includes("CESMaster")) {
      environment.masterNodeScheme = config.scheme;
      environment.masterNodeUrl = config.host;
      environment.masterNodeBasePath = config.path;
      environment.masterNodeKey = config.apiKey;
    }
  }

  onMasternodeReset() {
    window.localStorage.setItem(this.localStorageDataNode, JSON.stringify(this.masterNodeConfiguration));
  }

  onNewConfig(endpointUrl: string, endpointApiKey: string, basePath: string) {
    const isHttps: boolean = endpointUrl.startsWith("https");
    window.localStorage.setItem(this.localStorageDataNode, JSON.stringify({
      scheme: isHttps ? "https" : "https",
      host: endpointUrl.replace("https://", "").replace("http://", ""),
      apiKey: endpointApiKey,
      path: basePath,
      isDataNode: !basePath.includes("CESMaster")
    } as NodeConfiguration));
  }

  retrieveConfig(sessionIsValid: boolean) {
    if (sessionIsValid) {
      const dataNodeConfig: NodeConfiguration | undefined = this.dataNodeConfiguration;
      if (dataNodeConfig != undefined && dataNodeConfig.isDataNode) {
        window.localStorage.setItem(this.localStorageDataNode, JSON.stringify(dataNodeConfig));
      }
    } else {
      this.currentUsedSystem = '';
      this.onMasternodeReset();
    }
  }

}
