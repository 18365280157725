import {CanActivate, CanDeactivate, Router} from "@angular/router";
import {Injectable} from "@angular/core";
import {ChangePasswordComponent} from "../../layouts/topbar/modals/change-password/change-password.component";
import {SessionManager} from "../services/auth-service/support-services/SessionManager";

@Injectable({
  providedIn: 'root'
})
export class ForcePasswordChangeGuard implements CanActivate, CanDeactivate<ChangePasswordComponent> {

  constructor(
    private router: Router,
    private sessionManager: SessionManager
  ) {
  }

  canActivate(): boolean {
    if (this.sessionManager.sessionCredentials?.changePassword == true) {
      this.router.navigate(["/user/new-password"])
    }
    return SessionManager.getInstance().sessionCredentials?.changePassword != true;
  }

  canDeactivate(): boolean {
    return this.sessionManager.sessionCredentials?.changePassword != true;
  }
}
