import {LookupMapping} from "./lookup";

export const MODULE_LOOKUP: LookupMapping[] = [
  {
    "id": 1,
    "value": "auth"
  },
  {
    "id": 2,
    "value": "client"
  },
  {
    "id": 3,
    "value": "logbook"
  },
  {
    "id": 4,
    "value": "user"
  },
  {
    "id": 5,
    "value": "locking_device"
  },
  {
    "id": 6,
    "value": "locking_media"
  },
  {
    "id": 7,
    "value": "locking_media_assignable"
  },
  {
    "id": 8,
    "value": "access_profile"
  },
  {
    "id": 9,
    "value": "access_profile_time_models"
  },
  {
    "id": 10,
    "value": "access_profile_locking_devices"
  },
  {
    "id": 11,
    "value": "user_access_profiles"
  },
  {
    "id": 12,
    "value": "user_locking_media"
  },
  {
    "id": 13,
    "value": "time_model"
  },
  {
    "id": 14,
    "value": "job_changes"
  },
  {
    "id": 15,
    "value": "ongoing_jobs"
  },
  {
    "id": 16,
    "value": "finished_jobs"
  },
  {
    "id": 17,
    "value": "programming"
  },
  {
    "id": 18,
    "value": "group_locking_device"
  },
  {
    "id": 19,
    "value": "group_locking_device_assignments"
  },
  {
    "id": 20,
    "value": "group_user"
  },
  {
    "id": 21,
    "value": "group_user_assignments"
  },
  {
    "id": 22,
    "value": "group_user_access_profile"
  },
  {
    "id": 23,
    "value": "license"
  }
];
