import {Component, EventEmitter, Input, Output} from '@angular/core';

import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ChangeDetectorForm} from "../../../../../shared/util/change-detector/ChangeDetectorForm";
import {LockingDeviceGroupDetailsDto} from "../../../../../shared/entities/locking-device/LockingDeviceGroupDetailsDto";
import {DEVICE_GROUP} from "../../../../../shared/lookup/locking-device.lookup";

@Component({
  selector: 'app-locking-device-group-form',
  templateUrl: './locking-device-group-form.component.html',
  styleUrls: ['./locking-device-group-form.component.scss']
})
export class LockingDeviceGroupFormComponent {
  @Input() accessReadonly = false

  _selectedItem?: LockingDeviceGroupDetailsDto;

  validatorMaxLength64: number = 64;
  validatorMaxLength32: number = 32;

  @Input() set lockingDeviceGroup(item: LockingDeviceGroupDetailsDto) {
    if(item == null) {
      return
    }

    this._selectedItem = item

    this.lockingDeviceGroupForm.patchValue({
      id: item.id,
      uuid: item.uuid,
      name: item.name,
      description: item.description,
      groupTypeId: item.groupTypeId
    }, {emitEvent: false})

    this.changeDetector.refresh()
  }

  @Output() onChanges = new EventEmitter<boolean>()

  // Forms
  lockingDeviceGroupForm: FormGroup
  changeDetector: ChangeDetectorForm

  constructor(
    formBuilder: FormBuilder
  ) {
    this.lockingDeviceGroupForm = formBuilder.group({
      id: ['', []],
      uuid: ['', []],
      name: ['', [Validators.required, Validators.maxLength(32)]],
      description: ['', [Validators.maxLength(64)]],
      groupTypeId: ['1', []]
    })

    this.changeDetector = new ChangeDetectorForm(this.lockingDeviceGroupForm, () => {this.onChanges.emit(this.changeDetector.changed)})
  }

  get f() { return this.lockingDeviceGroupForm.controls; }

  get changed(): boolean {
    return this.changeDetector.changed
  }

  get invalid(): boolean {
    return this.lockingDeviceGroupForm.invalid
  }

  reset() {
    this.lockingDeviceGroupForm.reset()
    this.lockingDeviceGroupForm.markAsUntouched()
    this.changeDetector.refresh()
  }

  getLockingDeviceGroupDto(): LockingDeviceGroupDetailsDto {
    // update values
    this._selectedItem!.name = this.lockingDeviceGroupForm.get("name")?.value
    this._selectedItem!.description = this.lockingDeviceGroupForm.get("description")?.value
    this._selectedItem!.groupTypeId = this.lockingDeviceGroupForm.get("groupTypeId")?.value

    return this._selectedItem!
  }

  get deviceGroupTypeTranslationText(): string {
    return `LOCKING_DEVICES.GROUP_TYPE.${[...DEVICE_GROUP.filter(value => this.lockingDeviceGroupForm.get('groupTypeId')?.value == `${value.id}`), DEVICE_GROUP[0]][0].value.toUpperCase()}`;
  }
}
