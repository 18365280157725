import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {JobChangesFormComponent} from "./job-changes-form/job-changes-form.component";
import {OngoingJobsFormComponent} from "./ongoing-jobs-form/ongoing-jobs-form.component";
import {FinishedJobsFormComponent} from "./finished-jobs-form/finished-jobs-form.component";
import {SharedModule} from "../../../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {CoreModule} from "../../../core/core.module";
import {NgbProgressbarModule} from "@ng-bootstrap/ng-bootstrap";
import { JobsDistributedDeviceListComponent } from './jobs-distributed-device-list/jobs-distributed-device-list.component';



@NgModule({
  declarations: [
    JobChangesFormComponent,
    OngoingJobsFormComponent,
    FinishedJobsFormComponent,
    JobsDistributedDeviceListComponent
  ],
  exports: [
    JobChangesFormComponent,
    OngoingJobsFormComponent,
    FinishedJobsFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    CoreModule,
    NgbProgressbarModule
  ]
})
export class JobsFormsModule { }
