import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'errorTranslate'
})
export class ErrorTranslatePipe implements PipeTransform {

  private golangFormatter: string[] = ['%s', '%d', '%v'];
  private stringFormatter: string[] = ['{{length}}'];

  transform(value: string, ...args: string[]): string {
    let occurrences: number = 0;
    let formatters: string[] = this.stringFormatter;
    formatters.push(...this.golangFormatter);
    formatters.forEach(goEscapes => occurrences += (value.match(goEscapes)||[]).length)
    if (occurrences != args.length) {
      return value;
    }

    for (let i: number = 0; i < occurrences; i++) {
      value = value.replace(this.findFirstOccurrence(value), args[i]);
    }

    return value;
  }

  private findFirstOccurrence(value: string): string {
    const findSymbol = {
      length: {value: this.getIndexOrHighNumber(value, this.stringFormatter[0]), escapeLetter: this.stringFormatter[0]},
      percentS: {value: this.getIndexOrHighNumber(value, this.golangFormatter[0]), escapeLetter: this.golangFormatter[0]},
      percentD: {value: this.getIndexOrHighNumber(value, this.golangFormatter[1]), escapeLetter: this.golangFormatter[1]},
      percentV: {value: this.getIndexOrHighNumber(value, this.golangFormatter[2]), escapeLetter: this.golangFormatter[2]}
    };

    let minValue: number = 900;
    Object.values(findSymbol).map((value) => {
      minValue = Math.min(minValue, value.value);
    });
    return Object.values(findSymbol).find(value => value.value == minValue)?.escapeLetter || '';
  }

  private getIndexOrHighNumber(value: string, escapeLetter: string): number {
    const index: number = value.indexOf(escapeLetter);
    if (index == -1) {
      return 9000;
    }
    return index
  }

}
