export class LockingMediaDto {
  uuid: string;
  displayUid: string;
  articleNumber: string | undefined;
  mechanicalId: string | undefined;
  typeId: number;
  implementationId: number;
  stateId: number;
  locked: boolean;
  dateHandout: number | undefined;
  dateReturn: number | undefined;
  assignedUsers: string[] | undefined;
  mediaNumber: number;

  constructor(uuid: string,
              displayUid: string,
              articleNumber: string | undefined,
              mechanicalId: string | undefined,
              typeId: number,
              implementationId: number,
              stateId: number,
              locked: boolean,
              dateHandout: number | undefined,
              dateReturn: number | undefined,
              assignedUsers: string[] | undefined,
              mediaNumber: number) {
    this.uuid = uuid;
    this.displayUid = displayUid;
    this.articleNumber = articleNumber;
    this.mechanicalId = mechanicalId;
    this.typeId = typeId;
    this.implementationId = implementationId;
    this.stateId = stateId;
    this.locked = locked;
    this.dateHandout = dateHandout;
    this.assignedUsers = assignedUsers;
    this.mediaNumber = mediaNumber;
    this.dateReturn = dateReturn;
  }

}
