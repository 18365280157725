<div class="dtw-wrapper">

  <div class="dtw-availability">{{ "PROGRAMMING.NAMES.IO_BRIDGE" | translate }}
    <span [style.color]="'color' | iobIndicator: {iob: iobStatusShort}">
      {{ 'status-key' | iobIndicator: {iob: iobStatusShort} | translate }}</span>
  </div>

  <app-io-bridge-download *ngIf="!isIoBridgeAvailable || ioBridgeNeedsUpdate" [updateRequired]="ioBridgeNeedsUpdate"></app-io-bridge-download>

  <div *ngIf="isIoBridgeAvailable && !ioBridgeNeedsUpdate">{{ "PROGRAMMING.NAMES.DTW" | translate }}
    <span [style.color]="'color' | iobIndicator: {dtw: dtwStatusShort}">
      {{ 'status-key' | iobIndicator: {dtw: dtwStatusShort} | translate }}</span>
  </div>

  <div *ngIf="isIoBridgeAvailable && !ioBridgeNeedsUpdate && isUsbAvailable" class="dtw-availability"> </div>
  <button *ngIf="isIoBridgeAvailable && !ioBridgeNeedsUpdate && isUsbAvailable"
          class="btn btn-outline-primary btn-block" style="min-width: 100%;"
          (click)="testDTW()" [disabled]="disableTestDTW">{{ "PROGRAMMING.CONNECTION.TEST_DTW" | translate }}</button>
</div>
