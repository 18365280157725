import {Observable} from "rxjs";

export class PagetitleAction {
  constructor(
    public readonly key: string,
    public readonly actionKey: string,
    public styleClasses?: string,
    public icon?: string,
    public disabled: boolean = false,
    public disabledObserver: Observable<boolean> | undefined = undefined) { }
}
