import {LookupMapping, LookupType, LookupTypeTranslation} from "./lookup";
import {FIRMWARE_ERROR_CODE, FIRMWARE_MEDIA_TYPE} from "./firmware.lookup";
import {KEY_TYPE} from "./key.lookup";
import {ACCESS_MODE, ACCESS_TYPE} from "./access.lookup";
import {MASTER_TYPE} from "./master.lookup";
import {ACTOR_PROBLEM_TYPE, ACTOR_TYPE} from "./actor.lookup";
import {DST} from "./timezone.lookup";
import {MODULE_LOOKUP} from "./module.lookup";
import {LICENSE} from "./license.lookup";
import {ASSEMBLY_STATE} from "./assembly.lookup";
import {VARIANT} from "./variant.lookup";
import {HANDLE_DIRECTION, LOGGING} from "./locking-device.lookup";
import {HANDLE_INNER_TYPE, HANDLE_OUTER_TYPE} from "./handle.lookup";
import {MEDIA_STATE} from "./media.lookup";
import {TIME_MODEL_END_TYPE, TIME_MODEL_REPETITION_TYPE, TIME_MODEL_TYPE} from "./timemodel.lookup";
import {ROLE} from "./role.lookup";

export const EVENT: LookupMapping[] = [
  {
    "id": 1,
    "value": "event_access_unauthorized_local"
  },
  {
    "id": 2,
    "value": "event_access_unauthorized_temporal"
  },
  {
    "id": 3,
    "value": "event_access_unauthorized_locked"
  },
  {
    "id": 4,
    "value": "event_access_unauthorized_blocking_mode"
  },
  {
    "id": 5,
    "value": "event_access_unauthorized_validation_time"
  },
  {
    "id": 6,
    "value": "event_access_unauthorized_expiration_time"
  },
  {
    "id": 7,
    "value": "event_access_unauthorized_command_on_block"
  },
  {
    "id": 8,
    "value": "event_access_unauthorized_media_type"
  },
  {
    "id": 9,
    "value": "event_access_unauthorized_app_missing"
  },
  {
    "id": 10,
    "value": "event_access_unauthorized_app_communication_error"
  },
  {
    "id": 11,
    "value": "event_access_unauthorized_one_open"
  },
  {
    "id": 12,
    "value": "event_access_unauthorized_wrong_pin"
  },
  {
    "id": 13,
    "value": "event_access_authorized_media"
  },
  {
    "id": 14,
    "value": "event_access_authorized_command"
  },
  {
    "id": 15,
    "value": "event_access_authorized_digital_io"
  },
  {
    "id": 16,
    "value": "event_access_authorized_emergency_mode"
  },
  {
    "id": 17,
    "value": "event_access_authorized_release_mode"
  },
  {
    "id": 18,
    "value": "event_access_authorized_office_mode"
  },
  {
    "id": 19,
    "value": "event_access_authorized_emergency_open"
  },
  {
    "id": 20,
    "value": "event_master_medium_start"
  },
  {
    "id": 21,
    "value": "event_master_medium_end"
  },
  {
    "id": 22,
    "value": "event_master_medium_timeout"
  },
  {
    "id": 23,
    "value": "event_master_medium_stored"
  },
  {
    "id": 24,
    "value": "event_master_medium_erased"
  },
  {
    "id": 25,
    "value": "event_master_medium_erased_all"
  },
  {
    "id": 26,
    "value": "event_master_medium_set"
  },
  {
    "id": 27,
    "value": "event_master_medium_reset"
  },
  {
    "id": 28,
    "value": "event_master_medium_replace"
  },
  {
    "id": 29,
    "value": "event_master_medium_s_master_unauthorized"
  },
  {
    "id": 30,
    "value": "event_user_medium_store"
  },
  {
    "id": 31,
    "value": "event_user_medium_erase"
  },
  {
    "id": 32,
    "value": "event_user_medium_locked"
  },
  {
    "id": 33,
    "value": "event_user_medium_validation_successful"
  },
  {
    "id": 34,
    "value": "event_user_medium_validation_failed"
  },
  {
    "id": 35,
    "value": "event_user_medium_replacement_used_ctrld"
  },
  {
    "id": 36,
    "value": "event_user_medium_replacement_existing"
  },
  {
    "id": 37,
    "value": "event_user_medium_blacklist_store"
  },
  {
    "id": 38,
    "value": "event_user_medium_blacklist_user_locked"
  },
  {
    "id": 39,
    "value": "event_clock_set"
  },
  {
    "id": 40,
    "value": "event_clock_dst_change"
  },
  {
    "id": 41,
    "value": "event_battery_empty"
  },
  {
    "id": 42,
    "value": "event_battery_critical"
  },
  {
    "id": 43,
    "value": "event_battery_ok"
  },
  {
    "id": 44,
    "value": "event_battery_good"
  },
  {
    "id": 45,
    "value": "event_system_reset"
  },
  {
    "id": 46,
    "value": "event_system_legic_launching"
  },
  {
    "id": 47,
    "value": "event_system_legic_launch_finished"
  },
  {
    "id": 48,
    "value": "event_system_legic_delaunch"
  },
  {
    "id": 49,
    "value": "event_system_new_actor_type"
  },
  {
    "id": 50,
    "value": "event_system_key_changed"
  },
  {
    "id": 51,
    "value": "event_system_legitimation_mode_change"
  },
  {
    "id": 52,
    "value": "event_system_log_config_changed"
  },
  {
    "id": 53,
    "value": "event_system_access_config_changed"
  },
  {
    "id": 54,
    "value": "event_system_pairing_success"
  },
  {
    "id": 55,
    "value": "event_system_actor_problem"
  },
  {
    "id": 56,
    "value": "event_access_mode_office_on"
  },
  {
    "id": 57,
    "value": "event_access_mode_office_off"
  },
  {
    "id": 58,
    "value": "event_access_mode_office_timeout"
  },
  {
    "id": 59,
    "value": "event_access_mode_block_on"
  },
  {
    "id": 60,
    "value": "event_access_mode_block_off"
  },
  {
    "id": 61,
    "value": "event_access_mode_release_on"
  },
  {
    "id": 62,
    "value": "event_access_mode_release_off"
  },
  {
    "id": 63,
    "value": "event_firmware_update_start"
  },
  {
    "id": 64,
    "value": "event_firmware_update_finished"
  },
  {
    "id": 65,
    "value": "event_firmware_update_error"
  },
  {
    "id": 67,
    "value": "event_access_unauthorized_timeout"
  },
  {
    "id": 1000,
    "value": "event_login"
  },
  {
    "id": 1001,
    "value": "event_logout"
  },
  {
    "id": 1002,
    "value": "event_open_editor"
  },
  {
    "id": 1003,
    "value": "event_add"
  },
  {
    "id": 1004,
    "value": "event_get"
  },
  {
    "id": 1005,
    "value": "event_update"
  },
  {
    "id": 1006,
    "value": "event_delete"
  },
  {
    "id": 1007,
    "value": "event_finish"
  },
  {
    "id": 66,
    "value": "event_ces_battery_test"
  },
  {
    "id": 68,
    "value": "event_ces_motor_data"
  },
  {
    "id": 69,
    "value": "event_ces_nfc_status"
  },
  {
    "id": 70,
    "value": "event_ces_battery_test_motor_load"
  },
  {
    "id": 71,
    "value": "event_ces_advertise_timeout"
  }
];


export const EVENT_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "access_unauthorized"
  },
  {
    "id": 2,
    "value": "access_authorized"
  },
  {
    "id": 3,
    "value": "master_medium"
  },
  {
    "id": 4,
    "value": "user_medium"
  },
  {
    "id": 5,
    "value": "clock"
  },
  {
    "id": 6,
    "value": "battery"
  },
  {
    "id": 7,
    "value": "system"
  },
  {
    "id": 8,
    "value": "access_mode"
  },
  {
    "id": 9,
    "value": "firmware_update"
  },
  {
    "id": 10,
    "value": "ces"
  },
  {
    "id": 1000,
    "value": "login"
  },
  {
    "id": 1001,
    "value": "logout"
  },
  {
    "id": 1002,
    "value": "open_editor"
  },
  {
    "id": 1003,
    "value": "add"
  },
  {
    "id": 1004,
    "value": "get"
  },
  {
    "id": 1005,
    "value": "update"
  },
  {
    "id": 1006,
    "value": "delete"
  },
  {
    "id": 1007,
    "value": "finish"
  }
]

export const EVENT_INFO_TYPE: LookupType[] = [
  {
    "id": 1,
    "value": "fw_media_type",
    "type": "firmwareMediaType"
  },
  {
    "id": 2,
    "value": "fw_uid",
    "type": null
  },
  {
    "id": 3,
    "value": "fw_reset_bitmask",
    "type": "resetBitmask"
  },
  {
    "id": 4,
    "value": "fw_stamp",
    "type": null
  },
  {
    "id": 5,
    "value": "fw_key_type",
    "type": "keyType"
  },
  {
    "id": 6,
    "value": "fw_access_mode",
    "type": "accessMode"
  },
  {
    "id": 7,
    "value": "fw_master_type",
    "type": "masterType"
  },
  {
    "id": 8,
    "value": "fw_bat_current",
    "type": null
  },
  {
    "id": 9,
    "value": "fw_temperature",
    "type": null
  },
  {
    "id": 10,
    "value": "fw_firmware_version",
    "type": null
  },
  {
    "id": 11,
    "value": "fw_counter",
    "type": null
  },
  {
    "id": 12,
    "value": "fw_reader_terminal_bit",
    "type": null
  },
  {
    "id": 13,
    "value": "fw_dst",
    "type": "dst"
  },
  {
    "id": 14,
    "value": "fw_actor_problem_type",
    "type": "actorProblemType"
  },
  {
    "id": 15,
    "value": "fw_log_config",
    "type": null
  },
  {
    "id": 16,
    "value": "fw_actor_type",
    "type": "actorType"
  },
  {
    "id": 17,
    "value": "fw_fw_error_code",
    "type": "firmwareErrorCode"
  },
  {
    "id": 18,
    "value": "fw_buffer",
    "type": null
  },
  {
    "id": 19,
    "value": "fw_clock",
    "type": null
  },
  {
    "id": 20,
    "value": "fw_event_action",
    "type": null
  },
  {
    "id": 1000,
    "value": "gen_module",
    "type": "module"
  },
  {
    "id": 1001,
    "value": "gen_timestamp",
    "type": null
  },
  {
    "id": 1002,
    "value": "gen_top",
    "type": null
  },
  {
    "id": 1003,
    "value": "gen_identification",
    "type": null
  },
  {
    "id": 1100,
    "value": "ap_access_type_id",
    "type": "accessType"
  },
  {
    "id": 1101,
    "value": "ap_name",
    "type": null
  },
  {
    "id": 1102,
    "value": "ap_description",
    "type": null
  },
  {
    "id": 1103,
    "value": "ap_office_function",
    "type": null
  },
  {
    "id": 1104,
    "value": "ap_pin",
    "type": null
  },
  {
    "id": 1200,
    "value": "cl_client_name",
    "type": null
  },
  {
    "id": 1201,
    "value": "cl_license_type_id",
    "type": "licenseType"
  },
  {
    "id": 1202,
    "value": "cl_license_expire_timestamp",
    "type": null
  },
  {
    "id": 1203,
    "value": "cl_timezone_id",
    "type": null
  },
  {
    "id": 1204,
    "value": "cl_rfid_type_id",
    "type": null
  },
  {
    "id": 1300,
    "value": "ld_firmware_version",
    "type": null
  },
  {
    "id": 1301,
    "value": "ld_short_name",
    "type": null
  },
  {
    "id": 1302,
    "value": "ld_description",
    "type": null
  },
  {
    "id": 1303,
    "value": "ld_assembly_state_id",
    "type": "assemblyState"
  },
  {
    "id": 1304,
    "value": "ld_assembly_date",
    "type": null
  },
  {
    "id": 1305,
    "value": "ld_location_building",
    "type": null
  },
  {
    "id": 1306,
    "value": "ld_location_level",
    "type": null
  },
  {
    "id": 1307,
    "value": "ld_location_room",
    "type": null
  },
  {
    "id": 1308,
    "value": "ld_location_door",
    "type": null
  },
  {
    "id": 1309,
    "value": "ld_opening_duration",
    "type": null
  },
  {
    "id": 1310,
    "value": "ld_beeper",
    "type": null
  },
  {
    "id": 1311,
    "value": "ld_office_function",
    "type": null
  },
  {
    "id": 1312,
    "value": "ld_office_timeout",
    "type": null
  },
  {
    "id": 1313,
    "value": "ld_open_time_model_number",
    "type": null
  },
  {
    "id": 1314,
    "value": "ld_close_time_model_number",
    "type": null
  },
  {
    "id": 1315,
    "value": "ld_variant_id",
    "type": "variant"
  },
  {
    "id": 1316,
    "value": "ld_logging_id",
    "type": "logging"
  },
  {
    "id": 1317,
    "value": "ld_handle_direction_id",
    "type": "handleDirection"
  },
  {
    "id": 1318,
    "value": "ld_handle_inner_type_id",
    "type": "handleInnerType"
  },
  {
    "id": 1319,
    "value": "ld_handle_outer_type_id",
    "type": "handleOuterType"
  },
  {
    "id": 1320,
    "value": "ld_cylinder_distance_id",
    "type": "cylinderDistance"
  },
  {
    "id": 1331,
    "value": "ld_square_size_id",
    "type": "squareSize"
  },
  {
    "id": 1400,
    "value": "lm_state_id",
    "type": "lockingMediaState"
  },
  {
    "id": 1401,
    "value": "lm_date_handout",
    "type": null
  },
  {
    "id": 1402,
    "value": "lm_date_return",
    "type": null
  },
  {
    "id": 1403,
    "value": "lm_mechanical_id",
    "type": null
  },
  {
    "id": 1404,
    "value": "lm_locked",
    "type": null
  },
  {
    "id": 1500,
    "value": "tm_model_type_id",
    "type": "timeModelType"
  },
  {
    "id": 1502,
    "value": "tm_short_name",
    "type": null
  },
  {
    "id": 1503,
    "value": "tm_description",
    "type": null
  },
  {
    "id": 1504,
    "value": "tm_start_date",
    "type": null
  },
  {
    "id": 1505,
    "value": "tm_end_type_id",
    "type": "timeModelEndType"
  },
  {
    "id": 1506,
    "value": "tm_end_value",
    "type": null
  },
  {
    "id": 1507,
    "value": "tm_repetition_type_id",
    "type": "timeModelRepetitionType"
  },
  {
    "id": 1508,
    "value": "tm_repetition_value",
    "type": null
  },
  {
    "id": 1600,
    "value": "us_username",
    "type": null
  },
  {
    "id": 1601,
    "value": "us_user_password",
    "type": null
  },
  {
    "id": 1602,
    "value": "us_pin",
    "type": null
  },
  {
    "id": 1603,
    "value": "us_mail",
    "type": null
  },
  {
    "id": 1604,
    "value": "us_role_id",
    "type": "role"
  },
  {
    "id": 1605,
    "value": "us_blocked",
    "type": null
  },
  {
    "id": 1606,
    "value": "us_first_name",
    "type": null
  },
  {
    "id": 1607,
    "value": "us_last_name",
    "type": null
  },
  {
    "id": 1608,
    "value": "us_country_id",
    "type": null
  },
  {
    "id": 1609,
    "value": "us_zip_code",
    "type": null
  },
  {
    "id": 1610,
    "value": "us_city",
    "type": null
  },
  {
    "id": 1611,
    "value": "us_street_house_number",
    "type": null
  },
  {
    "id": 1612,
    "value": "us_date_of_birth",
    "type": null
  },
  {
    "id": 1613,
    "value": "us_personnel_no",
    "type": null
  },
  {
    "id": 1614,
    "value": "us_department",
    "type": null
  },
  {
    "id": 1615,
    "value": "us_activity",
    "type": null
  },
  {
    "id": 1616,
    "value": "us_area",
    "type": null
  },
  {
    "id": 1617,
    "value": "us_cost_centre",
    "type": null
  },
  {
    "id": 1618,
    "value": "us_phone_office",
    "type": null
  },
  {
    "id": 1619,
    "value": "us_phone_private",
    "type": null
  },
  {
    "id": 1620,
    "value": "us_phone_mobile",
    "type": null
  },
  {
    "id": 1621,
    "value": "us_description",
    "type": null
  },
  {
    "id": 1622,
    "value": "us_change_password",
    "type": null
  },
  {
    "id": 1623,
    "value": "us_image_available",
    "type": null
  },
  {
    "id": 1624,
    "value": "us_image",
    "type": null
  },
  {
    "id": 1700,
    "value": "gld_name",
    "type": null
  },
  {
    "id": 1701,
    "value": "gld_description",
    "type": null
  },
  {
    "id": 1702,
    "value": "gld_opening_duration",
    "type": null
  },
  {
    "id": 1703,
    "value": "gld_beeper",
    "type": null
  },
  {
    "id": 1704,
    "value": "gld_office_function",
    "type": null
  },
  {
    "id": 1705,
    "value": "gld_office_timeout",
    "type": null
  },
  {
    "id": 1706,
    "value": "gld_open_time_model_number",
    "type": null
  },
  {
    "id": 1707,
    "value": "gld_close_time_model_number",
    "type": null
  },
  {
    "id": 1708,
    "value": "gld_variant_id",
    "type": "variant"
  },
  {
    "id": 1709,
    "value": "gld_logging_id",
    "type": "logging"
  },
  {
    "id": 1800,
    "value": "gus_name",
    "type": null
  },
  {
    "id": 1801,
    "value": "gus_description",
    "type": null
  },
  {
    "id": 1900,
    "value": "aptm_added",
    "type": null
  },
  {
    "id": 1901,
    "value": "aptm_removed",
    "type": null
  },
  {
    "id": 2000,
    "value": "apld_added",
    "type": null
  },
  {
    "id": 2001,
    "value": "apld_removed",
    "type": null
  },
  {
    "id": 2100,
    "value": "uap_added",
    "type": null
  },
  {
    "id": 2101,
    "value": "uap_removed",
    "type": null
  },
  {
    "id": 2200,
    "value": "ulm_added",
    "type": null
  },
  {
    "id": 2201,
    "value": "ulm_removed",
    "type": null
  },
  {
    "id": 2300,
    "value": "gua_added",
    "type": null
  },
  {
    "id": 2301,
    "value": "gua_removed",
    "type": null
  },
  {
    "id": 2400,
    "value": "guap_added",
    "type": null
  },
  {
    "id": 2401,
    "value": "guap_removed",
    "type": null
  },
  {
    "id": 2500,
    "value": "glda_added",
    "type": null
  },
  {
    "id": 2501,
    "value": "glda_removed",
    "type": null
  }
];

function returnNumbersArray(): LookupMapping[] {
  let arr: LookupMapping[] = [];
  for (let i = 0; i < 101; i++) {
    arr.push({
      id: i,
      value: `${i}`
    });
  }
  return arr
}

export function EVENT_INFO_TYPE_TRANSLATION(type: string): LookupTypeTranslation {
  switch (type) {
    case 'role':
      return new LookupTypeTranslation(ROLE, 'USERS.USERS.ROLE.');
    case 'timeModelRepetitionType':
      return new LookupTypeTranslation(TIME_MODEL_REPETITION_TYPE, 'TIME_MODELS.REPETITION.');
    case 'timeModelEndType':
      return new LookupTypeTranslation(TIME_MODEL_END_TYPE, 'TIME_MODELS.END.');
    case 'timeModelType':
      return new LookupTypeTranslation(TIME_MODEL_TYPE, 'TIME_MODELS.TYPE.');
    case 'lockingMediaState':
      return new LookupTypeTranslation(MEDIA_STATE, 'LOCKING_MEDIA.STATE.');
    case 'cylinderDistance':
      return new LookupTypeTranslation(returnNumbersArray(), '', ' mm');
    case 'squareSize':
      return new LookupTypeTranslation(returnNumbersArray(), '', ' mm');
    case 'handleOuterType':
      return new LookupTypeTranslation(HANDLE_OUTER_TYPE, 'LOCKING_DEVICES.DEVICES.HANDLE_TYPE.');
    case 'handleInnerType':
      return new LookupTypeTranslation(HANDLE_INNER_TYPE, 'LOCKING_DEVICES.DEVICES.HANDLE_TYPE.');
    case 'handleDirection':
      return new LookupTypeTranslation(HANDLE_DIRECTION, 'LOCKING_DEVICES.DEVICES.HANDLE_DIRECTION.');
    case 'logging':
      return new LookupTypeTranslation(LOGGING, 'LOCKING_DEVICES.DEVICES.LOGGING.');
    case 'variant':
      return new LookupTypeTranslation(VARIANT, 'LOCKING_DEVICES.DEVICES.VARIANT.');
    case 'assemblyState':
      return new LookupTypeTranslation(ASSEMBLY_STATE, 'LOCKING_DEVICES.DEVICES.ASSEMBLY_STATE.');
    case 'licenseType':
      return new LookupTypeTranslation(LICENSE, 'SYSTEM.LICENSE.');
    case 'accessType':
      return new LookupTypeTranslation(ACCESS_TYPE, 'GENERAL.');
    case 'module':
      return new LookupTypeTranslation(MODULE_LOOKUP, 'LOGBOOK.MODULE.');
    case 'firmwareErrorCode':
      return new LookupTypeTranslation(FIRMWARE_ERROR_CODE, 'LOGBOOK.EVENT_INFO_TYPE.FIRMWARE_ERROR_CODE.');
    case 'actorType':
      // No translation needed.
      return new LookupTypeTranslation(ACTOR_TYPE, '');
    case 'actorProblemType':
      return new LookupTypeTranslation(ACTOR_PROBLEM_TYPE, 'LOGBOOK.EVENT_INFO_TYPE.ACTOR_PROBLEM_TYPE.');
    case 'dst':
      return new LookupTypeTranslation(DST, 'LOGBOOK.EVENT_INFO_TYPE.DST.');
    case 'masterType':
      return new LookupTypeTranslation(MASTER_TYPE, 'LOGBOOK.EVENT_INFO_TYPE.MASTER_TYPE.');
    case 'accessMode':
      return new LookupTypeTranslation(ACCESS_MODE, 'LOGBOOK.EVENT_INFO_TYPE.ACCESS_MODE.');
    case 'keyType':
      return new LookupTypeTranslation(KEY_TYPE, 'LOGBOOK.EVENT_INFO_TYPE.KEY_TYPE.');
    case 'firmwareMediaType':
      return new LookupTypeTranslation(FIRMWARE_MEDIA_TYPE, 'LOGBOOK.EVENT_INFO_TYPE.FIRMWARE_MEDIA_TYPE.');
    case 'resetBitmask':
      return new LookupTypeTranslation([
        {
          "id": 0,
          "value": "rtc"
        },
        {
          "id": 1,
          "value": "reader"
        },
        {
          "id": 2,
          "value": "firmware_update"
        }
      ], 'LOGBOOK.EVENT_INFO_TYPE.RESET_BITMASK.');
  }
  return new LookupTypeTranslation([], '', '');
}
