import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';

import {PendingChangesBlocker} from "../../core/guards/pending-changes-view.guard";
import {NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {PagetitleAction} from "../../shared/pagetitle/pagetitle-action";
import {debounceTime, Subject} from "rxjs";
import {UserUsersComponent} from "./user-users/user-users.component";
import {UserGroupsComponent} from "./user-groups/user-groups.component";
import {ApiService} from "../../core/services/api-service/api.service";
import {SubmitUtils} from "../../shared/util/SubmitUtils";
import {PagetitleComponent} from "../../shared/pagetitle/pagetitle.component";
import {FormBuilder} from "@angular/forms";
import {SessionManager} from "../../core/services/auth-service/support-services/SessionManager";
import {ModalService} from "../../shared/notification/modal/modal.service";

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit, PendingChangesBlocker {

  @ViewChildren(UserUsersComponent) queryUsers!: QueryList<UserUsersComponent>
  @ViewChildren(UserGroupsComponent) queryGroups!: QueryList<UserGroupsComponent>
  @ViewChild('pagetitle') private pagetitle: PagetitleComponent;

  userUsersComponent!: UserUsersComponent
  userGroupsComponent!: UserGroupsComponent

  selectedNavTab: 'users' | 'groups' = 'users'
  isBusinessLicense: boolean;
  isExpiredLicense: boolean;
  loaded: boolean = false;

  pagetitleActions: PagetitleAction[] = []
  addAction = new PagetitleAction("BUTTON.ADD", "add-user-or-group", "btn-outline-primary", "mdi mdi-plus-circle-outline")

  // event triggered
  onUpdate: Subject<void>
  minimizePagetitle = false
  typeaheadIndex = new Set<string>()
  get searchEntries() { return [...this.typeaheadIndex.values()] }

  // search query
  searchQuery: string = "";

  canAddUser: boolean = false
  canAddUserGroup: boolean = false

  getCanAddUser(): boolean{
    return this.apiService.user.canAdd;
  }

  getCanAddUserGroup(): boolean {
    return this.apiService.userGroup.canAdd;
  }

  constructor(
    private apiService: ApiService,
    private notification: ModalService
  ) {
    this.isBusinessLicense = SessionManager.getInstance().isLicenseBusiness;
    this.isExpiredLicense = SessionManager.getInstance().isLicenseExpired;
    this.pagetitle = new PagetitleComponent(new FormBuilder)
    this.onUpdate = new Subject()
    this.onUpdate.pipe(debounceTime(50)).subscribe(() => this.onCheckChanges())
  }

  async ngOnInit() {
    if (this.apiService.user.canAdd) {
      this.pagetitleActions.push(this.addAction)
    }
    this.loaded = true
    this.canAddUser = this.getCanAddUser()
    this.canAddUserGroup = this.getCanAddUserGroup()
  }

  ngAfterViewInit(): void {
    this.userUsersComponent = this.queryUsers.first
    this.queryUsers.changes.subscribe(value => {
      this.userUsersComponent = value.first
    })

    this.userGroupsComponent = this.queryGroups.first
    this.queryGroups.changes.subscribe(value => {
      this.userGroupsComponent = value.first
    })
  }

  onCheckChanges() {
    if (this.selectedNavTab == 'users') {
      this.typeaheadIndex = this.userUsersComponent?.typeaheadIndex || new Set<string>()
    }
    if (this.selectedNavTab == 'groups') {
      this.typeaheadIndex = this.userGroupsComponent?.typeaheadIndex || new Set<string>()
    }

    this.minimizePagetitle = this.userUsersComponent?.itemSelected == true || this.userGroupsComponent?.itemSelected == true
  }

  hasPendingChanges(): boolean {
    return this.userUsersComponent?.hasPendingChanges() == true || this.userGroupsComponent?.hasPendingChanges() == true
  }

  onSearch(search: string) {
    this.searchQuery = search
  }

  onSelect(selected: boolean) {
    this.minimizePagetitle = selected
  }

  async onNavigation(event: NgbNavChangeEvent) {
    this.resetSearch();

    const currentNav = event.nextId // contains selected element
    event.preventDefault()

    if (this.userUsersComponent?.hasPendingChanges() || this.userGroupsComponent?.hasPendingChanges()) {
      if (await SubmitUtils.warnPendingChanges(this.notification)) {
        return;
      }
    }
    this.selectedNavTab = currentNav;

    const showAdd =
      (this.selectedNavTab == "users" && this.apiService.user.canAdd) ||
      (this.selectedNavTab == "groups" && this.apiService.userGroup.canAdd)
    this.pagetitleActions = showAdd ? [this.addAction] : []

    // check for changes and wait for editor state change
    this.onUpdate.next()
    setTimeout(() => {
      this.resetSearch()
      this.onUpdate.next()
    }, 400)
  }

  async onPagetitleAction() {
    if (this.userUsersComponent?.hasPendingChanges() || this.userGroupsComponent?.hasPendingChanges()) {
      if (await SubmitUtils.warnPendingChanges(this.notification)) {
        return;
      }
    }
    if (this.selectedNavTab == 'users' && this.canAddUser) {
      this.userUsersComponent.onAddUser()
    }
    if (this.selectedNavTab == 'groups' && this.canAddUserGroup) {
      this.userGroupsComponent.onAddUserGroup()
    }
  }

  private resetSearch(): void {
    this.typeaheadIndex = new Set<string>();
    this.searchQuery = "";
    this.pagetitle = new PagetitleComponent(new FormBuilder);
    this.pagetitle.reInit();
  }
}
