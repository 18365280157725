import {LookupMapping} from "./lookup";

export const FIRMWARE_ERROR_CODE: LookupMapping[] = [
  {
    "id": 1,
    "value": "success"
  },
  {
    "id": 2,
    "value": "aborted"
  },
  {
    "id": 3,
    "value": "timeout"
  }
];

export const FIRMWARE_MEDIA_TYPE: LookupMapping[] = [
  {
    "id": 4,
    "value": "desfire"
  },
  {
    "id": 9,
    "value": "legic_advant"
  },
  {
    "id": 10,
    "value": "legic_cross"
  },
  {
    "id": 11,
    "value": "ble"
  }
];
