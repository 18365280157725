import {UuidShortName} from "../Basic";
import {TimeModelIntervalDto} from "./TimeModelIntervalDto";
import {TimeModelExecutionRepetitionDto} from "./TimeModelExecutionRepetitionDto";

export class TimeModelDto extends UuidShortName {
  modelTypeId: number;
  timeModelId: number;
  description: string | undefined;
  startDate: number | undefined;
  endValue: number | undefined;
  endTypeId: number | undefined;
  repetitionTypeId: number | undefined;
  repetitionValue: number | undefined;
  executionRepetition: TimeModelExecutionRepetitionDto[];
  intervals: TimeModelIntervalDto[];
  timeModelNumber: number;

  constructor(uuid: string, shortName: string, modelTypeId: number, timeModelId: number, timeModelNumber: number, executionRepetition: TimeModelExecutionRepetitionDto[], intervals: TimeModelIntervalDto[]) {
    super(uuid, shortName);
    this.modelTypeId = modelTypeId;
    this.timeModelId = timeModelId;
    this.executionRepetition = executionRepetition;
    this.intervals = intervals;
    this.timeModelNumber = timeModelNumber;
  }

  static emptyTimeModelDto(): TimeModelDto {
    return new TimeModelDto("", "", 1, 0, 0, [], []);
  }

  static zeroTimeModelDtoWithName(name: string): TimeModelDto {
    return new TimeModelDto("0", name, 1, 0, 0, [], []);
  }

}
