export class SelectListItem {

  image?: string;
  public info: string[] = [];
  public badges: {icon: string, style: string, description?: string, imgUrl?: string}[] = [];

  constructor(public id: string, public name: string, info: string[] = [], ...badges:{icon: string, style: string, description?: string, imgUrl?: string}[]) {
    this.info = info
  }

  setImage(image: string): SelectListItem {
    this.image = image
    return this
  }

  addInfo(info: string): SelectListItem {
    this.info.push(info)
    return this
  }

  addBadge(icon: string, style: string, description?: string, imgUrl?: string): SelectListItem {
    this.badges.push({icon: icon, style: style, description: description, imgUrl: imgUrl})
    return this
  }

}
