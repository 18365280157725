<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{ "PROGRAMMING.INSTALLATION_INFO.TITLE" | translate }}</h4>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <span>{{ "PROGRAMMING.INSTALLATION_INFO.DOWNLOAD" | translate }}</span>
  <br/>
  <div>
    <a type="button" class="m-1 btn btn-outline-secondary center" href="{{downloadLink}}" download="IOBridge">
      <i icon="io-download"></i>
      {{ "PROGRAMMING.BUTTONS.DOWNLOAD" | translate }}
    </a>
  </div>
  <br/>
  <span>{{ "PROGRAMMING.INSTALLATION_INFO.INSTALL" | translate }}</span>
  <br/>
  <img class="center" src="assets/images/ioBridge/downloadButton.png">
  <br/>
  <span>{{ "PROGRAMMING.INSTALLATION_INFO.WAIT" | translate }}</span>
  <br/>
  <img class="center" [src]="imageLink">
  <br/>
  <div class="io-connect-disconnect">
    <div>
      <h6>{{ "PROGRAMMING.BUTTONS.CONNECT" | translate }}</h6>
      <span>{{ "PROGRAMMING.INSTALLATION_INFO.CONNECT" | translate }}</span>
    </div>
    <div>
      <h6>{{ "PROGRAMMING.BUTTONS.DISCONNECT" | translate }}</h6>
      <span>{{ "PROGRAMMING.INSTALLATION_INFO.DISCONNECT" | translate }}</span>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary"
          (click)="activeModal.close()"> {{ "BUTTON.CANCEL" | translate }}
  </button>
</div>
