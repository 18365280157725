<div class="card h-100 shadow-none flex-grow-1">
  <h6 class="card-header">{{ "JOBS.SECTION.DEVICES" | translate }}</h6>
  <div class="card-body d-flex gap-3" [ngClass]="dynamicList ? 'flex-row flex-wrap flex-grow-0' : 'flex-column'">

    <div *ngFor="let device of distributedDevices; let i = index" class="form-floating form-floating-custom"
         [ngClass]="{'flex-grow-1': dynamicList}">
      <input readonly type="text" class="form-control" id="device-{{i}}" [value]="device['deviceName']">
      <label for="device-{{i}}">
        {{ "JOBS.FIELD.DISTRIBUTED_DEVICE.UID" | translate:{uid: device["virtualDeviceUid"]} }}
      </label>
      <div class="form-floating-icon">
        <i icon="mobile"></i>
      </div>
    </div>

    <div *ngIf="distributedDevices == null || distributedDevices.length == 0" class="text-center text-muted">
      {{ "JOBS.FIELD.DISTRIBUTED_DEVICE.EMPTY" | translate }}
    </div>

  </div>
</div>
