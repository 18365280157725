import {ChangeDetectorForm} from "./ChangeDetectorForm";
import {ChangeDetectorValue} from "./ChangeDetectorValue";

export class ChangeDetectorChanges {
  detectors: (ChangeDetectorValue | ChangeDetectorForm | boolean | undefined)[];

  constructor(...changeDetectors: (ChangeDetectorValue | ChangeDetectorForm | boolean| undefined)[]) {
    this.detectors = changeDetectors.filter(changeDetector => typeof changeDetector !== 'undefined');
  }

  get hasChanges(): boolean {
    return this.detectors.map(value => {
      if (value instanceof ChangeDetectorValue) {
        return value.hasChanges;
      }
      if (value instanceof ChangeDetectorForm) {
        return value.changed;
      }
      return value == true;
    }).filter(value => value).length > 0;
  }

  get isValid() {
    return this.detectors.map(value => {
      if (value instanceof ChangeDetectorValue) {
        return value.isValid;
      }
      return true;
    }).filter(value => !value).length == 0;
  }
}
