import {UuidName} from "../Basic";

export class UserGroupDto extends UuidName {
  id: number;
  description: string | undefined;
  users: string[] | undefined;
  accessProfiles: string[] | undefined;

  constructor(uuid: string, id: number, name: string, description: string | undefined, users: string[] | undefined, accessProfiles: string[] | undefined) {
    super(uuid, name);
    this.id = id;
    this.description = description;
    this.users = users;
    this.accessProfiles = accessProfiles;
  }

  static emptyUserGroupDto(): UserGroupDto {
    return new this('', 0, '', '', [], []);
  }
}
