import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccessProfileFormComponent} from './access-profile-form/access-profile-form.component';
import {
  AccessProfileTimeModelFormComponent
} from './access-profile-time-model-form/access-profile-time-model-form.component';
import {
  AccessProfileLockingDeviceFormComponent
} from './access-profile-locking-device-form/access-profile-locking-device-form.component';
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CoreModule} from "../../../core/core.module";
import {SharedModule} from "../../../shared/shared.module";
import {NgbAccordionModule, NgbCollapse, NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";


@NgModule({
  declarations: [
    AccessProfileFormComponent,
    AccessProfileTimeModelFormComponent,
    AccessProfileLockingDeviceFormComponent
  ],
  exports: [
    AccessProfileFormComponent,
    AccessProfileTimeModelFormComponent,
    AccessProfileLockingDeviceFormComponent
  ],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        CoreModule,
        SharedModule,
        NgbAccordionModule,
        NgbTypeahead,
        NgbCollapse,
        FormsModule
    ]
})
export class AccessProfileFormsModule {
}
