import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import { HttpClient } from "@angular/common/http";
import {HealthCheckDto} from "../../../../shared/entities/HealthCheckDto";
import {Observable} from "rxjs";
import {ToastService} from "../../../../shared/notification/toast/toast.service";

export class HealthService extends CESService {

  //param is used, see isWritable check
  private testReadonlyParam: boolean = false

  constructor(router: Router, httpClient: HttpClient, notification: ToastService) {
    super(router, httpClient, notification)
  }

  async getHealthCheckData(): Promise<Observable<HealthCheckDto>> {
    return this.httpClient.get<HealthCheckDto>(await this.buildUrl(undefined, '/healthCheck'));
  }
}
