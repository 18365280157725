import {LookupMapping} from "./lookup";

export const ACTOR_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "time"
  },
  {
    "id": 2,
    "value": "vnet"
  },
  {
    "id": 3,
    "value": "oss"
  },
  {
    "id": 4,
    "value": "zks"
  }
];

export const ACTOR_PROBLEM_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "jammed_opening"
  },
  {
    "id": 2,
    "value": "jammed_closing"
  },
  {
    "id": 3,
    "value": "no_actor"
  },
  {
    "id": 4,
    "value": "crc_error"
  }
];
