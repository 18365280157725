import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {JobChangesDto} from "../../../../shared/entities/jobs/JobChangesDto";
import {Observable} from "rxjs";
import {OngoingJobDto} from "../../../../shared/entities/jobs/OngoingJobDto";
import {FinishedJobDto} from "../../../../shared/entities/jobs/FinishedJobDto";
import {JournalResultDto} from "../../../../shared/entities/jobs/JournalResultDto";
import {ToastService} from "../../../../shared/notification/toast/toast.service";

export class JobService extends CESService {
  constructor(router: Router, httpClient: HttpClient, notification: ToastService) {
    super(router, httpClient, notification)
  }

  async getAllChanges(): Promise<Observable<JobChangesDto[]>> {
    return this.httpClient.get<JobChangesDto[]>(await this.buildUrl(undefined, '/jobs/changes'));
  }

  async getAllOngoingJobs(): Promise<Observable<OngoingJobDto[]>> {
    return this.httpClient.get<OngoingJobDto[]>(await this.buildUrl(undefined, '/jobs/ongoing'));
  }

  async getAllFinishedJobs(): Promise<Observable<FinishedJobDto[]>> {
    return this.httpClient.get<FinishedJobDto[]>(await this.buildUrl(undefined, '/jobs/finished'));
  }

  async generateJob(uuid: string): Promise<Observable<HttpResponse<any>>> {
    return this.httpClient.post<any>(await this.buildUrl(undefined, '/jobs/generate'), Array.of(uuid), {observe: 'response'});
  }

  async generateResetJob(uuid: string): Promise<Observable<HttpResponse<any>>> {
    return this.httpClient.post<any>(await this.buildUrl(undefined, '/jobs/generateReset'), Array.of(uuid), {observe: 'response'});
  }

  async processDataFromLockingDevice(resultData: JournalResultDto[]): Promise<Observable<HttpResponse<any>>> {
    return this.httpClient.post<any>(await this.buildUrl(undefined, '/jobs/processData'), resultData, {observe: 'response'});
  }

  canGenerateJob(): boolean {
    return !super.accessIsReadOnly;
  }
}
