import {Injectable} from '@angular/core';
import {CESService} from "../ces.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Subject} from "rxjs";
import {ToastService} from "../../../shared/notification/toast/toast.service";
import {$notifyRelogin} from "../../../shared/util/FallbackNotifiers";
import {AuthenticationService} from "../auth-service/auth.service";
import {RefreshTokenHelper} from "../../../shared/util/RefreshTokenHelper";
import {ModalContainerContent} from "../../../shared/notification/modal/modal-container.class";
import {ModalService} from "../../../shared/notification/modal/modal.service";
import {SessionManager} from "../auth-service/support-services/SessionManager";
import {Logger} from "../../../shared/util/logger";

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenService extends CESService {
  private modalOpened: boolean = false;
  private refreshTokenHelper: RefreshTokenHelper;

  running: boolean = false;
  pause: boolean = false;

  $tokenStartedHook: Subject<string> = new Subject<string>();

  constructor(router: Router,
              notification: ToastService,
              httpClient: HttpClient,
              private modalService: ModalService,
              private authenticationService: AuthenticationService) {
    super(router, httpClient, notification);
    this.refreshTokenHelper = RefreshTokenHelper.getInstance(SessionManager.getInstance().sessionCredentials?.token);
    $notifyRelogin.pipe().subscribe(mustRelogin => {
      Logger.log("$notifyRelogin: " + mustRelogin);
      if (mustRelogin) {
        this.reLogin();
      }
    });
    setTimeout(() => this.refreshTokenUpdater(), 5000);
  }

  refreshTokenUpdater() {
    if (this.running) {
      return;
    }
    this.running = true;
    this.refreshTokenRecursiveUpdater(true);
  }

  private callGuard(rtruv: { r: boolean, v: boolean, p: number, msg: string}[]): number {
    let triggered: number = 0;
    rtruv.sort((a, b) => a.p - b.p).forEach(value => {
      if (!triggered && value.v && value.r) {
        this.running = false;
        //Logger.info(value.msg);
        triggered = value.p;
      }
    });
    return triggered;
  }

  private refreshTokenRecursiveUpdater(recursion: boolean) {
    //Logger.log("refreshTokenRecursiveUpdater")
    switch (this.callGuard([
      {r: recursion, v: this.pause, p: 1, msg: 'No calls are planned'},
      {r: recursion, v: SessionManager.getInstance().sessionCredentials == undefined, p: 2, msg: 'Logout was performed'},
      {r: true, v: !this.refreshTokenHelper.isTokenValid, p: 3, msg: 'token is invalid. relogin will be called'},
      {r: recursion, v: this.refreshTokenHelper.durationLeftToWait > 1, p: 4, msg: `token is still valid. Time left to next call: ${Math.floor(this.refreshTokenHelper.durationLeftToWait)} seconds`}
    ])) {
      case 3:
        this.reLogin();
        return;
    }
    setTimeout((): void => this.refreshTokenUpdater(), 5000);
  }

  private reLogin(): void {
    if (this.modalOpened) {
      return;
    }
    this.modalOpened = true;
    let modal: ModalContainerContent = new ModalContainerContent(
      'NOTIFICATION.ALERT.SESSION.TITLE',
      '',
      undefined,
      undefined,
      'NOTIFICATION.ALERT.BUTTON.LOGIN',
      'NOTIFICATION.ALERT.BUTTON.LOGOUT',
      'btn-outline-light',
      'btn-outline-danger',
      'refreshToken');

    this.modalService.openModal(modal).then(async result => {
      if (typeof result === 'string') {
        if (!await this.authenticationService.relogin(result as string)) {
          this.modalOpened = false;
          this.reLogin();
          return;
        } else {
          // Dirty fix for ENTRY-1018
          window.location.reload();
        }
      } else {
        this.authenticationService.logout().finally(() => this.router.navigate(['/user/login']).catch());
      }
    }).finally(() => {
      this.modalOpened = false;
    });
  }

}
