import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {TimeModelDto} from "../../../../shared/entities/TimeModel/TimeModelDto";
import {ToastService} from "../../../../shared/notification/toast/toast.service";


export class TimeModelService extends CESService {

  constructor(router: Router, httpClient: HttpClient, notification: ToastService) {
    super(router, httpClient, notification)
  }

  // default CRUD
  async getAll(): Promise<Observable<TimeModelDto[]>> {
    return this.httpClient.get<TimeModelDto[]>(await this.buildUrl(undefined, '/timeModels'));
  }

  async add(timeModelDto: TimeModelDto): Promise<Observable<TimeModelDto>> {
    return this.httpClient.post<TimeModelDto>(await this.buildUrl(undefined, '/timeModels'), timeModelDto);
  }

  async update(timeModelDto: TimeModelDto): Promise<Observable<TimeModelDto>> {
    return this.httpClient.put<TimeModelDto>(await this.buildUrl(undefined, `/timeModels/${timeModelDto.uuid}`), timeModelDto);
  }

  async delete(timeModelUuid: string): Promise<Observable<Object>> {
    const url: string = await this.buildUrl(undefined, `/timeModels/${timeModelUuid}`);
    return this.httpClient.delete(url);
  }

  getImageNameForTimeModelType(typeId: number): string {
    switch (typeId) {
      case 1:
        return 'TimeModelType-PointInTime';
      case 2:
        return 'TimeModelType-TimeInterval';
      case 3:
        return 'TimeModelType-DailyBasis';
      case 4:
        return 'TimeModelType-Repetition';
      case 5:
        return 'TimeModelType-DayList';
      default:
        return 'TimeModelType-PointInTime';
    }
  }
}
