import {ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot} from "@angular/router";
import {from, Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {ModalService} from "../../shared/notification/modal/modal.service";
import {ModalContainerContent} from "../../shared/notification/modal/modal-container.class";

@Injectable({
  providedIn: 'root'
})
export class PendingChangesViewGuard implements CanDeactivate<PendingChangesBlocker> {

  constructor(private notification: ModalService) {
  }

  canDeactivate(
    component: PendingChangesBlocker,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    if(component == null) {
      // blocker not implemented
      return true
    }

    if(component.hasPendingChanges()) {
      let modal: ModalContainerContent = new ModalContainerContent(
        'ITEM_LIST.ALERT.CHANGES.TITLE',
        'ITEM_LIST.ALERT.CHANGES.TEXT'
      );
      modal.submitClass = 'btn-outline-danger';
      modal.closeClass = 'btn-outline-light';
      return from(this.notification.openModal(modal));
    }

    return true;
  }

}

export interface PendingChangesBlocker {
  hasPendingChanges(): boolean
}
