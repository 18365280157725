import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {SelectListComponent} from "../../../../shared/select-list/select-list.component";
import {SelectListItem} from "../../../../shared/select-list/SelectListItem";
import {ApiService} from "../../../../core/services/api-service/api.service";
import {TimeModelDto} from "../../../../shared/entities/TimeModel/TimeModelDto";
import {
  AccessProfileDetailTimeModelDto
} from "../../../../shared/entities/accessProfile/AccessProfileDetailTimeModelDto";
import {take} from "rxjs";
import {ChangeDetectorValue} from "../../../../shared/util/change-detector/ChangeDetectorValue";
import {ItemsDetectorValue} from "../access-profile-locking-device-form/access-profile-locking-device-form.component";

@Component({
  selector: 'app-access-profile-time-model-form',
  templateUrl: './access-profile-time-model-form.component.html',
  styleUrls: ['./access-profile-time-model-form.component.scss']
})
export class AccessProfileTimeModelFormComponent {
  limitAccessProfileTimeModels: number = 16;

  @ViewChild(SelectListComponent) selectListComponent!: SelectListComponent
  protected accessProfileTimeModels: ChangeDetectorValue = new ChangeDetectorValue(new Set<string>());

  items: SelectListItem[] = [];

  @Input() accessReadonly: boolean = true;

  get accessReadonlyGetter(): boolean {
    return this.accessReadonly || this.licenseNotValid;
  }

  @Input() licenseNotValid: boolean = false;

  @Input() set assigned(value: ChangeDetectorValue) {
    this.apiService.timeModel.getAll().then( observer => {
      observer.pipe(take(1)).subscribe(timeModels => {
        this.items = timeModels.map<SelectListItem>((entry: TimeModelDto) => {
          return new SelectListItem(entry.uuid, entry.shortName)
            .addInfo(entry.description!)
            .setImage(`assets/ces/time-model/${this.apiService.timeModel.getImageNameForTimeModelType(entry.modelTypeId)}.svg`)
        });
      });
    });

    this.accessProfileTimeModels = new ChangeDetectorValue(((value.value?.timeModels as AccessProfileDetailTimeModelDto[]) || [])
      .map(time => time.uuid )
      .reduce<Set<string>>((set, entry) => {
        set.add(entry)
        return set
      }, new Set<string>()));
  }

  constructor(
    private apiService: ApiService
  ) { }

  onSelectionChanges(changed: boolean) {
    this.accessProfileTimeModels.value = this.selectListComponent.selectedItems
  }

  get changed(): boolean {
    return this.accessProfileTimeModels.hasChanges;
  }

  getSelectedTimeModelsUuids(): string[] {
    return Array.from(this.accessProfileTimeModels.value);
  }

  reset() {
    this.items = []
    this.accessProfileTimeModels.reset();
    this.selectListComponent.resetChangeDetection()
  }

}
