import {Subject} from "rxjs";

export let $notifyRelogin: Subject<boolean> = new Subject<boolean>();
export let $notifyNewToken: Subject<any> = new Subject<any>();
export let $notifyIOBStatusVersionError: Subject<boolean> = new Subject<boolean>();

export const set$notifyRelogin = (value: boolean) => $notifyRelogin.next(value);
export const set$notifyNewToken = (value: any) => $notifyNewToken.next(value);
export const set$notifyIOBStatusVersionError = (value: boolean) => $notifyIOBStatusVersionError.next(value);

