import {MenuItem} from './layouts/sidebar/menu.model';


export const MENU: MenuItem[] = [
  {
    id: 1,
    label: '$SYSTEM-ID',
    isTitle: true
  },
  {
    id: 2,
    label: 'MENU.SYSTEM',
    icon: 'ces-menu system',
    link: '/system',
  },
  {
    id: 3,
    label: 'MENU.USERS',
    icon: 'ces-menu users',
    link: '/users',
  },
  {
    id: 4,
    label: 'MENU.LOCKING_DEVICES',
    icon: 'ces-menu locking-devices',
    link: '/locking-devices',
  },
  {
    id: 5,
    label: 'MENU.LOCKING_MEDIA',
    icon: 'ces-menu locking-media',
    link: '/locking-media',
  },
  {
    id: 6,
    label: 'MENU.TIME_MODELS',
    icon: 'ces-menu time-models',
    link: '/time-models',
  },
  {
    id: 7,
    label: 'MENU.ACCESS_PROFILES',
    icon: 'ces-menu access-profiles',
    link: '/access-profiles',
  },
  {
    id: 8,
    label: 'MENU.LOGBOOK',
    icon: 'ces-menu logbook',
    link: '/logbook',
  },
  {
    id: 9,
    label: 'MENU.JOBS',
    icon: 'ces-menu jobs',
    link: '/jobs',
 // },
 // {
 //   id: 10,
 //   label: 'MENU.PROGRAMMING',
 //   icon: 'ces-menu programming',
 //   link: '/programming',
 //   visibleToRoles: [UserRole.OWNER, UserRole.ADMIN]
  },

]
