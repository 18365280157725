import {Directive, HostBinding} from '@angular/core';

@Directive({
  selector: '[cesDefaultImage]'
})
export class CesDefaultImageDirective {

  constructor() { }

  @HostBinding("class")
  defaultClasses = "border border-1 border-primary rounded-3 img-fluid"

}
