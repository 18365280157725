export interface LookupMapping {
  id: number;
  value: string;
}

export interface LookupType extends LookupMapping {
  id: number;
  value: string;
  type: string | null;
}

export class LookupTypeTranslation {
  lookup: LookupMapping[];
  translationKey: string;
  suffix: string;

  constructor(lookup: LookupMapping[], translationKey: string, suffix?: string) {
    this.lookup = lookup;
    this.translationKey = translationKey;
    this.suffix = suffix ? suffix : '';
  }
}

export interface LookupTranslation {
  name: string;
  translationKey: string;
}
