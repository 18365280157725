import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
  selector: '[icon]'
})
export class IconDirective {

  // See https://icon-sets.iconify.design/mdi/
  registeredClasses = new Map<string, string>([
    // MAIN
    ["name", "mdi mdi-label-outline"],
    ["description", "mdi mdi-text-short"],
    ["id", "mdi mdi-link-variant"],
    // AUTH
    ["system", "mdi mdi-cloud-outline"],
    ["user", "mdi mdi-account-outline"],
    ["real-name", "far fa-address-card"],
    ["security", "mdi mdi-shield-alert-outline"],
    ["role", "mdi mdi-shield-alert-outline"],
    ["password", "mdi mdi-lock-open-outline"],
    ["password-old", "mdi mdi-lock-open-outline"],
    ["password-confirm", "mdi mdi-check-circle-outline"],
    ["pin", "mdi mdi-asterisk"], // uil-dialpad mdi-form-textbox-password
    // TYPE
    ["technical", "mdi mdi-memory"],
    ["mechanical", "mdi mdi-wrench-outline"],
    ["mail", "mdi mdi-email-outline"],
    ["distance", "mdi mdi-arrow-expand-horizontal"],
    ["battery", "mdi mdi-battery-charging-outline"],
    ["audio", "mdi mdi-bullhorn-outline"],
    ["users", "mdi mdi-account-multiple-outline"],
    ["license", "mdi mdi-package-variant-closed"],
    ["phone", "mdi mdi-phone-outline"],
    ["mobile", "mdi mdi-cellphone"],
    ["settings", "mdi mdi-cog-outline"],
    ["reference", "mdi mdi-tag-outline"],
    ["version", "mdi mdi-package"],
    ["direction", "mdi mdi-swap-horizontal"],
    ["office", "mdi mdi-door"],
    ["log", "mdi mdi-math-log"],
    ["online", "mdi mdi-cloud-check"],

    // DATA
    ["text", ""],
    ["textarea", ""],
    ["select", "mdi mdi-check-circle-outline"],
    ["toggle", "mdi mdi-toggle-switch-outline"],
    // TIME
    ["date", "mdi mdi-calendar-month-outline"],
    ["time", "mdi mdi-clock-outline"],
    ["interval", "mdi mdi-timer-outline"],
    ["repetition", "mdi mdi-repeat"],
    ["month", "mdi mdi-calendar-month-outline"],
    ["execution", "mdi mdi-play-circle-outline"],
    ["end-type", "mdi mdi-stop-circle-outline"],
    ["timezone", "mdi mdi-web"],

    // LOCATION
    ["marker", "mdi mdi-map-marker-outline"],
    ["building", "mdi mdi-office-building-outline"],

    // ANY
    ["info", "mdi mdi-information-outline"],

    // SYMBOLS
    ["check", "mdi mdi-check-circle-outline"],
    ["cross","mdi mdi-close-circle-outline"],

    // ACTIONS
    ["action-add", "mdi mdi-plus-circle-outline"],
    ["action-delete", "mdi mdi-delete-circle-outline"],

    // IO-BRIDGE
    ["io-pin", "mdi mdi-door-closed-lock"],
    ["io-download", "mdi mdi-file-download-outline"],
    ["io-instruction", "mdi mdi-file-alert-outline"],
    ["io-start", "mdi mdi-lock-plus"],
    ["io-loading", "mdi mdi-loading"],
    ["io-sn", "mdi mdi-numeric"],

    // Visible Password
    ["pw-eye-on", "mdi mdi-eye-outline"],
    ["pw-eye-off", "mdi mdi-eye-off-outline"],
  ])

  @Input() icon: string = ""

  @HostBinding("class")
  get iconClass(): string {
    return this.registeredClasses.get(this.icon) || "mdi mdi-information-outline"
  }

  constructor() { }

}
