import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

import {SelectListItem} from "../../../../../shared/select-list/SelectListItem";
import {ApiService} from "../../../../../core/services/api-service/api.service";
import {SelectListComponent} from "../../../../../shared/select-list/select-list.component";
import {AccessProfileDto} from "../../../../../shared/entities/accessProfile/AccessProfileDto";
import {ChangeDetectorValue} from "../../../../../shared/util/change-detector/ChangeDetectorValue";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-user-access-profile-form',
  templateUrl: './user-access-profile-form.component.html',
  styleUrls: ['./user-access-profile-form.component.scss']
})
export class UserAccessProfileFormComponent {

  limitUserAccessProfiles: number = 64;
  userAccessProfileUuids: ChangeDetectorValue = new ChangeDetectorValue(['']);

  @ViewChild(SelectListComponent) selectListComponent!: SelectListComponent

  items: SelectListItem[] = [];
  selectedItems = new Set<string>()

  @Input() readonly: boolean = true;
  @Input() licenseExpired = false
  @Input() licenseTypeId: number = 1;

  @Output() userAccessProfilesChange: EventEmitter<ChangeDetectorValue> = new EventEmitter<ChangeDetectorValue>();

  get userAccessProfiles(): ChangeDetectorValue {
    return this.userAccessProfileUuids;
  }

  @Input() set userAccessProfiles(userAccessProfileUuids: ChangeDetectorValue) {
    this.items = [];
    this.userAccessProfileUuids = userAccessProfileUuids;
    this.userAccessProfileUuids.reset();
    this.selectedItems = new Set<string>();
    this.apiService.accessProfile.getAll().then(observe => observe.subscribe({
      next: accessProfiles => {
        this.userAccessProfileItemsHelper(accessProfiles);
        this.userAccessProfileSelectedItemsHelper(userAccessProfileUuids.value as string[]);
      }
    })).catch(err => console.log(err));
  }

  private userAccessProfileSelectedItemsHelper(userAccessProfileUuids: string[] | null) {
    if (userAccessProfileUuids == undefined || userAccessProfileUuids.length == 0) {
      return;
    }
    this.selectedItems = new Set<string>(userAccessProfileUuids);
  }

  private userAccessProfileItemsHelper(accessProfiles: AccessProfileDto[]) {
    this.items = accessProfiles.map<SelectListItem>((entry: AccessProfileDto) => {
      const selection = new SelectListItem(entry.uuid, entry.name)
        .addInfo(this.translate.instant("ACCESS_PROFILES.LIST.TIME_MODEL_COUNT", {count: entry.timeModelCount || 0}))
        .addInfo(this.translate.instant("ACCESS_PROFILES.LIST.LOCKING_DEVICE_COUNT", {lockingDevices: entry.lockingDeviceCount || 0}));
      if (this.licenseTypeId > 1) {
        selection.addInfo(this.translate.instant("ACCESS_PROFILES.LIST.LOCKING_DEVICE_GROUPS_COUNT", {groups: entry.groupLockingDeviceCount || 0}));
      }
      selection.setImage(`assets/ces/access-profiles/${this.apiService.accessProfile.getImageNameForAccessProfileType(entry.accessTypeId)}.svg`);
      return selection;
    });
  }


  constructor(
    private apiService: ApiService,
    private translate: TranslateService
  ) {
  }

  onSelectionChanges(c: any) {
    this.userAccessProfileUuids.value = Array.from(this.selectListComponent.selectedItems);
  }

  reset() {
    this.items = [];
    this.selectedItems.clear();
    this.selectListComponent.resetChangeDetection();
    this.userAccessProfileUuids.reset();
  }
}
