import {AccessProfile} from "./AccessProfile";
import {AccessProfileDto} from "./AccessProfileDto";

export class UpdateAccessProfileDto extends AccessProfile {
  timeModels: string[] | undefined;
  lockingDevices: string[] | undefined;
  lockingDeviceGroups: string[] | undefined;

  constructor(uuid: string,
              name: string,
              description: string | undefined,
              accessTypeId: number,
              timeModels: string[] | undefined,
              lockingDevices: string[] | undefined,
              lockingDeviceGroups: string[] | undefined,
              officeFunction: boolean,
              pin: boolean,) {
    super(uuid, name, description, accessTypeId, officeFunction, pin);
    this.timeModels = timeModels;
    this.lockingDevices = lockingDevices;
    this.lockingDeviceGroups = lockingDeviceGroups;
  }

  static emptyUpdateAccessProfileDto(): UpdateAccessProfileDto {
    return new this('-1', '', '', 0, [], [], [], false, false);
  }

  public toAccessProfileDto(): AccessProfileDto {
    return new AccessProfileDto(
      this.uuid,
      this.name,
      this.description,
      this.accessTypeId,
      this.timeModels?.length,
      this.lockingDevices?.length,
      this.lockingDeviceGroups?.length,
      this.officeFunction,
      this.pin,
      0
    );
  }
}
