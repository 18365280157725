
export class ClientDto {
  uuid: string;
  systemId: string;
  rfidTypeId: number;
  clientName: string;
  licenseTypeId: number;
  licenseExpireTimestamp: number | undefined;
  lastLoginUserUuid: string | undefined;
  lastLoginUsername: string | undefined;
  lastLoginTimestamp: number | undefined;
  lastChangeUserUuid: string | undefined;
  lastChangeUsername: string | undefined;
  lastChangeTimestamp: number | undefined;
  lockingDeviceCount: number;
  lockingMediaCount: number;
  userCount: number;
  idCount: number;
  licenseIdCount: number;
  onlineCount: number;
  licenseOnlineCount: number;
  accessProfileCount: number;
  timezoneId: number;
  selfRegistration: boolean | undefined;
  defaultBeeper: boolean | undefined;
  newUserPasswordChange: boolean | undefined;
  lockingDeviceParameterGroups: boolean | undefined;
  mobilePermissionDuration: number | undefined;
  passwordComplexity: number | undefined;
  mailDeleteAfterDays: number | undefined;
  jobsDeleteAfterDays: number | undefined;
  defaultOpeningDuration: number | undefined;
  hasParameterGroups: boolean | undefined;

  constructor(uuid: string,
              systemId: string,
              rfidTypeId: number,
              clientName: string,
              licenseTypeId: number,
              licenseExpireTimestamp: number | undefined,
              lastLoginUserUuid: string | undefined,
              lastLoginUsername: string | undefined,
              lastLoginTimestamp: number | undefined,
              lastChangeUserUuid: string | undefined,
              lastChangeUsername: string | undefined,
              lastChangeTimestamp: number | undefined,
              lockingDeviceCount: number,
              lockingMediaCount: number,
              userCount: number,
              idCount: number,
              licenseIdCount: number,
              onlineCount: number,
              licenseOnlineCount: number,
              accessProfileCount: number,
              timezoneId: number,
              selfRegistration: boolean | undefined,
              defaultBeeper: boolean | undefined,
              newUserPasswordChange: boolean | undefined,
              lockingDeviceParameterGroups: boolean | undefined,
              mobilePermissionDuration: number | undefined,
              passwordComplexity: number | undefined,
              mailDeleteAfterDays: number | undefined,
              jobsDeleteAfterDays: number | undefined,
              defaultOpeningDuration: number | undefined,
              hasParameterGroups: boolean | undefined) {
    this.uuid = uuid;
    this.systemId = systemId;
    this.rfidTypeId = rfidTypeId;
    this.clientName = clientName;
    this.accessProfileCount = accessProfileCount;
    this.lockingDeviceCount = lockingDeviceCount;
    this.lockingMediaCount = lockingMediaCount;
    this.lastLoginUserUuid = lastLoginUserUuid;
    this.lastLoginUsername = lastLoginUsername;
    this.lastLoginTimestamp = lastLoginTimestamp;
    this.licenseExpireTimestamp = licenseExpireTimestamp;
    this.lastChangeUserUuid = lastChangeUserUuid;
    this.lastChangeUsername = lastChangeUsername;
    this.lastChangeTimestamp = lastChangeTimestamp;
    this.userCount = userCount;
    this.idCount = idCount;
    this.licenseIdCount = licenseIdCount;
    this.onlineCount = onlineCount;
    this.licenseOnlineCount = licenseOnlineCount;
    this.licenseTypeId = licenseTypeId;
    this.timezoneId = timezoneId;
    this.selfRegistration = selfRegistration;
    this.defaultBeeper = defaultBeeper;
    this.newUserPasswordChange = newUserPasswordChange;
    this.lockingDeviceParameterGroups = lockingDeviceParameterGroups;
    this.mobilePermissionDuration = mobilePermissionDuration;
    this.passwordComplexity = passwordComplexity;
    this.mailDeleteAfterDays = mailDeleteAfterDays;
    this.jobsDeleteAfterDays = jobsDeleteAfterDays;
    this.defaultOpeningDuration = defaultOpeningDuration;
    this.hasParameterGroups = hasParameterGroups;
  }

  static emptyClientDto(): ClientDto {
    return new ClientDto('', '',0,'',0,undefined,
      undefined,undefined,undefined,undefined,
      undefined,undefined,0,0,0,
      0,0,0,0,0,0,undefined,
      undefined,undefined,undefined,undefined,
      undefined,undefined,undefined,undefined,undefined);
  }

  updateClientDto(client: ClientDto): ClientDto {
    return client;
  }
}
