import {LookupMapping} from "./lookup";

export const HANDLE_INNER_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "C01"
  },
  {
    "id": 2,
    "value": "C02"
  },
  {
    "id": 3,
    "value": "C03"
  },
  {
    "id": 4,
    "value": "C04"
  },
  {
    "id": 5,
    "value": "C05"
  },
  {
    "id": 6,
    "value": "C06"
  },
  {
    "id": 7,
    "value": "C07"
  },
  {
    "id": 8,
    "value": "C08"
  },
  {
    "id": 9,
    "value": "C09"
  },
  {
    "id": 10,
    "value": "C10"
  },
  {
    "id": 11,
    "value": "C12"
  },
  {
    "id": 12,
    "value": "C13"
  },
  {
    "id": 13,
    "value": "PP"
  },
  {
    "id": 14,
    "value": "PT"
  },
  {
    "id": 15,
    "value": "P1"
  },
  {
    "id": 16,
    "value": "P2"
  },
  {
    "id": 17,
    "value": "T1"
  },
  {
    "id": 18,
    "value": "00"
  },
  {
    "id": 19,
    "value": "TD01"
  },
  {
    "id": 20,
    "value": "TD02"
  },
  {
    "id": 21,
    "value": "TD03"
  },
  {
    "id": 22,
    "value": "TD04"
  },
  {
    "id": 23,
    "value": "TD05"
  }
];

export const HANDLE_OUTER_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "C01"
  },
  {
    "id": 2,
    "value": "C02"
  },
  {
    "id": 3,
    "value": "C03"
  },
  {
    "id": 4,
    "value": "C04"
  },
  {
    "id": 5,
    "value": "C05"
  },
  {
    "id": 6,
    "value": "C06"
  },
  {
    "id": 7,
    "value": "C07"
  },
  {
    "id": 8,
    "value": "C08"
  },
  {
    "id": 9,
    "value": "C09"
  },
  {
    "id": 10,
    "value": "C10"
  },
  {
    "id": 11,
    "value": "C11"
  },
  {
    "id": 12,
    "value": "C12"
  },
  {
    "id": 13,
    "value": "C13"
  },
  {
    "id": 14,
    "value": "C14"
  },
  {
    "id": 15,
    "value": "TD01"
  },
  {
    "id": 16,
    "value": "TD02"
  },
  {
    "id": 17,
    "value": "TD03"
  },
  {
    "id": 18,
    "value": "TD04"
  },
  {
    "id": 19,
    "value": "TD05"
  }
];
