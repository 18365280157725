export class UserResetPasswordDto {
  systemId: string;
  username: string;
  originClient: string = 'webclient';

  constructor(systemId: string, username: string) {
    this.systemId = systemId;
    this.username = username;
  }
}
