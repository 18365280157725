
import {Subject} from "rxjs";
import {TimeModelDto} from "../entities/TimeModel/TimeModelDto";

let globalTimeModels: TimeModelDto[] | undefined = undefined;
let globalTimeModelEditName: string | undefined = undefined;

export let globalTimeModelsSubject: Subject<TimeModelDto[]> = new Subject<TimeModelDto[]>;

export const setGlobalTimeModels = (models: TimeModelDto[]) => {
  globalTimeModels = models;
  globalTimeModelsSubject.next(models);
};

export const getGlobalTimeModels = () => globalTimeModels?.filter(timeModel => timeModel != undefined);

export const setGlobalTimeModelEditName = (model: string | undefined) => globalTimeModelEditName = model;

export const getGlobalTimeModelEditName = () => globalTimeModelEditName;

