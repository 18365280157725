import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from "@angular/platform-browser";

@Pipe({
  name: 'Trust'
})
export class TrustPipe implements PipeTransform {

  constructor(protected sanitizer: DomSanitizer) {
  }

  transform(value: any, type: 'html' | 'style' | 'script' | 'url' | 'resourceUrl'): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case "html": return this.sanitizer.bypassSecurityTrustHtml(value);
      case "style": return this.sanitizer.bypassSecurityTrustStyle(value);
      case "script": return this.sanitizer.bypassSecurityTrustScript(value);
      case "url": return this.sanitizer.bypassSecurityTrustUrl(value);
      case "resourceUrl": return this.sanitizer.bypassSecurityTrustResourceUrl(value);
    }

    throw new Error(`Invalid type for trust-pipe: ${type}`);
  }

}
