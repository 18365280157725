<div class="form-floating form-floating-custom" [ngClass]="{'cursor-pointer': !readonly}" >
  <div [attr.readonly]="readonly ? true : undefined" id="timezone" class="text-wrap h-auto"
       [ngClass]="readonly ? 'form-control' : 'form-select'" (click)="openModal(selectModal)">
    <div class="text-start me-4">{{ currentTranslationText | translate }}</div>
  </div>
  <div class="form-floating-icon">
    <i icon="timezone"></i>
  </div>
  <label for="timezone">{{ "SYSTEM.FIELD.TIMEZONE.TEXT" | translate }}</label>
</div>


<!-- MODAL -->
<ng-template #selectModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ "SYSTEM.TIMEZONE.TITLE" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>

  <div class="row my-4 mx-3">
    <div class="d-flex flex-row form-control">
      <i class="mdi mdi-magnify search-icon align-self-center"></i>
      <input type="text" class="form-control border-0" (input)="onSearch($event)"
             placeholder="{{ 'SYSTEM.TIMEZONE.SEARCH' | translate }}"/>
    </div>
  </div>

  <div class="modal-body">
    <div class="list-group">
      <div *ngFor="let entry of filteredTimezones" id="t-{{entry.key}}" class="list-group-item list-group-item-action cursor-pointer"
           (click)="setValue(entry.key); modal.close()"
           [ngClass]="{
           'text-white bg-primary fw-bold': currentValue.value == entry.key && !isBlackDesign,
           'pending-changes': currentValue.value == entry.key && isBlackDesign}">
        <ngb-highlight [highlightClass]="'highlight'" [result]="('TIMEZONE.' + entry.value.toUpperCase()) | translate" [term]="searchValue"></ngb-highlight>
        <i *ngIf="currentValue.value == entry.key" icon="select" class="ms-2"></i>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-secondary" (click)="modal.close()"> {{ "BUTTON.CANCEL" | translate }} </button>
  </div>
</ng-template>

