import {CESService} from "../../ces.service";
import {Router} from "@angular/router";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {UserGroupDto} from "../../../../shared/entities/user-group/UserGroupDto";
import {Observable} from "rxjs";
import {UserGroupDetailsDto} from "../../../../shared/entities/user-group/UserGroupDetailsDto";
import {ToastService} from "../../../../shared/notification/toast/toast.service";

export class UserGroupService extends CESService {
  constructor(router: Router, httpClient: HttpClient, notification: ToastService) {
    super(router, httpClient, notification)
  }

  // default CRUD
  async getAll(): Promise<Observable<UserGroupDto[]>> {
    return this.httpClient.get<UserGroupDto[]>(await this.buildUrl(undefined, '/group/users'));
  }

  async getSpecific(groupUuid: string): Promise<Observable<UserGroupDetailsDto>> {
    return this.httpClient.get<UserGroupDetailsDto>(await this.buildUrl(undefined, `/group/users/${groupUuid}`));
  }

  async add(userGroup: UserGroupDto): Promise<Observable<UserGroupDto>> {
    return this.httpClient.post<UserGroupDto>(await this.buildUrl(undefined, '/group/users'), userGroup);
  }

  async update(user: UserGroupDto): Promise<Observable<UserGroupDto>> {
    return this.httpClient.put<UserGroupDto>(await this.buildUrl(undefined, `/group/users/${user.uuid}`), user);
  }

  async delete(groupUuid: string): Promise<Observable<HttpResponse<any>>> {
    return this.httpClient.delete<any>(await this.buildUrl(undefined, `/group/users/${groupUuid}`), {observe: 'response'});
  }

}
