import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserGroupsComponent } from './user-groups.component';
import { UserGroupFormComponent } from './user-group-forms/user-group-form/user-group-form.component';
import { UserGroupAccessProfileFormComponent } from './user-group-forms/user-group-access-profile-form/user-group-access-profile-form.component';
import { UserGroupAssignmentFormComponent } from './user-group-forms/user-group-assignment-form/user-group-assignment-form.component';
import {NgbNavModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {UserUsersModule} from "../user-users/user-users.module";
import {SharedModule} from "../../../shared/shared.module";
import {CoreModule} from "../../../core/core.module";
import {ReactiveFormsModule} from "@angular/forms";



@NgModule({
  declarations: [
    UserGroupsComponent,
    UserGroupFormComponent,
    UserGroupAccessProfileFormComponent,
    UserGroupAssignmentFormComponent
  ],
  exports: [
    UserGroupsComponent
  ],
  imports: [
    CommonModule,
    NgbNavModule,
    TranslateModule,
    UserUsersModule,
    SharedModule,
    CoreModule,
    ReactiveFormsModule
  ]
})
export class UserGroupsModule { }
