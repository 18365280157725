import {Component} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {environment} from "../../../../../../../environments/environment";
import {AngularDeviceInformationService} from "angular-device-information";
import {
  LocalStorageSchemaService
} from "../../../../../../core/services/json-schema-service/local-storage-schema.service";

@Component({
  selector: 'info-download',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent {

  local: LocalStorageSchemaService = new LocalStorageSchemaService();

  constructor(public activeModal: NgbActiveModal, private deviceInformationService: AngularDeviceInformationService) {
  }

  get imageLink(): string {
    switch (this.local.localStorage('lang').plain) {
      case 'fr':
        return `assets/images/ioBridge/connectedIOBridge-fr.png`;
      case 'de':
        return `assets/images/ioBridge/connectedIOBridge-de.png`;
      case 'nl':
        return `assets/images/ioBridge/connectedIOBridge-nl.png`;
      case 'en':
      default:
        return `assets/images/ioBridge/connectedIOBridge-en.png`;
    }
  }


  get downloadLink(): string {
    const osInformation: string = this.deviceInformationService.getDeviceInfo().os.toLowerCase();
    if (osInformation.includes('windows')) {
      return environment.ioBridgeDownloadUrlWithoutType + '.exe';
    } else if (osInformation.includes('mac')) {
      return environment.ioBridgeDownloadUrlWithoutType + '.dmg';
    }
    return environment.ioBridgeDownloadUrlWithoutType;
  }

}
