import {Component} from '@angular/core';
import {IModalContent} from "../IModalContent";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {FormValidator} from "../../../../shared/util/FormValidator";
import {SubmitUtils} from "../../../../shared/util/SubmitUtils";
import {ApiService} from "../../../../core/services/api-service/api.service";
import {take} from "rxjs";
import {ToastService} from "../../../../shared/notification/toast/toast.service";

@Component({
  selector: 'app-change-pin',
  templateUrl: './change-pin.component.html',
  styleUrls: ['./change-pin.component.scss']
})
export class ChangePinComponent implements IModalContent {
  changePinForm: FormGroup
  loading: boolean = false;

  constructor(
    formBuilder: FormBuilder,
    private notification: ToastService,
    private apiService: ApiService,
    private ngbModal: NgbActiveModal) {

    this.changePinForm = formBuilder.group({
      newPin: ['', [Validators.required, FormValidator.patternPIN]],
      confirmPin: ['', [Validators.required, FormValidator.patternPIN]]
    }, {
      validators: [
        FormValidator.mustMatch('confirmPin', 'newPin')]
    });
  }

  get f() {
    return this.changePinForm.controls;
  }

  async onSubmit() {
    if (SubmitUtils.checkControls(this.notification, true, this.changePinForm)) {
      return;
    }

    this.loading = true;
    (await this.apiService.user.changePin(this.changePinForm.get('newPin')?.value)).pipe(take(1)).subscribe({
      next: () => {
        this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.UPDATE');
        this.onClose();
      },
      complete: () => this.loading = false
    })
  }

  onClose() {
    this.ngbModal.close()
  }

}
