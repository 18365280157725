@if (time && !date) {
  <div class="form-floating flex-grow-1 form-floating-custom date-picker-container"
       [ngClass]="{extraClasses: true, 'invalid-feedback-ngd': invalidTime, 'pending-changes' : !invalidTime && showChanges}">
    <div class="form-floating-icon">
      <i icon="time"></i>
    </div>
    <div style="display: flex">
      <label class="label-date-time">{{ titleText | translate }}</label>
      <div style="align-content: end; padding-left: 33px;">
        <ngb-timepicker id="timeEnd" [disabled]="readonly" [(ngModel)]="timeValue" [spinners]="false" [seconds]="false"></ngb-timepicker>
      </div>
    </div>
  </div>
}
@if (date && !time) {
  <div [ngClass]="{'form-floating flex-grow-1 form-floating-custom date-picker-container': true, extraClasses: true, 'invalid-feedback-ngd': invalidDate, 'pending-changes' : !invalidDate && showChanges}">
    <div class="form-floating form-floating-custom mb-3">
      <input class="form-control"
             id="date-{{dateId}}"
             placeholder="dd.mm.yyyy"
             name="dp"
             [(ngModel)]="dateValue"
             ngbDatepicker
             [placement]="'top'"
             [disabled]="readonly"
             [firstDayOfWeek]="1"
             (click)="toggle(d)"
             #d="ngbDatepicker"/>
      <label style="overflow: visible;"
             for="date-{{dateId}}">{{ titleText | translate }}</label>
      <div class="form-floating-icon" style="margin-top: 14px">
        <i icon="date"></i>
      </div>
    </div>
    <div *ngIf="clearable && !readonly" style="right: 0; position: absolute; height: 100%;">
      <button class="btn btn-outline-secondary"
              ngbTooltip="{{ titleText | translate }}"
              [disableTooltip]="true"
              style="height: 100%;"
              (click)="dateValue = null">
        <i icon="cross"></i></button>
    </div>
  </div>
}
