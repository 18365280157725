import {Pipe, PipeTransform} from '@angular/core';
import {KeyValuePipe} from '@angular/common';
import {SelectListItem} from "../../shared/select-list/SelectListItem";

const keepOrder = (a: any, b: any) => a;

// This pipe uses the angular KeyValue pipe. but doesn't change order.
@Pipe({
  name: 'defaultOrderAndSearchKeyValue'
})
export class DefaultOrderAndSearchKeyValuePipe extends KeyValuePipe {

  override transform(value: any, ...args: any[]): any {
    let returnValueMap: Map<string, SelectListItem> = new Map<string, SelectListItem>;
    let returnValueStringArray: string[] = [];
    for (const arg of args) {
      if (value instanceof Array) {
        for (const valueElement of value) {
          if (valueElement.toLowerCase().includes(arg.toLowerCase()) && returnValueStringArray.filter(value1 => value1.includes(arg.toLowerCase())).length == 0) {
            returnValueStringArray.push(valueElement);
          }
        }
      } else {
        [...new Map([...value.entries()].filter(([key, value]) => {
            return value.name.toLowerCase().indexOf(arg.toLowerCase()) != -1
              && [...returnValueMap.entries()].filter(([key1, _value1]) => key == key1).length == 0;
          })
        ).entries()].forEach((entry) => returnValueMap.set(<string>entry[0], <SelectListItem>entry[1]));
      }
    }

    if (value instanceof Array && args.length > 0) {
      return super.transform(returnValueStringArray, keepOrder);
    } else if (args.length > 0) {
      return super.transform(returnValueMap, keepOrder);
    }
    return super.transform(value, keepOrder);
  }

}
