<form [formGroup]="lockingDeviceGroupForm">
  <div class="row row-cols-lg-6 g-4">

    <div class="col-lg-12">
      <div class="card shadow-none">
        <div class="card-body">

          <div class="form-floating form-floating-custom mb-3">
            <input [readonly]="accessReadonly" type="text" class="form-control"
                   data-testid="locking-group-name" id="name" formControlName="name"
                   [ngClass]="{ 'is-invalid': f['name'] | hasErrors }">
            <label for="name">{{ "LOCKING_DEVICES.GROUPS.FIELD.NAME.TEXT" | translate }}*</label>
            <div class="form-floating-icon">
              <i icon="name"></i>
            </div>
            <div *ngIf="f['name'] | hasErrors" class="invalid-feedback">
              <div *ngIf="f['name'] | hasErrors:'required'">
                {{ "LOCKING_DEVICES.GROUPS.FIELD.NAME.REQUIRED" | translate }}
              </div>
              <div *ngIf="f['name'] | hasErrors:'maxlength'">
                {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("LOCKING_DEVICES.GROUPS.FIELD.NAME.TEXT" | translate) : ('' + validatorMaxLength32) }}
              </div>
            </div>
          </div>

          <div class="form-floating form-floating-custom mb-3">
            <input [readonly]="accessReadonly" type="text" class="form-control"
                   data-testid="locking-group-description" id="description" formControlName="description">
            <label for="description">{{ "LOCKING_DEVICES.GROUPS.FIELD.DESCRIPTION.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="description"></i>
            </div>
            <div *ngIf="f['description'] | hasErrors" class="invalid-feedback">
              <div *ngIf="f['description'] | hasErrors:'maxlength'">
                {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("LOCKING_DEVICES.GROUPS.FIELD.DESCRIPTION.TEXT" | translate) : ('' + validatorMaxLength64) }}
              </div>
            </div>
          </div>

          <div class="form-floating form-floating-custom">
            <input readonly id="groupTypeId" class="form-control" [value]="deviceGroupTypeTranslationText | translate">
            <label for="groupTypeId">{{ "LOCKING_DEVICES.GROUPS.FIELD.GROUP_TYPE.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="technical"></i>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</form><!-- end form -->
