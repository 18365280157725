<div [loadingCes]="loading" id="content">
  <div class="modal-header border-bottom-0">
    <h4 class="modal-title" id="modal-basic-title">{{ "USERMENU.CHANGE_PIN.TITLE" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="changePinForm">

      <p class="text-muted">{{ "USERMENU.CHANGE_PIN.INFO" | translate }}</p>

      <div class="form-floating form-floating-custom mb-3">
        <input type="password" class="form-control" id="new-password"
               formControlName="newPin" placeholder="-" viewablePassword
               [ngClass]="{ 'is-invalid': f['newPin'] | hasErrors }">
        <label for="new-password">{{ "USERMENU.CHANGE_PIN.FIELD.NEW_PIN.TEXT" | translate }}</label>
        <div class="form-floating-icon">
          <i icon="pin"></i>
        </div>
        <div *ngIf="f['newPin'] | hasErrors" class="invalid-feedback">
          <span *ngIf="f['newPin'] | hasErrors:'required'">{{ "USERMENU.CHANGE_PIN.FIELD.NEW_PIN.REQUIRED" | translate }}</span>
          <span *ngIf="f['newPin'] | hasErrors:'pattern'">{{ "USERMENU.CHANGE_PIN.FIELD.NEW_PIN.INVALID" | translate }}</span>
        </div>
      </div>

      <div class="form-floating form-floating-custom">
        <input type="password" class="form-control" id="conform-password"
               formControlName="confirmPin" placeholder="-" viewablePassword
               [ngClass]="{ 'is-invalid': f['confirmPin'] | hasErrors }">
        <label for="confirm-password">{{ "USERMENU.CHANGE_PIN.FIELD.CONFIRM_PIN.TEXT" | translate }}</label>
        <div class="form-floating-icon">
          <i icon="password-confirm"></i>
        </div>
        <div *ngIf="f['confirmPin'] | hasErrors" class="invalid-feedback">
          <span *ngIf="f['confirmPin'] | hasErrors:'required'">{{ "USERMENU.CHANGE_PIN.FIELD.CONFIRM_PIN.REQUIRED" | translate }}</span>
          <span *ngIf="f['confirmPin'] | hasErrors:'pattern'">{{ "USERMENU.CHANGE_PIN.FIELD.CONFIRM_PIN.INVALID" | translate }}</span>
          <span *ngIf="f['confirmPin'] | hasErrors:'mustMatch'">{{ "USERMENU.CHANGE_PIN.FIELD.CONFIRM_PIN.MATCH" | translate }}</span>
        </div>
      </div>

    </form>
  </div>
  <div class="modal-footer border-top-0">
    <button type="button" class="btn text-primary btn-outline-light" (click)="onSubmit()">{{ "USERMENU.CHANGE_PIN.TITLE" | translate }}</button>
    <button type="button" class="btn text-secondary btn-outline-light" (click)="onClose()">{{ "NOTIFICATION.ALERT.BUTTON.CANCEL" | translate }}</button>
  </div>
</div>
