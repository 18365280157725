import {LookupMapping} from "./lookup";

export const PROGRAMMING_STATE: LookupMapping[] = [
  {
    "id": 1,
    "value": "in_programming"
  },
  {
    "id": 2,
    "value": "finished"
  },
  {
    "id": 3,
    "value": "error"
  }
]
