import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {AuthenticationService} from "../services/auth-service/auth.service";

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {


  constructor(private authService: AuthenticationService,
              private router: Router) {

  }

  canActivate(routeSnapshot: ActivatedRouteSnapshot, routerSnapshot: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.authService.isValidSession()) {
      this.router.navigate(['/user/login']).catch();
      return false;
    } else {
      return true;
    }
  }

}
