export class LogbookDetailDto {
  eventInfoTypeId: number;
  value: string;
  valueUpdated?: string;
  technicalType?: string;

  constructor(eventInfoTypeId: number, value: string, valueUpdated?: string, technicalType?: string) {
    this.technicalType = technicalType;
    this.eventInfoTypeId = eventInfoTypeId;
    this.value = value;
    this.valueUpdated = valueUpdated;
  }
}
