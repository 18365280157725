import {Component, Input} from "@angular/core";
import {environment} from "../../../../../../environments/environment";
import {InfoComponent} from "./info/info.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TranslateService} from "@ngx-translate/core";
import {AngularDeviceInformationService} from "angular-device-information";


@Component({
  selector: 'app-io-bridge-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent {

  @Input() updateRequired: boolean = false;

  constructor(private modalService: NgbModal,
              private translate: TranslateService,
              private deviceInformationService: AngularDeviceInformationService)
  { }

  openInfo() {
    this.modalService.open(InfoComponent);
  }

  get downloadLink(): string {
    const osInformation: string = this.deviceInformationService.getDeviceInfo().os.toLowerCase();
    if (osInformation.includes('windows')) {
      return `${environment.ioBridgeDownloadUrlWithoutType}.${environment.osFileExtensions.windows}`;
    } else if (osInformation.includes('mac')) {
      return `${environment.ioBridgeDownloadUrlWithoutType}.${environment.osFileExtensions.mac}`;
    }
    return environment.ioBridgeDownloadUrlWithoutType;
  }

  get translatedButtonText(): string {
    if (this.updateRequired) {
      return this.translate.instant("PROGRAMMING.BUTTONS.UPDATE");
    }
    return this.translate.instant("PROGRAMMING.BUTTONS.DOWNLOAD");
  }

}
