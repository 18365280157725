export class BindingState {
  name: string;
  translationKey: string;
  order: number;

  constructor(stateName: string, translationKey: string, order: number) {
    this.name = stateName;
    this.translationKey = translationKey;
    this.order = order;
  }

  static emptyBindingState(): BindingState {
    return new this('', '', 0);
  }

}
