<div [ngClass]="{'card p-4': !lightContainer}">

  <!-- MULTI ACTION BAR -->
  @if (multiSelectActions.length != 0 && !disableButtons) {
    <div *skeleton="showLoading; repeat: 1, width: '90vw', height: '100px'" [ngbCollapse]="itemSelected"
         class="col-12 item-multi-action-bar">
      <div class="card mb-0 shadow-sm border-secondary mb-2">
        <div class="card-header d-flex px-0 mx-1 px-3 black-multi-select">
          <input id="selectAll" data-testid="selectAll-multi"
                 [checked]="multiSelectItems.size != 0 && multiSelectItems.size == displayedItemList.length"
                 type="checkbox"
                 class="form-check-input font-size-20 mt-0 black-margin-height-auto"
                 (change)="onSelectAllChange($event)"
                 [attr.disabled]="displayedItemList.length == 0 ? true : null">
          <label for="selectAll" class="mb-0 ps-1 black-margin-height-auto"
                 [ngClass]="{'text-muted': displayedItemList.length == 0}">
            &nbsp;&nbsp;{{ (enableReloadItems ? "BUTTON.MULTI.SELECT_LOADED" : "BUTTON.MULTI.SELECT_ALL") | translate }}
          </label>

          <div class="black-flex-middle">
            <span *ngIf="multiSelectItems.size == 0">{{ "ITEM_LIST.MULTI_SELECT.NONE" | translate }}</span>
            <span *ngIf="multiSelectItems.size == 1">{{ "ITEM_LIST.MULTI_SELECT.ONE" | translate }}</span>
            <span
              *ngIf="multiSelectItems.size > 1">{{ "ITEM_LIST.MULTI_SELECT.COUNT" | translate:{count: multiSelectItems.size} }}</span>
          </div>
          <div class="black-flex-end">

            <div *ngIf="multiSelectSplitActions && multiSelectSplitActions.children.length > 0" ngbDropdown
                 class="d-inline-block" [ngClass]="multiSelectSplitActions!.styleClasses? multiSelectSplitActions!.styleClasses : 'btn-outline-secondary'">
              <button type="button"
                      [ngClass]="multiSelectItems.size < 1 ? 'btn btn-outline-light text-muted' : 'btn btn-outline-primary'"
                      [disabled]="multiSelectItems.size < 1"
                      id="dropdownMultiSelectSplitActions" ngbDropdownToggle>
                {{ multiSelectSplitActions!.key | translate }}
                <i *ngIf="multiSelectSplitActions.icon != null" [ngClass]="multiSelectSplitActions.icon"></i>
              </button>
              <div ngbDropdownMenu aria-labelledby="dropdownMultiSelectSplitActions" class="btn-outline-secondary">
                <button *ngFor="let child of multiSelectSplitActions!.children" ngbDropdownItem
                        (click)="onClickMultiSplitAction(child)"
                        [ngClass]="child.styleClasses? child.styleClasses : 'btn-outline-secondary'">
                  {{ child.key | translate }}
                </button>
              </div>
            </div>

            <div *ngIf="multiSelectActions.length > 0">
              <button *ngFor="let multiAction of multiSelectActions" type="button" class="m-1 btn"
                      data-testid="item-list-action-buttons"
                      (click)="onClickMultiAction(multiAction)" [disabled]="multiSelectItems.size < 1"
                      [ngClass]="multiAction.styleClasses? multiAction.styleClasses : 'btn-outline-secondary'">
                <i *ngIf="multiAction.icon != null" [ngClass]="multiAction.icon"></i>
                {{ multiAction.key | translate }}
              </button>
            </div>

          </div>
        </div>
      </div>
    </div>
  }

  <div id="itemList-card-content"> <!-- CONTENT -->

    <!-- NO CONTENT MESSAGE -->
    <div *ngIf="!showLoading && displayedItemList.length == 0 && !itemSelected">
      <p
        class="my-4 text-center">{{ (messageNoContent != null ? messageNoContent : "ITEM_LIST.EMPTY") | translate }}</p>
    </div>

    <!-- LIST VIEW -->
    <collapse-container #listView="collapseContainer" id="itemList" [initialCollapse]="false"
                        [collapse]="itemSelected || displayedItemList.length == 0" class="list-group">

      <!-- LIST ITEMS -->

      @for (item of displayedItemList; let i = $index; track i; ) {
        <div
          *skeleton="showLoading; repeat: 50, className: ['col-xl-4', 'col-sm-6', 'py-2'], width: '530px', height: '130px', acceptedLoadingDuration: skeletonShowingAfterMS"
          class="col-xl-4 col-sm-6 py-2 fly-in" [id]="'item-' + i">
          <div class="card border shadow-none h-100 mb-2 black-no-border">
            <input *ngIf="multiSelectActions.length != 0 && item[1].selectable && !disableButtons && !item[1].readonly" type="checkbox"
                   class="form-check-input item-selector m-1 font-size-22"
                   [ngClass]="{'perfectly-hidden': item[0] == 'remove-me'}"
                   [checked]="multiSelectItems.has(item[0])" (change)="onSelectChange($event, item[1])"
                   style="z-index: 2">
            <div class="d-flex flex-column parent h-100"
                 [ngClass]="{'list-group-item-action cursor-pointer': item[1].actions.length != 0}"
                 (click)="onClickMainListAction(item[1])">
              <div class="d-flex h-100 card-body black-padding-reset" [ngClass]="{'pb-0': item[1].actions.length == 1}">

                <div class="d-flex align-items-start position-relative">
                  <!-- IMAGE -->
                  <div *ngIf="item[1].image != null && item[1].image != ''"
                       class="flex-shrink-0 avatar rounded-circle me-3">
                    <img id="itemImage" [src]="item[1].image | Trust:'url'" alt="" cesDefaultImage>
                    <div *ngIf="item[1].overlay != null" class="d-flex justify-content-center">
                      <img id="itemOverlay" [src]="itemBatteryOverlay(item[1].overlay)" style="width: 2.3em" alt="icon">
                    </div>
                  </div>

                  <div class="flex-grow-1">
                    <div class="d-flex gap-2">
                      <!-- TITLE -->
                      <h5 class="font-size-15 mb-0 text-truncate">
                        <span
                          class="text-dark text-wrap">{{ (prefixTitleTranslation + item[1].name) | translate }}</span>
                      </h5>
                      <div *ngIf="item[1].badges.length != 0" class="d-flex gap-1"
                           [ngStyle]="{'margin-right.px' : multiSelectActions.length != 0 && item[1].selectable && !disableButtons && !item[1].readonly ? 30 : 0,
                            'margin-top.px': multiSelectActions.length != 0 && item[1].selectable && !disableButtons && !item[1].readonly ? -9 : 0}">
                        <div *ngFor="let badge of item[1].badges" class="badge" [ngClass]="badge.style"
                             placement="left"
                             [ngbTooltip]="badge.description"
                             triggers="click mouseenter:mouseleave">
                          @if (badge.imgUrl) {
                            <img style="margin: 0.2em 0.3em 0.2em 0.5em; max-width: 20px; max-height: 20px;" src="{{badge.imgUrl}}">
                          } @else {
                            <i [ngClass]="badge.icon"></i>
                          }
                        </div>
                      </div>
                    </div>
                    <p *ngFor="let info of item[1].info" class="text-muted text-wrap mb-0"
                       [innerHTML]="(info || '') | Trust:'html'"></p>
                  </div>
                </div>
              </div>

              <!-- SINGLE ACTION -->
              <div *ngIf="item[1].actions.length == 1 && !disableButtons" class="d-flex h-100 visible-parent-hover">
                <div class="btn flex-grow-1 align-self-end text-end text-primary fw-medium">
                  <i *ngIf="item[1].actions[0].actionIcon != null" [ngClass]="item[1].actions[0].actionIcon"></i>
                  {{ item[1].actions[0].key | translate }}
                </div>
              </div>
            </div>
            <!-- end card body -->

            <!-- LIST ACTIONS -->
            <div *ngIf="item[1].actions.length > 1 && !disableButtons" class="btn-group btn-icon" role="group">
              <button *ngFor="let action of item[1].actions" type="button" class="btn btn-outline-light"
                      (click)="onClickListAction(item[1], action)">
                <i *ngIf="action.actionIcon != null" [ngClass]="action.actionIcon"></i>
                {{ action.key | translate }}
              </button>
            </div>
          </div><!-- end card -->
        </div>
      }

      <div *ngIf="footerTemplate != null" id="itemListFooter" class="row pt-4">
        <div [ngTemplateOutlet]="footerTemplate"></div>
      </div>
    </collapse-container>

    <!-- EDIT VIEW -->
    <collapse-container id="itemEdit" [collapse]="!itemSelected" [initialCollapse]="true">
      <div *ngIf="!_hideEditHeaders" id="itemEditHeader" class="card-header pt-0">

        <div class="d-flex flex-row flex-wrap">
          <!-- BACK BUTTON -->
          <div *ngIf="displayedItemList.length != 0" class="flex-shrink-0 pe-2">
            <button class="btn btn-outline-secondary m-1" (click)="onClickReturn()"
                    data-testid="back-button-item-list">
              <i class="mdi mdi-chevron-left font-24"></i>
              {{ "BUTTON.RETURN" | translate }}
            </button>
          </div>
          <!-- IMAGE -->
          <div *ngIf="selectedListItem?.image != null; else placeholder"
               class="flex-shrink-0 avatar rounded-circle me-3">
            <img [src]="selectedListItem!.image | Trust:'url'" alt="" cesDefaultImage>
          </div>
          <ng-template #placeholder>
            <div class="m-2"></div>
          </ng-template>
          <!-- INFO -->
          <div class="flex-grow-1 overflow-hidden align-self-center flex-column">
            <h5 class="font-size-15 mb-1 text-truncate"><span
              class="text-dark">{{ headerName || selectedListItem?.name }}</span></h5>
            <p *ngIf="selectedListItem != null" class="text-muted text-truncate mb-0"
               [innerHTML]="selectedListItem.info.join(', ') | Trust:'html'"></p>
          </div>

          <div id="itemEditActions" class="d-flex flex-row flex-wrap justify-content-end"
               [ngClass]="{'flex-grow-1': (itemActions || []).length > 0}">
            <button *ngFor="let action of itemActions" class="btn m-1" (click)="onClickItemAction(action)"
                    [disabled]="action.disabled || selectedListItem?.readonly == true"
                    [ngClass]="(action.disabled  || selectedListItem?.readonly == true)? 'btn-outline-light text-muted' : action.styleClasses? action.styleClasses : 'btn-outline-secondary'">
              <i *ngIf="action.icon != null" [ngClass]="action.icon"></i>
              {{ action.key | translate }}
            </button>
          </div>

        </div>

      </div>
      <div class="card-body">
        <ng-content></ng-content>
      </div>
    </collapse-container>

  </div> <!-- END CONTENT -->
</div>
