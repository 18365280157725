export class RegisterUserDto {
   systemId: string;
   localization: string;
   username: string;
   firstName: string;
   lastName: string;
   mail: string;
   originClient: string = 'webclient';
   constructor(systemId: string,
               localization: string,
               username: string,
               firstName: string,
               lastName: string,
               mail: string) {
     this.systemId = systemId;
     this.localization = localization;
     this.username = username;
     this.firstName = firstName;
     this.lastName = lastName;
     this.mail = mail;
   }
}
