<div [ngClass]="showHeadline ? 'password-complexity-min-height-headline': 'password-complexity-min-height-no-headline'">
  <p *ngIf="showHeadline" class="text-muted">{{ "SYSTEM.SETTINGS.PASSWORD_COMPLEXITY" | translate }}</p>

  <div class="form-floating form-floating-custom">
    <div class="invalid-feedback">
    <span
      [ngClass]="{ 'is-valid': !passwordValidationLength }">{{ replaceLengthInMessage('AUTH.FIELD.NEW_PASSWORD.FORM.INVALID_LENGTH' | translate) }}</span><br>
      <span
        [ngClass]="{ 'is-valid': !passwordRegExNumbers }">{{ 'AUTH.FIELD.NEW_PASSWORD.FORM.INVALID_NUMBERS' | translate }}</span><br>
      <span *ngIf="passwordComplexityData?.id != 3"
            [ngClass]="{ 'is-valid': !passwordRegExAllCharacters }">{{ 'AUTH.FIELD.NEW_PASSWORD.FORM.INVALID_ALL_CHARS' | translate }}
        <br></span>
      <span *ngIf="passwordComplexityData?.id == 3"
            [ngClass]="{ 'is-valid': !passwordRegExBigCharacters }">{{ 'AUTH.FIELD.NEW_PASSWORD.FORM.INVALID_BIG_CHARS' | translate }}
        <br></span>
      <span *ngIf="passwordComplexityData?.id == 3"
            [ngClass]="{ 'is-valid': !passwordRegExSmallCharacters }">{{ 'AUTH.FIELD.NEW_PASSWORD.FORM.INVALID_SMALL_CHARS' | translate }}
        <br></span>
      <span *ngIf="passwordComplexityData?.id != 1"
            [ngClass]="{ 'is-valid': !passwordRegExExtraCharacters }">{{ 'AUTH.FIELD.NEW_PASSWORD.FORM.INVALID_EXTRA_CHARS' | translate }}
        <br></span>
    </div>
  </div>
</div>

