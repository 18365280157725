import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TimeModelIntervalFormComponent} from "./time-model-interval-form/time-model-interval-form.component";
import {TimeModelPointInTimeFormComponent} from "./time-model-point-in-time-form/time-model-point-in-time-form.component";
import {TimeModelDailyBasisFormComponent} from "./time-model-daily-basis-form/time-model-daily-basis-form.component";
import {TimeModelRepetitionFormComponent} from "./time-model-repetition-form/time-model-repetition-form.component";
import { TimeModelListOfDaysFormComponent } from './time-model-list-of-days-form/time-model-list-of-days-form.component';
import {IntervalSelectorComponent} from './interval-selector/interval-selector.component';
import {CoreModule} from "../../../core/core.module";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgbTimepicker} from "@ng-bootstrap/ng-bootstrap";
import {SharedModule} from "../../../shared/shared.module";



@NgModule({
  declarations: [
    TimeModelIntervalFormComponent,
    TimeModelPointInTimeFormComponent,
    TimeModelDailyBasisFormComponent,
    TimeModelRepetitionFormComponent,
    TimeModelListOfDaysFormComponent,
    IntervalSelectorComponent
  ],
  exports: [
    IntervalSelectorComponent
  ],
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule,
    ReactiveFormsModule,
    NgbTimepicker,
    FormsModule,
    SharedModule
  ]
})
export class TimeModelFormsModule { }
