import {Component, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'skeleton-component',
  host: {
    'class': 'skeleton'
  },
  template: '',
  styleUrls: ['./skeleton.component.scss'],
})
export class SkeletonComponent implements OnInit {
  width?: string;
  height?: string;
  className?: string[];

  constructor(private host: ElementRef<HTMLElement>) { }

  ngOnInit() {
    const host = this.host.nativeElement;
    this.className?.forEach(value => {
      host.classList.add(value);
    });

    host.style.setProperty('width', this.width ? this.width : '100%');
    host.style.setProperty('height', this.height ? this.height : '100%');
    host.style.setProperty('margin', '5px');
  }
}
