import {LockingDeviceParamsDto} from "./LockingDeviceParamsDto";
import {LockingDeviceGroupAssignmentDto} from "./LockingDeviceGroupAssignmentDto";
import {UuidName} from "../Basic";

export class LockingDeviceGroupDetailsDto extends UuidName {
  id: number;
  description: string | undefined;
  groupTypeId: number;
  params: LockingDeviceParamsDto | undefined;
  devices: LockingDeviceGroupAssignmentDto[];

  constructor(uuid: string, id: number, name: string, description: string | undefined, groupTypeId: number, params: LockingDeviceParamsDto | undefined, devices: LockingDeviceGroupAssignmentDto[]) {
    super(uuid, name);
    this.id = id;
    this.description = description;
    this.groupTypeId = groupTypeId;
    this.devices = devices;
    this.params = params;
  }

  static emptyLockingDeviceGroupDetailsDto() {
    return new LockingDeviceGroupDetailsDto('', 0, '', '', 0, undefined, []);
  }

}
