import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LockingDeviceGroupFormComponent } from './forms/locking-device-group-form/locking-device-group-form.component';
import {NgbNavModule} from "@ng-bootstrap/ng-bootstrap";
import {LockingDeviceDevicesModule} from "../locking-device-devices/locking-device-devices.module";
import {SharedModule} from "../../../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {CoreModule} from "../../../core/core.module";
import {ReactiveFormsModule} from "@angular/forms";
import { LockingDeviceGroupDevicesComponent } from './forms/locking-device-group-devices/locking-device-group-devices.component';
import {LockingDeviceGroupsComponent} from "./locking-device-groups.component";

@NgModule({
  declarations: [
    LockingDeviceGroupFormComponent,
    LockingDeviceGroupDevicesComponent,
    LockingDeviceGroupsComponent
  ],
  exports: [
    LockingDeviceGroupFormComponent,
    LockingDeviceGroupDevicesComponent,
    LockingDeviceGroupsComponent
  ],
  imports: [
    CommonModule,
    NgbNavModule,
    LockingDeviceDevicesModule,
    SharedModule,
    TranslateModule,
    CoreModule,
    ReactiveFormsModule
  ]
})
export class LockingDeviceGroupsModule { }
