import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginGuard } from "./core/guards/login.guard";
import { LayoutComponent } from "./layouts/layout.component";
import {ForcePasswordChangeGuard} from "./core/guards/force-password-change.guard";


const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
    canActivate: [LoginGuard, ForcePasswordChangeGuard]
  },
  {
    path: 'legal',
    loadChildren: () => import('./legal/legal.module').then(m => m.LegalModule)
  },
  {
    path: 'user',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    canDeactivate: [ForcePasswordChangeGuard]
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
