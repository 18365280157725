import {LookupMapping} from "./lookup";

export const DEVICE_GROUP: LookupMapping[] = [
  {
    "id": 1,
    "value": "params"
  },
  {
    "id": 2,
    "value": "access"
  }
];

export const LOGGING: LookupMapping[] = [
  {
    "id": 1,
    "value": "off"
  },
  {
    "id": 2,
    "value": "on"
  }
];

export const HANDLE_DIRECTION: LookupMapping[] = [
  {
    "id": 1,
    "value": "left"
  },
  {
    "id": 2,
    "value": "right"
  }
];
