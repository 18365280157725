import {Component, Input, OnInit, Output} from '@angular/core';
import {passwordComplexity, PasswordComplexity} from "../util/password-complexity";
import {FormGroup} from "@angular/forms";
import {ApiService} from "../../core/services/api-service/api.service";
import {environment} from "../../../environments/environment";
import {NodeManager} from "../../core/services/auth-service/support-services/NodeManager";
import {firstValueFrom} from "rxjs";


@Component({
  selector: 'app-password-complexity',
  templateUrl: './password-complexity.component.html',
  styleUrls: ['./password-complexity.component.scss']
})
export class PasswordComplexityComponent implements OnInit{

  passwordComplexityData: PasswordComplexity | undefined = undefined;

  @Input() changePasswordForm: FormGroup | undefined;
  @Input() formControlName: string | undefined;
  @Input() showHeadline: boolean | undefined;


  constructor(private apiService: ApiService) {
  }

  async ngOnInit() {
    let clientPasswordComplexity: number = 2;
    const node = NodeManager.getInstance().dataNodeConfiguration;
    if (node != undefined && node.isDataNode) {
      clientPasswordComplexity = (await firstValueFrom(await this.apiService.client.getClient()))?.passwordComplexity || 2;
    }
    this.passwordComplexityData = passwordComplexity.find((p) => p.id === clientPasswordComplexity);
  }

  async complexityRegexPattern(): Promise<string> {
    if (this.passwordComplexityData == undefined) {
      await this.ngOnInit();
    }
    if (!environment.passwordComplexityEnabled) {
      return '';
    }
    return this.passwordComplexityData?.regEx || '';
  }

  replaceLengthInMessage(str: string): string {
    return str.replace('{{length}}', '' + (this.passwordComplexityData!.minLength || 10));
  }

  get passwordValidationLength(): boolean {
    return this.changePasswordForm!.get(this.formControlName!)?.value.length < this.passwordComplexityData!.minLength;
  }

  get passwordRegExAllCharacters(): boolean {
    return !this.changePasswordForm!.get(this.formControlName!)?.value.match(this.passwordComplexityData?.regExPattern.regExAllCharacters);
  }

  get passwordRegExSmallCharacters(): boolean {
    return !this.changePasswordForm!.get(this.formControlName!)?.value.match(this.passwordComplexityData?.regExPattern.regExSmallCharacters);
  }

  get passwordRegExBigCharacters(): boolean {
    return !this.changePasswordForm!.get(this.formControlName!)?.value.match(this.passwordComplexityData?.regExPattern.regExBigCharacters);
  }

  get passwordRegExExtraCharacters(): boolean {
    return !this.changePasswordForm!.get(this.formControlName!)?.value.match(this.passwordComplexityData?.regExPattern.regExSpecialCharacters);
  }

  get passwordRegExNumbers(): boolean {
    return !this.changePasswordForm!.get(this.formControlName!)?.value.match(this.passwordComplexityData?.regExPattern.regExNumbersCharacters);
  }


}
