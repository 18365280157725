import {Component} from '@angular/core';
import {LanguageService} from './core/services/language-service/language.service';
import {RefreshTokenService} from "./core/services/refresh-token-service/refresh-token.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'adminAngular';
  defaultLang = 'de';

  // Reference languageService to be initialized
  constructor(
    language: LanguageService,
    private refreshTokenService: RefreshTokenService,
  ) {
    language.setDefaultLanguage(this.defaultLang);
    this.refreshTokenService.$tokenStartedHook.subscribe((value: any) => console.debug(`New Token at "${Date.now()}" with value "${value}"`));
  }
}
