import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {SystemComponent} from "./system/system.component";
import {UsersComponent} from "./users/users.component";
import {LockingDevicesComponent} from "./locking-devices/locking-devices.component";
import {LockingMediaComponent} from "./locking-media/locking-media.component";
import {TimeModelsComponent} from "./time-models/time-models.component";
import {AccessProfilesComponent} from "./access-profiles/access-profiles.component";
import {LogbookComponent} from "./logbook/logbook.component";
import {PendingChangesViewGuard} from "../core/guards/pending-changes-view.guard";
import {JobsComponent} from "./jobs/jobs.component";

const routes: Routes = [
  {
    path: 'start',
    redirectTo: 'system'
  },
  {
    path: 'system',
    component: SystemComponent,
    canDeactivate: [PendingChangesViewGuard]
  },
  {
    path: 'users',
    component: UsersComponent,
    canDeactivate: [PendingChangesViewGuard]
  },
  {
    path: 'locking-devices',
    component: LockingDevicesComponent,
    canDeactivate: [PendingChangesViewGuard]
  },
  {
    path: 'locking-devices/devices',
    component: LockingDevicesComponent,
    canDeactivate: [PendingChangesViewGuard]
  },
  {
    path: 'locking-devices/devices/:id',
    component: LockingDevicesComponent,
    canDeactivate: [PendingChangesViewGuard]
  },
  {
    path: 'locking-devices/params-groups',
    component: LockingDevicesComponent,
    canDeactivate: [PendingChangesViewGuard]
  },
  {
    path: 'locking-devices/params-groups/:id',
    component: LockingDevicesComponent,
    canDeactivate: [PendingChangesViewGuard]
  },
  {
    path: 'locking-media',
    component: LockingMediaComponent,
    canDeactivate: [PendingChangesViewGuard]
  },
  {
    path: 'time-models',
    component: TimeModelsComponent,
    canDeactivate: [PendingChangesViewGuard]
  },
  {
    path: 'access-profiles',
    component: AccessProfilesComponent,
    canDeactivate: [PendingChangesViewGuard]
  },
  {
    path: 'jobs',
    component: JobsComponent,
    canDeactivate: [PendingChangesViewGuard]
  },
  {
    path: 'logbook',
    component: LogbookComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'system'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PagesRoutingModule { }
