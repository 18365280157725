import {LookupMapping} from "./lookup";

export const TIME_MODEL_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "point_in_time"
  },
  {
    "id": 2,
    "value": "time_interval"
  },
  {
    "id": 3,
    "value": "daily_basis"
  },
  {
    "id": 4,
    "value": "repetition"
  },
  {
    "id": 5,
    "value": "list_of_days"
  }
];

export const TIME_MODEL_REPETITION_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "day"
  },
  {
    "id": 2,
    "value": "week"
  },
  {
    "id": 3,
    "value": "month"
  },
  {
    "id": 4,
    "value": "year"
  }
];

export const TIME_MODEL_MONTH_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "january"
  },
  {
    "id": 2,
    "value": "february"
  },
  {
    "id": 3,
    "value": "march"
  },
  {
    "id": 4,
    "value": "april"
  },
  {
    "id": 5,
    "value": "may"
  },
  {
    "id": 6,
    "value": "june"
  },
  {
    "id": 7,
    "value": "july"
  },
  {
    "id": 8,
    "value": "august"
  },
  {
    "id": 9,
    "value": "september"
  },
  {
    "id": 10,
    "value": "october"
  },
  {
    "id": 11,
    "value": "november"
  },
  {
    "id": 12,
    "value": "december"
  }
];

export const TIME_MODEL_WEEKDAY_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "monday"
  },
  {
    "id": 2,
    "value": "tuesday"
  },
  {
    "id": 3,
    "value": "wednesday"
  },
  {
    "id": 4,
    "value": "thursday"
  },
  {
    "id": 5,
    "value": "friday"
  },
  {
    "id": 6,
    "value": "saturday"
  },
  {
    "id": 7,
    "value": "sunday"
  }
];

export const TIME_MODEL_END_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "none"
  },
  {
    "id": 2,
    "value": "date"
  },
  {
    "id": 3,
    "value": "repetitions"
  }
];

export const TIME_MODEL_EXECUTION_REPETITION_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "day_x"
  },
  {
    "id": 2,
    "value": "first_weekday_x"
  },
  {
    "id": 3,
    "value": "second_weekday_x"
  },
  {
    "id": 4,
    "value": "third_weekday_x"
  },
  {
    "id": 5,
    "value": "fourth_weekday_x"
  },
  {
    "id": 6,
    "value": "last_weekday_x"
  },
  {
    "id": 7,
    "value": "last_day"
 // },
 // {
 //   "id": 8,
 //   "value": "non_recurring"
 // },
 // {
 //   "id": 9,
 //   "value": "recurring"
  }
];
