<app-item-list [items]="itemManager" [enableChangesWarning]="hasPendingChanges()" [showLoading]="isLoading"
               (onListAction)="onSelectEvent($event)" (onItemAction)="onEditEvent($event)" (onItemReturn)="onReturnEvent()"
               [listFilter]="searchFilter" [(itemSelected)]="itemSelected" [viewTypeModuleId]="'18'"
               [multiSelectActions]="multiSelectActions" (onMultiListAction)="onMultiDelete($event)">

  <ul ngbNav #nav="ngbNav" class="nav nav-tabs nav-tabs-custom nav-justified" [(activeId)]="selectedNavTab" [destroyOnHide]="false">
    <li ngbNavItem="lockingDeviceGroup">
      <a ngbNavLink>{{ "LOCKING_DEVICES.GROUPS.TAB.GENERAL" | translate }}</a>
      <ng-template ngbNavContent>
        <app-locking-device-group-form [lockingDeviceGroup]="selectedItem!" [accessReadonly]="accessReadonly"
                                       (onChanges)="handlePendingChanges()" >
        </app-locking-device-group-form>
      </ng-template>
    </li>
    <li ngbNavItem="lockingDevices">
      <a ngbNavLink>{{ "LOCKING_DEVICES.GROUPS.TAB.LOCKING_DEVICES" | translate }}</a>
      <ng-template ngbNavContent>
        <app-locking-device-group-devices [readonly]="accessReadonly || !canChangeLockingDeviceGroupAssignmentSettings"
                                          (onChanges)="handlePendingChanges()"
                                          [device]="selectedItem">
        </app-locking-device-group-devices>
      </ng-template>
    </li>
    <li ngbNavItem="groupParams" [hidden]="hideLockingDevicesTab">
      <a ngbNavLink>{{ "LOCKING_DEVICES.GROUPS.TAB.GROUP_PARAMS" | translate }}</a>
      <ng-template ngbNavContent>
        <app-locking-device-params-form [lockingGroupDeviceParamsAndTimeModels]="selectedParams"
                                        [accessReadonly]="accessReadonly"
                                        (onChanges)="handlePendingChanges()">
        </app-locking-device-params-form>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" id="navOutlet" class="pt-4"></div>

</app-item-list>
