export class TimeModelExecutionRepetitionDto {
  uuid: string;
  id: number;
  executionTypeId?: number;
  executionWeekdayId?: number;
  executionValue?: number;

  constructor(uuid: string, id: number, executionTypeId?: number, executionWeekdayId?: number, executionValue?: number) {
    this.uuid = uuid;
    this.id = id;
    this.executionTypeId = executionTypeId;
    this.executionWeekdayId = executionWeekdayId;
    this.executionValue = executionValue;
  }
}
