import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {SystemOverviewComponent} from "./overview/overview.component";
import {NgbHighlight, NgbProgressbar} from "@ng-bootstrap/ng-bootstrap";
import {SharedModule} from "../../shared/shared.module";
import {CoreModule} from "../../core/core.module";
import {TimezonePickerComponent} from "./overview/timezone-picker/timezone-picker.component";
import {SystemSettingsComponent} from "./settings/settings.component";

@NgModule({
  declarations: [
    SystemSettingsComponent,
    SystemOverviewComponent,
    TimezonePickerComponent,
  ],
  exports: [
    SystemSettingsComponent,
    SystemOverviewComponent,
  ],
    imports: [
        CommonModule,
        TranslateModule,
        ReactiveFormsModule,
        NgbProgressbar,
        NgbHighlight,
        SharedModule,
        CoreModule,
        FormsModule
    ]
})
export class SystemModule { }
