<form [formGroup]="groupForm">
  <div class="row row-cols-lg-6 g-4">

    <div class="col-lg-12">
      <div class="card shadow-none">
        <div class="card-body">

          <div class="form-floating form-floating-custom mb-3">
            <input [readonly]="accessReadonly" type="text" class="form-control" id="name" formControlName="name"
                   [ngClass]="{ 'is-invalid': f['name'] | hasErrors }" data-testid="user-group-name">
            <label for="name">{{ "USERS.GROUPS.FIELD.NAME.TEXT" | translate }}*</label>
            <div class="form-floating-icon">
              <i icon="name"></i>
            </div>
            <div *ngIf="f['name'] | hasErrors" class="invalid-feedback">
              <div *ngIf="f['name'] | hasErrors:'required'" data-testid="user-group-name-required">
                {{ "USERS.GROUPS.FIELD.NAME.REQUIRED" | translate }}</div>
              <div *ngIf="f['name'] | hasErrors:'maxlength'" data-testid="user-group-name-length">
                {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: nameTranslate : ('' + validatorMaxLength32) }}</div>
            </div>
          </div>

          <div class="form-floating form-floating-custom">
            <input [readonly]="accessReadonly" type="text" class="form-control" data-testid="user-group-desc"
                   id="description" formControlName="description">
            <label for="description">{{ "USERS.GROUPS.FIELD.DESCRIPTION.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="description"></i>
            </div>
            <div *ngIf="f['description'] | hasErrors" class="invalid-feedback">
              <div *ngIf="f['description'] | hasErrors:'maxlength'" data-testid="user-group-desc-length">
                {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: descriptionTranslate : ('' + validatorMaxLength64) }}</div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</form><!-- end form -->
