import {LookupMapping} from "./lookup";

export const MASTER_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "system_master"
  },
  {
    "id": 2,
    "value": "program_master"
  },
  {
    "id": 3,
    "value": "time_master"
  },
  {
    "id": 4,
    "value": "block_master"
  },
  {
    "id": 5,
    "value": "release_master"
  },
  {
    "id": 6,
    "value": "battery_master"
  },
  {
    "id": 7,
    "value": "datareset_master"
  },
  {
    "id": 8,
    "value": "service_master"
  },
  {
    "id": 9,
    "value": "emergency_master"
  },
  {
    "id": 10,
    "value": "gateway_master"
  },
  {
    "id": 11,
    "value": "pairing_master"
  },
  {
    "id": 12,
    "value": "test_master"
  }
];
