<app-pagetitle title="{{'MENU.JOBS' | translate}}" [itemSelected]="false" [disableSearch]="true"></app-pagetitle>

<div class="card card-body mb-2">

  <ul ngbNav #nav="ngbNav" class="nav nav-pills nav-justified bg-light" [(activeId)]="selectedNavTab"
      (navChange)="onNavigation($event)">
    <li ngbNavItem="changes">
      <a ngbNavLink><span class="black-align-text">{{ "JOBS.TAB.CHANGES" | translate }}</span></a>
      <ng-template ngbNavContent>
        <app-job-changes-form></app-job-changes-form>
      </ng-template>
    </li>
    <li ngbNavItem="ongoing">
      <a ngbNavLink><span class="black-align-text">{{ "JOBS.TAB.ONGOING" | translate }}</span></a>
      <ng-template ngbNavContent>
        <app-ongoing-jobs-form></app-ongoing-jobs-form>
      </ng-template>
    </li>
    <li ngbNavItem="finished">
      <a ngbNavLink><span class="black-align-text">{{ "JOBS.TAB.FINISHED" | translate }}</span></a>
      <ng-template ngbNavContent>
        <app-finished-jobs-form></app-finished-jobs-form>
      </ng-template>
    </li>
  </ul>

</div>

<div [ngbNavOutlet]="nav" id="navOutlet" class="pt-0"></div>
