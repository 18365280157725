import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class Logger {

  static log(msg: string): void {
    this.msg(msg, 'log');
  }

  static info(msg: string): void {
    this.msg(msg, 'info');
  }

  static warn(msg: string): void {
    this.msg(msg, 'warning');
  }

  static debug(msg: string): void {
    this.msg(msg, 'debug');
  }

  static error(msg: string): void {
    this.msg(msg, 'error');
  }

  private static msg(msg: string, level: string): void {
    if (environment.production) {
      return;
    }
    console.info(level);
    console.info(msg);
    console.info('');
  }
}
