export const LAYOUT_VERTICAL = 'vertical';

export const LAYOUT_HORIZONTAL = 'horizontal';

export const LAYOUT_WIDTH = 'fluid';

export const LAYOUT_POSITION = 'scrollable';

export const SIDEBAR_SIZE = 'default';

export const SIDEBAR_COLOR = 'brand';
