import {LockingDeviceGroupAssignmentDto} from "./LockingDeviceGroupAssignmentDto";

export class LockingDeviceGroupAssignableDto extends LockingDeviceGroupAssignmentDto {
  description: string | undefined;

  constructor(uuid: string, uid: number, shortName: string, description: string | undefined, deviceTypeId: number) {
    super(uuid, uid, shortName, deviceTypeId);
    this.description = description;
  }


}
