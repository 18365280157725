import {AfterViewInit, Component, HostListener, OnInit} from '@angular/core';
import {AppService} from "../../core/services/app-service/app.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

/**
 * Footer Component
 */
export class FooterComponent implements OnInit, AfterViewInit {

  // set the current year
  year: string = "2022";

  infoItems: {key: string, link: string}[] = [
    {key: 'LEGAL.TERMS.TITLE', link: '/legal/terms-of-use'},
    {key: 'LEGAL.PRIVACY.TITLE', link: '/legal/privacy-policy'},
    {key: 'LEGAL.OPEN_SOURCE.TITLE', link: '/legal/open-source-licences'},
    {key: 'LEGAL.IMPRINT.TITLE', link: '/legal/imprint'}
  ]

  scrollablePage = false;

  @HostListener('window:scroll')
  async onScroll() {
    await this.checkIfScrollable()
  }

  @HostListener('window:resize')
  async onResize() {
    await this.checkIfScrollable()
  }

  constructor(public appService: AppService) {
    const year = new Date().getFullYear()
    if(year > 2022) {
      this.year = `${year}`
    }
  }

  async ngOnInit() {
    if(visualViewport != null) {
      visualViewport.onresize = () => { this.checkIfScrollable() }
    }
    await this.checkIfScrollable()
  }

  async ngAfterViewInit() {
    await this.checkIfScrollable()
  }

  private async checkIfScrollable() {
    let scroll = window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop || 0;

    const max = document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    this.scrollablePage = (max > scroll)

  }
}
