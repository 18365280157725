import {Pipe, PipeTransform} from '@angular/core';
import {FormatterService} from "../services/formatter-service/formatter.service";

@Pipe({
  name: 'dateTimeRecurring'
})
export class DateTimeRecurringPipe implements PipeTransform {

  constructor(private formatter: FormatterService) {
  }

  transform(value: { year: number; month: number; day: number }, recurring: boolean): string {
    const day: string = `${value.day < 10 ? `0${value.day}` : value.day}`;
    const month: string = `${value.month < 10 ? `0${value.month}` : value.month}`;
    const year: string = `${recurring ? 'xxxx' : value.year}`;
    return `${day}.${month}.${year}`;
  }

}
