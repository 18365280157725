<!-- ========== Left Sidebar Start ========== -->
<div class="vertical-menu">

  <!-- LOGO -->
  <div class="navbar-brand-box">
    <div class="logo">
      <span class="logo-sm">
        <img src="assets/ces/logo/dark.svg" alt="" height="42">
      </span>
      <span class="logo-lg">
        <img src="assets/ces/logo/dark.svg" alt="" height="42">
        <span class="ps-3 text-center text-primary fw-semibold black-font">{{ "APP.TITLE" | translate }}</span>
      </span>
    </div>
  </div>

  <button type="button" class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn"
    (click)="toggleMobileMenu($event)">
    <i class="mdi mdi-menu"></i>
  </button>

  <!--- Sidemenu -->
  <div class="sidebar-menu-scroll" style="max-height: 100vh">
    <!--- Sidemenu -->
    <div id="sidebar-menu">
      <!-- Left Menu Start -->
      <ul class="metismenu list-unstyled" id="side-menu">

        <ng-container *ngFor="let item of menuItems">
          <li class="menu-title" *ngIf="item.isTitle">
            <span *ngIf="item.label == '$SYSTEM-ID'; else system">{{systemId || ("AUTH.NO_SYSTEM" | translate)}}</span>
            <ng-template #system>{{ item.label | translate }}</ng-template>
          </li>

          <li *ngIf="!item.isTitle && !item.isLayout">
            <a *ngIf="hasItems(item)" href="javascript:void(0);" class="is-parent"
              [ngClass]="{'has-arrow': !item.badge, 'has-dropdown': item.badge}">
              <i [class]="'me-1 nav-icon ces ' + item.icon"></i>
              <span class="menu-item"> {{ item.label | translate }}</span>
              <span class="badge rounded-pill float-end bg-{{item.badge.variant}}" *ngIf="item.badge">
                {{item.badge.text | translate}}
              </span>
            </a>

            <a [routerLink]="item.link" *ngIf="!hasItems(item)" class="side-nav-link-ref" routerLinkActive="active">
              <i [class]="'me-1 nav-icon ces ' + item.icon"></i>
              <span class="menu-item"> {{ item.label | translate }}</span>
              <span class="badge rounded-pill {{item.badge.variant}} float-end" *ngIf="item.badge">
                {{item.badge.text | translate}}
              </span>
            </a>

            <ul *ngIf="hasItems(item)" class="sub-menu" aria-expanded="false">
              <li *ngFor="let subitem of item.subItems">
                <a [routerLink]="subitem.link" *ngIf="!hasItems(subitem)" class="side-nav-link-ref"
                  [attr.data-parent]="subitem.parentId" routerLinkActive="active">
                  {{ subitem.label | translate}}
                </a>
                <a *ngIf="hasItems(subitem)" class="side-nav-link-a-ref has-arrow" href="javascript:void(0);"
                  [attr.data-parent]="subitem.parentId">
                  {{ subitem.label | translate}}
                </a>
                <ul *ngIf="hasItems(subitem)" class="sub-menu mm-collapse" aria-expanded="false">
                  <li *ngFor="let subSubitem of subitem.subItems">
                    <a [attr.data-parent]="subSubitem.parentId" [routerLink]="subSubitem.link" routerLinkActive="active"
                      class="side-nav-link-ref">
                      {{ subSubitem.label | translate }}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ng-container>
      </ul>
    </div>
    <!-- Sidebar -->
  </div>
  <!-- Sidebar -->

  <div *ngIf="!isProduction" class="position-absolute text-muted version" style="bottom: 5px; left: 5px; font-size: 12px;">
    <div class="version_meta-data">
      <ul>
        <li style="font-weight: bold; text-decoration: underline;">FE:</li>
        <li>Revision: {{versionData?.revision}}</li>
        <li>Version: {{versionData?.version}}</li>
        <li>Build Time: {{versionData?.buildTime}}</li>
      </ul>
      <ul>
        <li style="font-weight: bold; text-decoration: underline;">BE:</li>
        <li>Revision: {{beHealthCheckData?.commitHash || "-"}}</li>
        <li>Version: {{beHealthCheckData?.backendVersion || "-"}}</li>
        <li>Build Time: {{beHealthCheckData?.buildDate || "-"}}</li>
      </ul>
    </div>
    {{version}}
  </div>
</div>
<!-- Left Sidebar End -->
