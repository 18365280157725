import {ItemAction, ListAction} from "./Actions";
import {ItemListItem} from "../item-list/ItemListItem";

export class ListActionEvent<T> {

  itemActions: ItemAction[] = []

  constructor(public readonly action: ListAction, public readonly item:T, public readonly listItem: ItemListItem<T>) {
  }

  public addItemAction(...itemAction: ItemAction[]) {
    this.itemActions.push(...itemAction)
  }
}
