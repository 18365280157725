import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import {NgbDropdownModule, NgbPopoverModule, NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import { LanguageService } from '../core/services/language-service/language.service';
import { TranslateModule } from '@ngx-translate/core';

import { LayoutComponent } from './layout.component';
import { VerticalComponent } from './vertical/vertical.component';
import { TopbarComponent } from './topbar/topbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { FooterComponent } from './footer/footer.component';
import {ReactiveFormsModule} from "@angular/forms";
import {CoreModule} from "../core/core.module";
import {TopbarModule} from "./topbar/topbar.module";

@NgModule({
  declarations: [
    LayoutComponent,
    VerticalComponent,
    TopbarComponent,
    SidebarComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    NgbDropdownModule,
    TranslateModule,
    RouterModule,
    NgbToastModule,
    NgbPopoverModule,
    ReactiveFormsModule,
    CoreModule,
    TopbarModule
  ],
  exports: [
    FooterComponent
  ],
  providers: [
    LanguageService
  ]
})
export class LayoutsModule { }
