import {LookupMapping} from "./lookup";

export const MEDIA_IMPLEMENTATION: LookupMapping[] = [
  {
    "id": 1,
    "value": "electronic_key"
  },
  {
    "id": 2,
    "value": "transponder_clip"
  },
  {
    "id": 3,
    "value": "pendant_slim_line"
  },
  {
    "id": 4,
    "value": "pendant_premium"
  },
  {
    "id": 5,
    "value": "card"
  },
  {
    "id": 6,
    "value": "smartphone"
  }
];

export const MEDIA_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "emergency_key"
  },
  {
    "id": 2,
    "value": "mifare_desfire_ev2_8k"
  },
  {
    "id": 3,
    "value": "legic_advant"
  },
  {
    "id": 4,
    "value": "virtual_device"
  }
];

export const MEDIA_STATE: LookupMapping[] = [
  {
    "id": 1,
    "value": "handed_out"
  },
  {
    "id": 2,
    "value": "returned"
  },
  {
    "id": 3,
    "value": "broken"
  },
  {
    "id": 4,
    "value": "lost"
  },
  {
    "id": 5,
    "value": "invalid"
  },
  {
    "id": 6,
    "value": "locked"
  }
];
