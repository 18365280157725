import {Component, EventEmitter, forwardRef, Input, OnInit, Output, TemplateRef} from '@angular/core';
import {debounceTime, Subject} from "rxjs";
import {KeyValue} from "@angular/common";
import {ApiService} from "../../../../core/services/api-service/api.service";
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {TIMEZONES} from "../../../../shared/lookup/timezone.lookup";
import {LookupMapping} from "../../../../shared/lookup/lookup";
import {ChangeDetectorValue} from "../../../../shared/util/change-detector/ChangeDetectorValue";

@Component({
  selector: 'timezone-picker',
  templateUrl: './timezone-picker.component.html',
  styleUrls: ['./timezone-picker.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => TimezonePickerComponent)
  }]
})
export class TimezonePickerComponent implements OnInit {
  @Input() readonly = false;
  private _currentValue:ChangeDetectorValue = new ChangeDetectorValue(0);
  get currentValue(): ChangeDetectorValue {
    return this._currentValue;
  }
  @Input() set currentValue(value: ChangeDetectorValue) {
    this._currentValue = value;
  }
  @Output() currentValueChange: EventEmitter<number> = new EventEmitter<number>();

  timezones: KeyValue<number, string>[] = []
  filteredTimezones: KeyValue<number, string>[] = []

  searchValue = ""
  onChanges = new Subject<string>()

  get currentTranslationText(): string {
    const timezone: LookupMapping[] = TIMEZONES.filter(value => value.id == this.currentValue.value);
    if (timezone.length == 0) {
      return '';
    }
    return `TIMEZONE.${timezone[0].value.toUpperCase()}`;
  }

  constructor(private modalService: NgbModal) {
    this.onChanges.pipe(debounceTime(200)).subscribe((search) => {
      this.searchValue = search
      this.filteredTimezones = (search === '' ?
        this.timezones :
        this.timezones.filter(v => v.value.toLowerCase().indexOf(search.toLowerCase()) > -1))
    });
  }

  async ngOnInit() {
    this.timezones = Array.from(TIMEZONES, value => {
      return {key: value.id, value: value.value} as KeyValue<number, string>;
    });
  }

  openModal(selectModal: TemplateRef<any>) {

    if(this.readonly) {
      return;
    }

    this.filteredTimezones = this.timezones.slice()

    this.modalService.open(selectModal, {size: 'lg', scrollable: true, centered: true}).result.then(
      () => { },
      () => { });

    setTimeout(() => { // wait for document to add modal
      document.getElementById(`t-${this.currentValue}`)?.scrollIntoView()
    }, 50)
  }

  onSearch($event: Event) {
    this.onChanges.next((<HTMLInputElement>$event.target).value)
  }

  // Value Accessor

  setValue(value: number) {
    this.currentValue.value = value
  }

  get isBlackDesign(): boolean {
    const themeDesign = document.body.getAttribute("data-bs-theme");
    if (themeDesign != null) {
      return themeDesign.includes('black');
    }
    return false;
  }

}
