import { Component, OnInit } from '@angular/core';

import {ApiService} from "../../../../core/services/api-service/api.service";
import {ItemListItem, ItemListMapper} from "../../../../shared/item-list/ItemListItem";
import {ListActionEvent} from "../../../../shared/models/ListActionEvent";
import {ListAction} from "../../../../shared/models/Actions";
import {TranslateService} from "@ngx-translate/core";
import {ItemManager} from "../../../../shared/item-list/ItemManager";
import {JobChangesDto} from "../../../../shared/entities/jobs/JobChangesDto";
import {firstValueFrom} from "rxjs";
import {ToastService} from "../../../../shared/notification/toast/toast.service";

@Component({
  selector: 'app-job-changes-form',
  templateUrl: './job-changes-form.component.html',
  styleUrls: ['./job-changes-form.component.scss']
})
export class JobChangesFormComponent implements OnInit, ItemListMapper<JobChangesDto> {

  itemManager: ItemManager<JobChangesDto>
  selectedItem?: JobChangesDto;

  generateAction = new ListAction("JOBS.ACTION.GENERATE", () => {}, false, "mdi mdi-upload-outline")
  _isLoading: boolean = false;
  get isLoading(): boolean {
    return this._isLoading;
  }

  constructor(
    private apiService: ApiService,
    private notification: ToastService,
    private translate: TranslateService) {
    this.itemManager = new ItemManager<JobChangesDto>(this, notification);
  }

  async ngOnInit() {
    this._isLoading = true;
    this.itemManager.setItems(await firstValueFrom(await this.apiService.job.getAllChanges()));
    this._isLoading = false;
  }

  mapToItemList(item: JobChangesDto): ItemListItem<JobChangesDto> {
    const listItem =  new ItemListItem(item.lockingDeviceUuid, item.shortName, item)
      .addInfo(this.translate.instant("JOBS.ITEM_LIST.CHANGES_DETAILS", {uid: item.lockingDeviceUid}))
      .setImage("assets/ces/job/jobs_changes.svg")

    if(this.apiService.job.canGenerateJob()) {
      listItem.addAction(this.generateAction);
    }

    return listItem
  }

  async onListEvent(listActionEvent: ListActionEvent<JobChangesDto>) {
    if((await firstValueFrom(await this.apiService.job.generateJob(listActionEvent.item.lockingDeviceUuid))).ok) {
      // manual remove item to prevent delete message
      this.itemManager.items.delete(listActionEvent.item.lockingDeviceUuid)
      this.itemManager.emitChange()

      this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.DEFAULT');
    }
  }
}
