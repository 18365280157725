export class ModalContainerContent {
  title: string;
  content: string;
  submit: () => Promise<any>;
  submitTitle?: string;
  submitClass?: string;
  close: () => Promise<any>;
  closeTitle?: string;
  closeClass?: string;
  templateRef?: string;

  constructor(title: string,
              content: string,
              submit?: () => Promise<any>,
              close?: () => Promise<any>,
              submitTitle?: string,
              closeTitle?: string,
              submitClass?: string,
              closeClass?: string,
              templateRef?: string) {
    this.title = title;
    this.content = content;
    this.submit = (submit || this.ofTrue);
    this.submitTitle = submitTitle;
    this.submitClass = submitClass;
    this.close = (close || this.ofFalse);
    this.closeTitle = closeTitle;
    this.closeClass = closeClass;
    this.templateRef = templateRef;
  }

  private ofTrue(): Promise<boolean> {
    return Promise.resolve(true);
  }

  private ofFalse(): Promise<boolean> {
    return Promise.resolve(false);
  }

}
