import {Pipe, PipeTransform} from '@angular/core';
import {AsyncPipe} from '@angular/common';

@Pipe({
  name: 'nonNullAsync'
})
export class NonNullAsyncPipe extends AsyncPipe implements PipeTransform {

  override transform(value: any): any {
    return super.transform(value) || ""
  }

}
