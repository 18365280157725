import {AbstractControl, FormArray, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {FormatterService} from "../../core/services/formatter-service/formatter.service";

const secondsPerDay = 86400

export class FormValidator {

  public static readonly patternSystem = Validators.pattern(/EB\d{6}/)
  public static readonly patternPIN = Validators.pattern(/^\d{4}$/gm)
  public static readonly patternNotEmpty = Validators.pattern(/\S+/)

  public static get timeFormat(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const stringValue = String(control.value)
      const format = stringValue.match(/\d{2}:\d{2}:\d{2}/g)

      return format != null ? null : {'timeFormat': {value: stringValue}}
    }
  }

  public static get timeValue(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const v = FormatterService.fromPicker(control.value)

      return (v >= 0 && v < secondsPerDay) ? null : {'timeFormat': {value: v}}
    }
  }

  /** Validates values of fields timeStart and timeEnd of FormGroup
   *
   * @param distance time between start and end in seconds
   */
  public static timeStartEndValue(distance = 0): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      if (control.get('timeStart') == null || control.get('timeEnd') == null) {
        return null
      }

      const vs = FormatterService.fromPicker(control.get('timeStart')!.value)
      const ve = FormatterService.fromPicker(control.get('timeEnd')!.value)

      return ve > vs ? null : {'timeStartEndValue': {value: [vs, ve]}}
    }
  }

  public static greaterThan(field: string, orEquals = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const fieldToCompare = control.parent?.get(field)
      const isGreaterThan = orEquals ?
        Number(control.value) >= Number(fieldToCompare?.value) :
        Number(control.value) > Number(fieldToCompare?.value)

      return isGreaterThan ? null : {'greaterThan': {value: control.value}}
    }
  }

  public static lessThan(field: string, orEquals = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const fieldToCompare = control.parent?.get(field)
      const isLessThan = orEquals ?
        Number(fieldToCompare?.value) <= Number(control.value) :
        Number(fieldToCompare?.value) < Number(control.value)

      return isLessThan ? null : {'lessThan': {value: control.value}}
    }
  }

  public static equal(fieldA: string, fieldB: string, notEqual: boolean = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const controlA = control.get(fieldA)
      const controlB = control.get(fieldB)

      const isEqual = (controlA?.value == controlB?.value)

      if (notEqual) {
        return !isEqual ? null : {'not-equal': {valueA: controlA?.value, valueB: controlB?.value}}
      } else {
        return isEqual ? null : {'equal': {valueA: controlA?.value, valueB: controlB?.value}}
      }
    }
  }

  public static paramsTimeModel(control: AbstractControl): ValidationErrors | null {
    const openTimeModel = control.get('openTimeModel')
    const closeTimeModel = control.get('closeTimeModel')

    if (openTimeModel?.value == null || openTimeModel.value == '0' || openTimeModel.value == '') {
      return null
    }

    const equals = openTimeModel?.value == closeTimeModel?.value
    return equals ? {paramsTimeModel: {valueA: openTimeModel?.value, valueB: closeTimeModel?.value}} : null
  }

  public static disallowOnlyZeros(control: AbstractControl): ValidationErrors | null {

    const value = parseInt(control?.value)
    console.log(value)

    if (value == 0) {
      return {onlyZeros: true}
    }

    return null
  }

  // DATE
  public static dateGreaterThan(fieldGreater: string, fieldLess: string, orEquals = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const field = control.get(fieldGreater)
      const fieldToCompare = control.get(fieldLess)

      if (field?.value == null || fieldToCompare?.value == null || field.value == "" || fieldToCompare.value == "") {
        this.setError(field!, 'greaterThan', null)
        this.setError(fieldToCompare!, 'lessThan', null)
        return null
      }

      const isGreaterThan = orEquals ?
        FormatterService.fromPicker(field?.value) >= FormatterService.fromPicker(fieldToCompare?.value) :
        FormatterService.fromPicker(field?.value) > FormatterService.fromPicker(fieldToCompare?.value)


      this.setError(field, 'greaterThan', isGreaterThan ? null : true)
      this.setError(fieldToCompare, 'lessThan', isGreaterThan ? null : true)

      let error: { [p: string]: any } | null = null
      if (!isGreaterThan) {
        error = {}
        error[`dateGreater:${fieldGreater}`] = fieldLess
      }

      return error
    }
  }

  public static dateLessThan(field: string, orEquals = false): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const fieldToCompare = control.parent?.get(field)
      const isLessThan = orEquals ?
        new Date(control?.value).getTime() <= new Date(fieldToCompare?.value).getTime() :
        new Date(control?.value).getTime() < new Date(fieldToCompare?.value).getTime()

      return isLessThan ? null : {'lessThan': {value: control.value}}
    }
  }

  public static dateEqual(field: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      const fieldToCompare = control.parent?.get(field)
      const isEqual = new Date(control.value).getTime() == new Date(fieldToCompare?.value).getTime()

      return isEqual ? null : {'equal': {value: control.value}}
    }
  }

  // Multi-Forms
  static mustMatch(controlName: string, mustMatchControlName: string, mustNotMatch = false) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const mustMatchControl = formGroup.controls[mustMatchControlName];

      if (mustMatchControl == null || control == null) return;
      if (control.errors && (!control.errors['mustMatch'] || !control.errors['mustNotMatch'])) return;


      if (mustMatchControl.value !== control.value) {
        control.setErrors(mustNotMatch ? null : {mustMatch: true});
      } else {
        control.setErrors(mustNotMatch ? {mustNotMatch: true} : null);
      }
    };
  }

  public static notMatchingValues(compareValues: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null =>
      compareValues.filter(value => value.toLowerCase() == (control.value || '').toLowerCase()).length == 0 ? null : {duplicateName: true};
  }

  public static matchValue(matchTo: string): (arg0: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const parentControl = control.parent;
      if (parentControl == undefined || control.parent == undefined || control.parent.value == undefined) {
        return null;
      }
      // @ts-ignore
      return control.value === control.parent.controls[matchTo].value ? null : {mustMatch: false};
    };
  }

  public static notMatchingValue(matchTo: string): (arg0: AbstractControl) => ValidationErrors | null {
    return (control: AbstractControl): ValidationErrors | null => {
      const oldValue = this.matchValue(matchTo);
      if (oldValue == null) {
        return {mustNotMatch: true};
      }
      return null;
    }
  }

  public static intervalOverlapping(control: AbstractControl): ValidationErrors | null {

    if (!(control instanceof FormArray)) return null;

    const formArray = control as FormArray
    const startValues: number[] = []
    const endValues: number[] = []

    let isOverlapping = false
    for (let control of formArray.controls) {
      if (!(control instanceof FormGroup)) {
        continue;
      }

      const startValue = control.get('timeStart')?.value || 0
      const endValue = control.get('timeEnd')?.value || 0

      for (let i = 0; i < startValues.length; i++) {
        if (((startValues[i] <= startValue && endValues[i] >= startValue) ||
            (startValues[i] <= endValue && endValues[i] >= endValue)) ||
          ((startValue <= startValues[i] && endValue >= startValues[i]) ||
            (startValue <= endValues[i] && endValue >= endValues[i]))) {
          isOverlapping = true
          break;
        }
      }

      startValues.push(startValue)
      endValues.push(endValue)
    }

    return isOverlapping ? {intervalOverlapping: true} : null
  }

  public static duplicatesListOfDays(control: AbstractControl): ValidationErrors | null {

    if (!(control instanceof FormArray)) return null;

    const formArray = control as FormArray
    const dayMap = new Map<string, FormGroup[]>()
    const intersectionMap = new Map<string, FormGroup[]>()

    let hasDuplicates = false
    for (let control of formArray.controls) {
      if (!(control instanceof FormGroup)) continue;

      const recurring = control.get('recurring')?.value

      const recurringValue = FormatterService.translateDateRecurring(control.get('timestamp')?.value, true)
      const nonRecurringValue = FormatterService.translateDateRecurring(control.get('timestamp')?.value, false)

      const index = FormatterService.asDatePicker(recurring ? recurringValue : nonRecurringValue)
      const intersectionIndex = recurring ? undefined : FormatterService.asDatePicker(recurringValue)

      // check duplicates
      const hasIndex = recurring ?
        dayMap.has(index) || intersectionMap.has(index) :
        dayMap.has(index) || dayMap.has(intersectionIndex!)

      // set indices
      dayMap.has(index) ?
        dayMap.get(index)!.push(control) :
        dayMap.set(index, [control])

      if (intersectionIndex != null) {
        intersectionMap.has(intersectionIndex) ?
          intersectionMap.get(intersectionIndex)!.push(control) :
          intersectionMap.set(intersectionIndex, [control])
      }


      // duplicate handling

      if (!hasIndex) { // continue if there was no duplicate index
        control.setErrors(null)
        continue;
      }

      hasDuplicates = true

      dayMap.get(index)?.forEach(dayIndex => dayIndex.setErrors({duplicate: true}))
      if (intersectionIndex != null) {
        dayMap.get(intersectionIndex)?.forEach(dayIndex => dayIndex.setErrors({duplicate: true}))
      }

      if (recurring && intersectionMap.has(index)) {
        intersectionMap.get(index)?.forEach(
          intersection => intersection.setErrors({duplicate: true}))
      }
    }

    return hasDuplicates ? {duplicates: true} : null
  }

  // helper
  private static setError(control: AbstractControl, errorKey: string, errorValue: any) {
    if (control.errors && errorValue == null) {
      const errorKeys = Object.keys(control.errors)
      if (errorKeys.length == 1 && errorKeys.includes(errorKey)) {
        control.setErrors(null)
        return
      }
      delete control.errors[errorKey]
      return
    }

    if (errorValue == null) {
      return
    }

    if (control.errors == null) {
      let errors: ValidationErrors = {}
      errors[errorKey] = errorValue
      control.setErrors(errors)
      return
    }

    control.errors[errorKey] = errorValue

  }

  static officeTimeout(range: { min?: number, max?: number }) {
    return (control: AbstractControl): ValidationErrors | null => {

      const officeFunction = control.get('officeFunction')
      const officeTimeout = control.get('officeTimeout')

      if (officeTimeout == null) {
        return null
      }
      if (range == null || officeFunction?.value == null || officeFunction.value == false) {
        this.setError(officeTimeout, "min", undefined)
        this.setError(officeTimeout, "max", undefined)
        return null // no error
      }

      const value = FormatterService.fromPicker(officeTimeout.value)

      let error: any = null

      if (range.min != null && range.min > value) {
        error = {min: range.min, actual: value}
        this.setError(officeTimeout, "min", error)
        return {officeTimeout: error}
      }
      if (range.max != null && range.max < value) {
        error = {max: range.max, actual: value}
        this.setError(officeTimeout, "max", error)
        return {officeTimeout: error}
      }

      return error
    }
  }
}
