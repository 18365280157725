import {Injectable} from '@angular/core';
import {ClientService} from "./sub-services/client.service";
import {HealthService} from "./sub-services/health.service";
import {LockingMediaService} from "./sub-services/locking-media.service";
import {LogbookService} from "./sub-services/logbook.service";
import {LockingDeviceService} from "./sub-services/locking-device.service";
import {UserService} from "./sub-services/user.service";
import {AccessProfileService} from "./sub-services/access-profile.service";
import {TimeModelService} from "./sub-services/time-model.service";
import {JobService} from "./sub-services/job.service";
import {UserGroupService as UserGroupServiceDefault} from "./sub-services/user-group.service";
import {LockingDeviceGroupService as LockingDeviceGroupServiceDefault} from "./sub-services/locking-device-group.service";
import {Router} from "@angular/router";
import {FirmwareService} from "./sub-services/firmware.service";
import { HttpClient } from "@angular/common/http";
import {AuthenticationService} from "../auth-service/auth.service";
import {ToastService} from "../../../shared/notification/toast/toast.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private router: Router;

  private readonly _authService: AuthenticationService;
  private readonly _clientService: ClientService;
  private readonly _firmwareService: FirmwareService;
  private readonly _healthService: HealthService;
  private readonly _userService: UserService;
  private readonly _userGroupService: UserGroupServiceDefault;
  private readonly _lockingDeviceService: LockingDeviceService;
  private readonly _lockingDeviceGroupService: LockingDeviceGroupServiceDefault;
  private readonly _lockingMediaService: LockingMediaService;
  private readonly _timeModelService: TimeModelService;
  private readonly _accessProfileService: AccessProfileService;
  private readonly _jobService: JobService;
  private readonly _logbookService: LogbookService;

  constructor(router: Router, httpClient: HttpClient, notification: ToastService) {
    this.router = router;
    this._authService = new AuthenticationService(router, httpClient, notification);
    this._clientService = new ClientService(router, httpClient, notification);
    this._firmwareService = new FirmwareService(router, httpClient, notification);
    this._healthService = new HealthService(router, httpClient, notification);
    this._userService = new UserService(router, httpClient, notification);
    this._userGroupService = new UserGroupServiceDefault(router, httpClient, notification);
    this._lockingDeviceService = new LockingDeviceService(router, httpClient, notification);
    this._lockingDeviceGroupService = new LockingDeviceGroupServiceDefault(router, httpClient, notification);
    this._accessProfileService = new AccessProfileService(router, httpClient, notification);
    this._timeModelService = new TimeModelService(router, httpClient, notification);
    this._lockingMediaService = new LockingMediaService(router, httpClient, notification);
    this._jobService = new JobService(router, httpClient, notification);
    this._logbookService = new LogbookService(router, httpClient, notification);
  }

  async navigate(to: string) {
    await this.router.navigate([to]);
  }

  get auth() {
    return this._authService;
  }

  get client() {
    return this._clientService
  }

  get firmware() {
    return this._firmwareService
  }

  get health() {
    return this._healthService
  }

  get user() {
    return this._userService
  }

  get userGroup() {
    return this._userGroupService
  }

  get lockingDevice() {
    return this._lockingDeviceService
  }

  get lockingDeviceGroup() {
    return this._lockingDeviceGroupService
  }

  get lockingMedia() {
    return this._lockingMediaService
  }

  get timeModel() {
    return this._timeModelService
  }

  get accessProfile() {
    return this._accessProfileService
  }

  get job() {
    return this._jobService
  }

  get logbook() {
    return this._logbookService
  }

}
