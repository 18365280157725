<div class="card h-100 shadow-none">

  <div class="card-header d-flex justify-content-between py-2">
    <div class="d-flex align-items-center gap-1">
      <h6 class="my-2 align-self-center" style="color: #ffffff">{{ 'TIME_MODELS.INTERVAL.TITLE' | translate }}</h6>
    </div>
    <button *ngIf="!accessReadonly" class="btn" [disabled]="!addEnabled" (click)="addInterval()"
            [ngClass]="{'btn-outline-primary': addEnabled, 'text-muted btn-outline-light': !addEnabled}">
      <i icon="action-add"></i>
      {{ "BUTTON.ADD" | translate }}
    </button>
  </div>

  <div class="card-body pb-0 d-flex flex-column gap-1">
    <form *ngFor="let entry of _timeModelInterval; let i = index"
          [ngClass]="i == _timeModelInterval.length-1 ? '' : 'mb-3'"
          class="d-flex flex-column gap-1" id="interval-{{i}}" @fadeAnimation>
      <div style="display: flex; flex-direction: row">


        <date-time-picker
          titleText="TIME_MODELS.FIELD.TIME_START.TEXT"
          style="flex-grow: 1;  padding-right: 5px;"
          [readonly]="accessReadonly"
          [showChanges]="entry.timeModelUuid.length == 0 || entry.timeStart.hasChanges"
          [invalidTime]="isInInvalidFrameUnique(entry.unique) || isOverlapping(entry.timeStart, entry.timeEnd)"
          [(timeValue)]="entry.timeStart.value">
        </date-time-picker>

        <date-time-picker
          style="flex-grow: 1; padding-left: 5px; padding-right: 5px;"
          [readonly]="accessReadonly"
          [showChanges]="entry.timeModelUuid.length == 0 || entry.timeEnd.hasChanges"
          [invalidTime]="isInInvalidFrameUnique(entry.unique) || isOverlapping(entry.timeStart, entry.timeEnd)"
          [(timeValue)]="entry.timeEnd.value">
        </date-time-picker>

        <div *ngIf="isInInvalidFrameUnique(entry.unique) || isOverlapping(entry.timeStart, entry.timeEnd)"
             class="invalid-feedback d-flex flex-column mb-2 show-under-filed">
          <div *ngIf="isInInvalidFrameUnique(entry.unique)">{{ "TIME_MODELS.INTERVAL.OVERLAPPING" | translate }}</div>
          <div
            *ngIf="isOverlapping(entry.timeStart, entry.timeEnd)">{{ "TIME_MODELS.INTERVAL.TIME_START_END_VALUE" | translate }}
          </div>
          <div style="max-width: 110px; min-width: 110px;" *ngIf="isInInvalidFrameUnique(entry.unique)"></div>
        </div>

      <button *ngIf="!accessReadonly" class="btn text-nowrap" [ngClass]="{'btn-outline-danger': deleteEnabled, 'text-muted btn-outline-light': !deleteEnabled}"
              (click)="removeInterval(entry.unique)" type="button" [disabled]="!deleteEnabled"
              style="flex-grow: 1; max-width: 100px; margin: 5px">
        <i icon="action-delete"></i>
        {{ "BUTTON.REMOVE" | translate }}
      </button>
      </div>
    </form>
  </div>
</div>
