export class LogbookRequestDto {
  skip: number;
  top: number;
  searchQuery: string | undefined;
  filterBy: Map<String, String> | undefined;
  timestamp: number;

  constructor(timestamp: number,skip: number,  top: number, searchQuery: string, filterBy : Map<String, String> | undefined) {
    this.top = top;
    this.skip = skip;
    this.searchQuery = searchQuery;
    this.timestamp = timestamp;
    this.filterBy = filterBy;
  }

  static emptyLogbookRequestDto(): LogbookRequestDto {
    return new LogbookRequestDto(0, 0, 0, "", undefined);
  }

}
