import {LookupMapping} from "./lookup";

export const ROLE: LookupMapping[] = [
  {
    "id": 1,
    "value": "ces_pti"
  },
  {
    "id": 2,
    "value": "ces_owner"
  },
  {
    "id": 3,
    "value": "ces_admin"
  },
  {
    "id": 4,
    "value": "ces_user"
  }
];
