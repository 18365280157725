import { Injectable } from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from "@angular/router";
import {Location} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  history: string[] = []
  root: string = '/'

  constructor(
    private router: Router,
    private location: Location) {

    router.events.subscribe(event => {
      if(event instanceof NavigationStart && !this.history.includes(router.url)) {
        this.history.push(router.url)
      }
      if(event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects)
      }
    })
  }

  navigateBack(clearHistory = false) {
    this.history.pop()
    if(this.history.length > 0) {
      this.location.back()

      if(clearHistory) {
        this.history = []
      }
    } else {
      this.router.navigateByUrl(this.root)
    }
  }

  navigateBackTo(url: string) {
    if(this.history.includes(url)) {
      this.history = this.history.slice(0, this.history.indexOf(url))
    }

    this.router.navigateByUrl(url)
  }

  setRoot(root: string) {
    this.root = root
  }
}
