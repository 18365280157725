import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse} from '@angular/common/http';
import {catchError, Observable, of} from 'rxjs';
import {inject, Injectable} from '@angular/core';
import {map} from "rxjs/operators";
import {ToastService} from "../../shared/notification/toast/toast.service";
import {set$notifyIOBStatusVersionError, set$notifyRelogin} from "../../shared/util/FallbackNotifiers";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  toast = inject(ToastService);

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError(request => {
        if (req.url?.includes('CESAdmin') && req.url?.includes('/image') && [404].includes(request.status)) {
          return of(new HttpResponse(request));
        }
        if ((req.url?.includes('CESAdmin') || req.url?.includes('CESMaster') && !req.url?.endsWith('/image'))) {
          const errorText: string | undefined = this.errorText(request);
          this.toast.showError(errorText);
        }
        if (req.url?.includes('CESAdmin') && [401, 403].includes(request.status)) {
          set$notifyRelogin(true);
        }
        if (req.url?.includes('status') && [400].includes(request.status)) {
          if (request.error && typeof request.error =='string' && request.error.includes('version is not valid')) {
            set$notifyIOBStatusVersionError(true);
          }
        }
        return of(new HttpResponse(request));
      }),
      map((event: HttpEvent<any>) => {
        return event;
      })
    )
  }

  private errorText(request: any): string {
    let message: string | undefined;
    if (request && request.error) {
      if (request.error.raw && !request.error.raw.includes(' ')) {
        message = request.error.raw;
      } else if (request.error.message && !request.error.message.includes(' ')) {
        message = request.error.message;
      } else if (request.error.status && !request.error.status.includes(' ')) {
        message = request.error.status;
      }
    }
    return message ? `NOTIFICATION.TOAST.CES_ERROR.${message}` : 'NOTIFICATION.TOAST.ERROR.DEFAULT';
  }

  private messageSwitch(msg: string): string {
    switch (msg) {
      case 'cesErrForbidden':
        return msg
    }
    return msg;
  }

}
