<form>
  <div class="form-floating form-floating-custom mb-3">
    <date-time-picker
      style="flex-grow: 1"
      [readonly]="accessReadonly"
      [(dateValue)]="startDate"
      [invalidDate]="invalid"
      [showChanges]="startDateChanged">
    </date-time-picker>
  </div>

  <div class="form-floating form-floating-custom mb-3">
    <date-time-picker
      style="flex-grow: 1"
      [readonly]="accessReadonly"
      titleText="TIME_MODELS.FIELD.END_VALUE_DATE.TEXT"
      [(dateValue)]="endDate"
      [invalidDate]="invalid"
      [showChanges]="endDateChanged">
    </date-time-picker>
  </div>

  <div *ngIf="invalid" class="invalid-feedback d-flex">
    <div *ngIf="invalid">{{ "TIME_MODELS.FIELD.END_VALUE_DATE.MINIMUM_EQUAL" | translate }}</div>
  </div>
</form>
