import {EmailStatus} from "../../../core/enums/emailStatus.enum";

export class UserMailVerificationDto {
  emailStatus: EmailStatus

  constructor(emailStatus: EmailStatus) {
    this.emailStatus = emailStatus;
  }

}
