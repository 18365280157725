import {LogbookDetailDto} from "./LogbookDetailDto";

export class LogbookAddDto {
  eventTypeId: number;
  eventId: number;
  timestamp: number;
  details: LogbookDetailDto[];

  constructor(eventTypeId: number, eventId: number, timestamp: number, details: LogbookDetailDto[]) {
    this.eventTypeId = eventTypeId;
    this.eventId = eventId;
    this.timestamp = timestamp;
    this.details = details;
  }
}
