import {LookupTranslation} from "./lookup";

const prefixModule = 'LOGBOOK.MODULE.';

export const LOGBOOK_TRANSLATION_KEY: LookupTranslation[] = [
  {
    name: "module_locking_device",
    translationKey: `${prefixModule}LOCKING_DEVICE`,
  }, {
    name: "module_locking_media",
    translationKey: `${prefixModule}LOCKING_MEDIA`,
  }, {
    name: "module_locking_media_assignable",
    translationKey: `${prefixModule}LOCKING_MEDIA_ASSIGNABLE`,
  }, {
    name: "module_access_profile",
    translationKey: `${prefixModule}ACCESS_PROFILE`,
  }, {
    name: "module_access_profile_time_models",
    translationKey: `${prefixModule}ACCESS_PROFILE_TIME_MODELS`,
  }, {
    name: "module_access_profile_locking_devices",
    translationKey: `${prefixModule}ACCESS_PROFILE_LOCKING_DEVICES`,
  }, {
    name: "module_user_access_profiles",
    translationKey: `${prefixModule}USER_ACCESS_PROFILES`,
  }, {
    name: "module_user_locking_media",
    translationKey: `${prefixModule}USER_LOCKING_MEDIA`,
  }, {
    name: "module_time_model",
    translationKey: `${prefixModule}TIME_MODEL`,
  }, {
    name: "module_job_changes",
    translationKey: `${prefixModule}JOB_CHANGES`,
  }, {
    name: "module_ongoing_jobs",
    translationKey: `${prefixModule}ONGOING_JOBS`,
  }, {
    name: "module_finished_jobs",
    translationKey: `${prefixModule}FINISHED_JOBS`,
  }, {
    name: "module_programming",
    translationKey: `${prefixModule}PROGRAMMING`,
  }, {
    name: "module_group_locking_device",
    translationKey: `${prefixModule}GROUP_LOCKING_DEVICE`,
  }, {
    name: "module_group_locking_device_assignments",
    translationKey: `${prefixModule}GROUP_LOCKING_DEVICE_ASSIGNMENTS`,
  }, {
    name: "module_group_user",
    translationKey: `${prefixModule}GROUP_USER`,
  }, {
    name: "module_group_user_assignments",
    translationKey: `${prefixModule}GROUP_USER_ASSIGNMENTS`,
  }, {
    name: "module_group_user_access_profile",
    translationKey: `${prefixModule}GROUP_USER_ACCESS_PROFILE`,
  }, {
    name: "module_auth",
    translationKey: `${prefixModule}AUTH`,
  }, {
    name: "module_client",
    translationKey: `${prefixModule}CLIENT`,
  }, {
    name: "module_license",
    translationKey: `${prefixModule}LICENSE`,
  }, {
    name: "module_logbook",
    translationKey: `${prefixModule}LOGBOOK`
  }, {
    name: "module_user",
    translationKey: `${prefixModule}USER`,
  }, {
    name: "data_unknown",
    translationKey: 'LOGBOOK.DETAILS.DATA_UNKNOWN',
  }, {
    name: "all_data",
    translationKey: 'LOGBOOK.DETAILS.ALL_DATA',
  }
]
