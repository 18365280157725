export abstract class User {
  uuid: string;
  username: string;
  mail: string;
  roleId: number;
  blocked: boolean;
  changePassword: boolean;
  firstName: string | undefined;
  lastName: string | undefined;
  streetHouseNumber: string | undefined;
  zipCode: string | undefined;
  city: string | undefined;
  countryId: number | undefined;
  dateOfBirth: number | undefined;
  personnelNo: string | undefined;
  department: string | undefined;
  activity: string | undefined;
  area: string | undefined;
  costCentre: string | undefined;
  phoneOffice: string | undefined;
  phonePrivate: string | undefined;
  phoneMobile: string | undefined;
  imageAvailable: boolean | undefined;
  imageHash: string | undefined;
  originClient: string | undefined;

  protected constructor(uuid: string, username: string, mail: string, roleId: number, blocked: boolean, changePassword: boolean) {
    this.uuid = uuid;
    this.username = username;
    this.mail = mail;
    this.roleId = roleId;
    this.blocked = blocked;
    this.changePassword = changePassword;
  }
}
