export abstract class UuidName {
  uuid: string;
  name: string;

  protected constructor(uuid: string, name: string) {
    this.uuid = uuid;
    this.name = name;
  }
}
export abstract class UuidShortName {
  uuid: string;
  shortName: string;

  protected constructor(uuid: string, name: string) {
    this.uuid = uuid;
    this.shortName = name;
  }
}
