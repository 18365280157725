import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {NgbCalendar, NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {DateTimeConverter} from "./date-time-converter";
import {DateTimeWeekdayConverter} from "./date-time-weekday-converter";
import {environment} from "../../../environments/environment";
import {TranslateService} from "@ngx-translate/core";
import {FormatterService} from "../../core/services/formatter-service/formatter.service";

@Component({
  selector: 'date-time-picker',
  templateUrl: './date-time-picker.component.html',
  styleUrls: ['./date-time-picker.component.scss'],
  providers: [
    {provide: NgbDateParserFormatter, useClass: DateTimeConverter},
    //{provide: NgbDatepickerI18n, useValue: DateTimeWeekdayConverter}
  ],
})
export class DateTimePickerComponent {
  ngbCalender = inject(NgbCalendar);
  dateId: string = `${Date.now()}`

  private _titleText?: string;
  @Input() set titleText(title: string) {
    this._titleText = title;
  }
  @Input() readonly: boolean = false;
  @Input() showChanges: boolean = false;
  @Input() clearable: boolean = false;
  @Input() styleClasses: string[] = [];

  // Time Picker (Hour : Minute)
  @Input() time: boolean = false;
  _timeValue: { hour: number, minute: number } = {hour: 0, minute: 0};
  @Input() set timeValue(newTimeValue: { hour: number, minute: number } | {
    hour: number,
    minute: number,
    seconds: number
  } | number) {
    if (typeof newTimeValue === 'number') {
      let hours: number = 0;
      if (newTimeValue >= 3600) {
        hours = newTimeValue / 3600;
        newTimeValue = newTimeValue - (hours * 3600);
      }
      this._timeValue = {hour: hours, minute: (newTimeValue / 60)};
    } else {
      //@ts-ignore
      if (newTimeValue.second != undefined) {
        this.timeValue = {hour: newTimeValue.hour, minute: newTimeValue.minute};
      } else {
        this._timeValue = newTimeValue as { hour: number, minute: number };
      }
    }
    this.time = true;
    this.timeValueChange.emit(this._timeValue);
  }

  get timeValue(): { hour: number, minute: number } {
    return this._timeValue;
  }

  @Output() timeValueChange: EventEmitter<{ hour: number, minute: number }> = new EventEmitter<{
    hour: number,
    minute: number
  }>();
  @Input() invalidTime: boolean = false;

  // Date Picker (Year, Month, Day)
  @Input() date: boolean = false;
  _dateValue: NgbDateStruct | null = this.ngbCalender.getToday();
  @Input() set dateValue(dateValue: { year: number, month: number, day: number } | string | null | number) {
    switch (typeof dateValue) {
      case 'string':
        this.dateValue = new DateTimeConverter().parse(dateValue)!;
        break;
      case 'number':
        this.dateValue = new FormatterService().formatToNgbDatePicker(dateValue);
        break;
      default:
        this._dateValue = dateValue;
        this.date = true;
        this.dateValueChange.emit(this._dateValue);
        break;
    }
  }

  get dateValue(): { year: number, month: number, day: number } | null {
    return this._dateValue;
  }

  @Output() dateValueChange: EventEmitter<{ year: number, month: number, day: number } | null> = new EventEmitter<{
    year: number,
    month: number,
    day: number
  } | null>();
  @Input() invalidDate: boolean = false;

  toggle(d: any) {
    if (!this.readonly) {
      d.toggle();
    }
  }

  get titleText(): string {
    if (this._titleText == undefined) {
      if (this.time) {
        return 'TIME_MODELS.FIELD.TIME_END.TEXT';
      }
      return 'TIME_MODELS.FIELD.START_DATE.TEXT';
    }
    return this._titleText;
  }

  constructor() {
  }

  protected readonly environment = environment;
}
