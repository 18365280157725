import {AccessProfile} from "./AccessProfile";

export class AccessProfileDto extends AccessProfile {
  accessProfileNumber: number;
  timeModelCount: number | undefined;
  lockingDeviceCount: number | undefined;
  groupLockingDeviceCount: number | undefined;

  constructor(uuid: string,
              name: string,
              description: string | undefined,
              accessTypeId: number,
              timeModelCount: number | undefined,
              lockingDeviceCount: number | undefined,
              groupLockingDeviceCount: number | undefined,
              officeFunction: boolean,
              pin: boolean,
              accessProfileNumber: number) {
    super(uuid, name, description, accessTypeId, officeFunction, pin);
    this.accessProfileNumber = accessProfileNumber;
    this.timeModelCount = timeModelCount;
    this.lockingDeviceCount = lockingDeviceCount;
    this.groupLockingDeviceCount = groupLockingDeviceCount;
  }

  static emptyAccessProfileDto() {
    return new AccessProfileDto('-1', '', '', 1, 0, 0, 0, false, false, 0);
  }
}
