// this file is automatically generated by git.version.ts script
export const versions = {
      beta: undefined,
      branch: 'feature/user-without-mail',
      buildTime: '2024-11-26T16:52:19.332Z',
      package: '@ces/cesentry-web-client',
      revision: '0b7049d4a',
      tag: 'v1.0.3',
      version: '1.2.0',
     };
