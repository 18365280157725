import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserUsersComponent} from './user-users.component';
import {UserFormComponent} from "./user-forms/user-form/user-form.component";
import {UserAccessProfileFormComponent} from "./user-forms/user-access-profile-form/user-access-profile-form.component";
import {UserLockingMediaFormComponent} from "./user-forms/user-locking-media-form/user-locking-media-form.component";
import {NgbNavModule, NgbTooltip} from "@ng-bootstrap/ng-bootstrap";
import {SharedModule} from "../../../shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {CoreModule} from "../../../core/core.module";
import {ImageCropperComponent} from "ngx-image-cropper";
import {HammerModule} from "@angular/platform-browser";


@NgModule({
  declarations: [
    UserUsersComponent,
    UserFormComponent,
    UserAccessProfileFormComponent,
    UserLockingMediaFormComponent
  ],
  exports: [
    UserUsersComponent,
    UserAccessProfileFormComponent
  ],
    imports: [
        CommonModule,
        NgbNavModule,
        SharedModule,
        TranslateModule,
        ReactiveFormsModule,
        CoreModule,
        HammerModule,
        ImageCropperComponent,
        FormsModule,
        NgbTooltip
    ]
})
export class UserUsersModule {
}
