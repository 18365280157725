export interface PasswordComplexity {
  id: number;
  label: string;
  regEx: string;
  regExPattern: {
    regExAllCharacters: string;
    regExSmallCharacters: string;
    regExBigCharacters: string;
    regExNumbersCharacters: string;
    regExSpecialCharacters: string;
  };
  minLength: number;
}

export const passwordComplexity: PasswordComplexity[] = [
  {
    id: 1,
    label: 'LIGHT',
    regEx: '^(?=.*[a-zA-Z])(?=.*[0-9]).{8,}$',
    regExPattern: {
      regExAllCharacters: '^(?=.*[a-zA-Z])',
      regExSmallCharacters: '^',
      regExBigCharacters: '^',
      regExNumbersCharacters: '^(?=.*[0-9])',
      regExSpecialCharacters: '^',
    },
    minLength: 8,
  },
  {
    id: 2,
    label: 'MIDDLE',
    regEx: '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{8,}$',
    regExPattern: {
      regExAllCharacters: '^(?=.*[a-zA-Z])',
      regExSmallCharacters: '^',
      regExBigCharacters: '^',
      regExNumbersCharacters: '^(?=.*[0-9])',
      regExSpecialCharacters: '(?=.*[^a-zA-Z0-9])',
    },
    minLength: 8,
  },
  {
    id: 3,
    label: 'STRONG',
    regEx: '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9]).{10,}$',
    regExPattern: {
      regExAllCharacters: '^',
      regExSmallCharacters: '^(?=.*[a-z])',
      regExBigCharacters: '^(?=.*[A-Z])',
      regExNumbersCharacters: '^(?=.*[0-9])',
      regExSpecialCharacters: '(?=.*[^a-zA-Z0-9])',
    },
    minLength: 10
  },
];
