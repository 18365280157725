import {AfterViewInit, Component, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';

import {PendingChangesBlocker} from "../../core/guards/pending-changes-view.guard";
import {NgbModal, NgbModalRef, NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {LockingDeviceDevicesComponent} from "./locking-device-devices/locking-device-devices.component";
import {debounceTime, Subject} from "rxjs";
import {SubmitUtils} from "../../shared/util/SubmitUtils";
import {ApiService} from "../../core/services/api-service/api.service";
import {LockingDeviceGroupsComponent} from "./locking-device-groups/locking-device-groups.component";
import {ActivatedRoute} from "@angular/router";
import {PagetitleComponent} from "../../shared/pagetitle/pagetitle.component";
import {FormBuilder} from "@angular/forms";
import {
  LockingDeviceCommissioningComponent
} from "./modals/locking-device-commissioning/locking-device-commissioning.component";
import {IoBridgeStatus} from "../../core/enums/ioBridgeStatus";
import {environment} from "../../../environments/environment";
import {ModalCloseCause} from "../../core/enums/modalCloseCause";
import {CommissionManager} from "../../core/services/auth-service/support-services/CommissionManager";
import {SessionManager} from "../../core/services/auth-service/support-services/SessionManager";
import {LockingDeviceDto} from "../../shared/entities/locking-device/LockingDeviceDto";
import {DEVICE_GROUP} from "../../shared/lookup/locking-device.lookup";
import {ModalService} from "../../shared/notification/modal/modal.service";

enum GROUP_TYPES {
  PARAMS = 1,
  ACCESS,
}

enum TABS {
  DEVICES = 'devices',
  GROUPS = 'groups'
}


@Component({
  selector: 'app-locking-devices',
  templateUrl: './locking-devices.component.html',
  styleUrls: ['./locking-devices.component.scss']
})
export class LockingDevicesComponent implements OnInit, AfterViewInit, PendingChangesBlocker {
  private commissionManager: CommissionManager;

  @ViewChildren(LockingDeviceDevicesComponent) queryDevices!: QueryList<LockingDeviceDevicesComponent>
  @ViewChildren(LockingDeviceGroupsComponent) queryGroups!: QueryList<LockingDeviceGroupsComponent>
  @ViewChild('pagetitle') private pagetitle: PagetitleComponent;

  lockingDeviceDevicesComponent!: LockingDeviceDevicesComponent
  lockingDeviceGroupsComponent!: LockingDeviceGroupsComponent

  selectedNavTab: TABS.DEVICES | TABS.GROUPS = TABS.DEVICES

  canAddGroup = false
  groups: boolean | undefined = undefined
  id: string | null = null
  loaded = false;
  isParamsGroupAllowed: boolean = false

  licenseTypeId: number = 1;
  licenseIsValidForBusiness: boolean = true;

  // event triggered
  onUpdate: Subject<void>
  minimizePagetitle = false
  typeaheadIndex = new Set<string>()

  get searchEntries() {
    return [...this.typeaheadIndex.values()]
  }

  // search query
  searchQuery: string = ""
  canAddOrRemoveDevices = false

  // IOBridge add
  ioBridgeConnected: boolean = false;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private alertService: ModalService
  ) {
    this.pagetitle = new PagetitleComponent(new FormBuilder)
    this.onUpdate = new Subject()
    this.onUpdate.pipe(debounceTime(50)).subscribe(() => this.onCheckChanges())
    this.commissionManager = CommissionManager.getInstance();
    this.isParamsGroupAllowed = SessionManager.getInstance().getSystemData()?.lockingDeviceParameterGroups == true;
    SessionManager.getInstance().systemDataSubject.pipe(debounceTime(500)).subscribe({
      next: data => this.isParamsGroupAllowed = data ? data.lockingDeviceParameterGroups == true : false
    });
  }

  async ngOnInit() {
    const sessionManager: SessionManager = SessionManager.getInstance()
    this.canAddOrRemoveDevices = this.selectedNavTab == TABS.DEVICES &&
      !sessionManager.isLicenseExpired && sessionManager.isLicenseBusiness && sessionManager.isAdminOrHigher;
    this.licenseIsValidForBusiness = !sessionManager.isLicenseExpired && sessionManager.isLicenseBusiness;
    this.licenseTypeId = sessionManager.isLicenseBusiness ? 2 : 1;

    this.id = this.route.snapshot.paramMap.get('id');
    this.groups = this.route?.snapshot?.routeConfig?.path?.includes('params-groups');
    this.loaded = true;
    this.ioBridgeConnected = this.commissionManager.bindingProcess.ioBridgeStatus == IoBridgeStatus.CONNECTED;
    this.commissionManager.bindingProcessObservable.pipe(debounceTime(500)).subscribe({
      next: data => {
        this.ioBridgeConnected = data.ioBridgeStatus == IoBridgeStatus.CONNECTED;
      },
      error: () => {
        this.ioBridgeConnected = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.lockingDeviceDevicesComponent = this.queryDevices.first
    this.queryDevices.changes.subscribe(value => {
      this.lockingDeviceDevicesComponent = value.first
    })

    this.lockingDeviceGroupsComponent = this.queryGroups.first
    this.queryGroups.changes.subscribe(value => {
      this.lockingDeviceGroupsComponent = value.first
    });
  }

  onCheckChanges() {
    if (this.selectedNavTab == TABS.DEVICES) {
      this.typeaheadIndex = this.lockingDeviceDevicesComponent?.typeaheadIndex || new Set<string>()
    }
    if (this.selectedNavTab == TABS.GROUPS) {
      this.typeaheadIndex = this.lockingDeviceGroupsComponent?.typeaheadIndex || new Set<string>()
    }

    this.minimizePagetitle =
      this.lockingDeviceDevicesComponent?.itemSelected == true ||
      this.lockingDeviceGroupsComponent?.itemSelected == true
  }

  hasPendingChanges(): boolean {
    return (this.lockingDeviceDevicesComponent?.hasPendingChanges() ||
      this.lockingDeviceGroupsComponent?.hasPendingChanges())
  }

  onSearch(search: string) {
    this.searchQuery = search
  }

  onSelect(selected: boolean) {
    this.minimizePagetitle = selected
  }

  async onNavigation(event: NgbNavChangeEvent) {
    this.resetSearch();
    const currentNav = event.nextId // contains selected element
    event.preventDefault()
    this.navigate(currentNav)
  }

  async navigate(currentNav: TABS.DEVICES | TABS.GROUPS) {
    let result =
      this.lockingDeviceDevicesComponent?.hasPendingChanges() ||
      this.lockingDeviceGroupsComponent?.hasPendingChanges()

    if (result) {
      result = await SubmitUtils.warnPendingChanges(this.alertService);
    }

    if (!result) { // allow switch
      this.selectedNavTab = currentNav
    }

    this.canAddOrRemoveDevices = this.selectedNavTab == TABS.DEVICES &&
      !SessionManager.getInstance().isLicenseExpired && SessionManager.getInstance().isLicenseBusiness &&
      SessionManager.getInstance().isAdminOrHigher;
    this.canAddGroup = this.selectedNavTab == 'groups' && this.apiService.lockingDeviceGroup.canAdd;

    // check for changes and wait for editor state change
    this.onUpdate.next()
    setTimeout(() => {
      this.onUpdate.next()
    }, 400)
  }

  onAddGroup(groupTypeId: number) {
    this.lockingDeviceGroupsComponent.addNewGroup(groupTypeId);
  }

  onAddDevice() {
    this.commissionManager.initBind();
    const commissioningModal: NgbModalRef = this.modalService.open(LockingDeviceCommissioningComponent);
    commissioningModal.closed.pipe(debounceTime(500)).subscribe(async closeReason => {
      if (typeof closeReason === "object" && closeReason.uuid != undefined) {
        this.lockingDeviceDevicesComponent.itemManager.addItems(closeReason as LockingDeviceDto);
        this.lockingDeviceDevicesComponent.itemManager.forEach((value => {
          this.typeaheadIndex.add(value.name)
        }));

      } else if (typeof closeReason === "number" && closeReason == ModalCloseCause.SUCCESS) {
        // Uuid was not returned. Therefore, a fallback with a complete reload
        await this.lockingDeviceDevicesComponent.ngOnInit();
      }
    });
  }

  private resetSearch(): void {
    this.typeaheadIndex = new Set<string>();
    this.searchQuery = "";
    this.pagetitle = new PagetitleComponent(new FormBuilder);
    this.pagetitle.reInit();
  }

  protected readonly GROUP_TYPES = GROUP_TYPES;
  protected readonly environment = environment;
  protected readonly DEVICE_GROUP = DEVICE_GROUP;
}
