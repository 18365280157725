import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {AppService} from "../app-service/app.service";
import {Subject} from "rxjs";

const defaultLanguage = 'de'

@Injectable({providedIn: 'root'})
export class LanguageService {

  private languageChanged = new Subject<string>();

  public languages: string[] = ['de', 'en', 'fr', 'nl'];
  public languageLocalization= new Map([
    [ 'de', 'de-DE' ],
    [ 'en', 'en-GB' ],
    [ 'nl', 'nl-NL' ],
    [ 'fr', 'fr-FR' ]
  ]);

  constructor(
    public translate: TranslateService,
    private app: AppService) {
    this.translate.addLangs(this.languages);
    let browserLang;
    const storedValue = window.localStorage.getItem('lang')
    browserLang = (storedValue != null ?
        storedValue :
        navigator.language
    )

    this.setLanguage(browserLang)
  }

  /***
   * Cookie Language set
   */
  public setLanguage(lang: any) {
    let match = lang?.match(/[a-z]{2}/)
    let langKey = match ? match[0] : defaultLanguage

    this.translate.use(this.languages.includes(langKey) ? langKey : defaultLanguage);
    window.localStorage.setItem('lang', langKey);

    this.newLanguage(langKey);
  }

  public setDefaultLanguage(lang: any) {
    this.translate.setDefaultLang(lang)
  }

  getCurrentLanguage() {
    return window.localStorage.getItem('lang') || this.translate.getDefaultLang();
  }

  get currentLocalization(): string {
    return this.languageLocalization.get(this.getCurrentLanguage()) || 'en-GB';
  }

  newLanguage(lang: string) {
    this.languageChanged.next(lang);
  }

  get languageChanged$() {
    return this.languageChanged.asObservable();
  }
}
