<div class="container">
  <div *ngIf="!accessReadonlyGetter" class="d-flex mb-3">
    <div class="d-flex justify-content-start gap-2">
      <div class="align-self-center">
        <input id="selectAllItemsRemove" type="checkbox" class="form-check-input"
               [checked]="removeSelectionGetter.size == (groups.value?.selectedItems.size + devices.value?.selectedItems.size) && removeSelectionGetter.size != 0"
               (change)="onRemoveSelectionChangeAll($event)"
               [attr.disabled]="(devices.value?.selectedItems.size == 0 && groups.value?.selectedItems.size == 0) ? true : undefined">
        <label for="selectAllItemsRemove" class="ps-1 m-0"
               [ngClass]="{'text-muted': (devices.value?.selectedItems.size == 0 && groups.value?.selectedItems.size == 0)}">{{ "BUTTON.MULTI.SELECT_ALL" | translate }}</label>
      </div>
      <button type="button" [disabled]="removeSelectionGetter.size == 0" (click)="onRemoveSelected()"
              [ngClass]="removeSelectionGetter.size == 0 ? 'btn-outline-light text-muted' : 'btn-outline-danger'"
              class="btn">{{ "BUTTON.MULTI.REMOVE_COUNT" | translate:{count: removeSelectionGetter.size} }}
      </button>
    </div>
    <div class="d-flex justify-content-end flex-grow-1">
      <button type="button" data-testid="add-button" class="btn btn-outline-primary" (click)="openItemSelector()">
        {{ "BUTTON.ADD" | translate }}
      </button>
    </div>
  </div>

  <div id="selector-content">

    <ng-template #selectedItemTemplate let-element="element" let-key="key">
      <div [ngClass]="{'list-group-item-action cursor-pointer accordion-fix': !accessReadonlyGetter}"
           class="list-group-item d-flex align-items-start flex-grow-1 gap-2"
           (click)="onRemoveSelectionChange(!removeSelection.has(key), key)">

        <!-- IMAGE -->
        <div *ngIf="element.image != null" class="d-flex avatar flex-shrink-0">
          <img [src]="element.image! | Trust:'resourceUrl'" alt="icon" cesDefaultImage>
        </div>

        <div class="flex-grow-1 overflow-hidden">
          <div class="d-flex mb-2">
            <h6 class="font-size-15 mb-0 text-truncate"><span class="text-dark">{{ element.name }}</span></h6>
          </div>
          <p *ngFor="let info of element.info" class="text-muted text-truncate mb-0">{{ info }}</p>
        </div>

        <input *ngIf="!accessReadonlyGetter" type="checkbox"
               class="form-check-input item-selector align-self-start mt-0 font-size-18 flex-shrink-0"
               [checked]="removeSelection.has(key)" (change)="onRemoveSelectionChange($event, key)">
      </div>
    </ng-template>


    <ng-template #groupLists let-show="show" let-items="items" let-template="template">
      <div ngbAccordion *ngIf="show">
        @for (item of items; track $index) {
          <div ngbAccordionItem style="{{ $last ? 'margin-top: 24px' : ''}}" [collapsed]="false"
               [id]="'ngbAccordionItem-'+$index">
            <h2 ngbAccordionHeader>
              <button ngbAccordionButton>{{ item.title | translate:{count: item.count || item.selectedItems.size} }}
              </button>
            </h2>
            <div ngbAccordionCollapse>
              <div ngbAccordionBody>
                <ng-template>
                  <div class="accordion-body-no-padding list-group list-group-flush">
                    <ng-container *ngFor="let key of item.selectedItems">
                      <div *ngIf="item.map.get(key) != null"
                           [ngTemplateOutlet]="template"
                           [ngTemplateOutletContext]="{element: item.map.get(key), key: key}">
                      </div>
                    </ng-container>

                    <div *ngIf="item.selectedItems.size == 0" class="p-2">
                      <p class="m-2 text-center">
                        {{ item.button | translate }}
                      </p>
                    </div>

                  </div>
                </ng-template>
              </div>
            </div>
          </div>
        }
      </div>
    </ng-template>

    <div *ngIf="groups.value?.selectedItems.size == 0 && devices.value?.selectedItems.size == 0" class="card shadow-none p-2">
      <p class="m-2 text-center">
        {{ "ACCESS_PROFILES.SELECT_DEVICES.NO_LOCKING_DEVICES_OR_GROUPS_SELECTED" | translate }}
      </p>
    </div>

    <div [ngTemplateOutlet]="groupLists"
         [ngTemplateOutletContext]="{items: _items, template: selectedItemTemplate, show: groups.value?.selectedItems.size != 0 || devices.value?.selectedItems.size != 0}">
    </div>

  </div>

  <!-- ADD MODAL -->
  <ng-template #itemSelector let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">
        {{
          licenseTypeId > 1 ?
            ("ACCESS_PROFILES.SELECT_DEVICES.ADD_LOCKING_DEVICES" | translate) :
            ("LOCKING_DEVICES.GROUPS.SELECT_LIST.ADD_LOCKING_DEVICES"  | translate)
        }}
      </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
    </div>
    <div id="search-bar" class="d-flex justify-content-between flex-grow-1">
      <form class="search-box w-100 h-100 position-relative" [formGroup]="searchForm" (change)="updateSearch($event)">
        <i class="mdi mdi-magnify search-icon d-flex align-items-center h-100"></i>
        <input id="search-bar-input" data-testid="search-bar-input" type="text" class="form-control rounded h-100"
               formControlName="searchText"
               placeholder="{{ 'PAGETITLE.SEARCH' | translate }}" (change)="updateSearch($event)"
               (input)="updateSearch($event)">
      </form>
    </div>

    <div class="modal-body accordion-flush">

      <ng-template #addItemTemplate let-element="element" let-key="key">
        <div
          class="list-group-item list-group-item-action d-flex align-items-start flex-grow-1 gap-2 cursor-pointer accordion-fix"
          (click)="onAddSelectionChange(!addSelection.has(element.id), element.id)">

          <!-- IMAGE -->
          <div *ngIf="element.image" class="d-flex avatar flex-shrink-0">
            <img [src]="element.image! | Trust:'resourceUrl'" cesDefaultImage alt="icon">
          </div>

          <div class="flex-grow-1 overflow-hidden">
            <div class="d-flex mb-2">
              <h6 class="font-size-15 mb-0 text-truncate"><span class="text-dark">{{ element.name }}</span></h6>
            </div>
            <p *ngFor="let info of element.info" class="text-muted text-truncate mb-0">{{ info }}</p>
          </div>

          <input type="checkbox" class="form-check-input item-selector align-self-start mt-0 font-size-18 flex-shrink-0"
                 [checked]="addSelectionGetter.has(element.id)" (change)="onAddSelectionChange($event, element.id)">
        </div>
      </ng-template>

      <div [ngTemplateOutlet]="groupLists"
           [ngTemplateOutletContext]="{items: _modalItems, template: addItemTemplate, show: true}">
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" [disabled]="addSelectionGetter.size == 0" (click)="modal.close(); onAddSelected()"
              [ngClass]="addSelectionGetter.size == 0 ? 'text-muted' : 'text-primary btn-outline-light'"
              class="btn">
        {{ "BUTTON.MULTI.ADD_COUNT" | translate:{count: addSelectionGetter.size} }}
      </button>
      <button type="button" class="btn btn-outline-light text-secondary" (click)="modal.close()">
        {{ "BUTTON.CANCEL" | translate }}
      </button>
    </div>
  </ng-template>
</div>
