<form>
  <div class="form-floating form-floating-custom mb-3">
    <date-time-picker
      style="flex-grow: 1"
      [readonly]="accessReadonly"
      [(dateValue)]="startDate"
      [showChanges]="startDateChanged">
    </date-time-picker>
  </div>

  <div class="d-flex flex-row gap-1">
    <date-time-picker
      style="flex-grow: 1"
      titleText="TIME_MODELS.FIELD.TIME_START.TEXT"
      [readonly]="accessReadonly"
      [(timeValue)]="timeStart"
      [invalidTime]="invalid"
      [showChanges]="timeStartChanged">
    </date-time-picker>
    <date-time-picker
      style="flex-grow: 1"
      titleText="TIME_MODELS.FIELD.TIME_END.TEXT"
      [readonly]="accessReadonly"
      [(timeValue)]="timeEnd"
      [invalidTime]="invalid"
      [showChanges]="timeEndChanged">
    </date-time-picker>
  </div>

  <div *ngIf="invalid" class="invalid-feedback d-flex flex-column mb-2">
    <div>{{ "TIME_MODELS.INTERVAL.TIME_START_END_VALUE" | translate }}</div>
  </div>
</form>

