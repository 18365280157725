import {Component, OnInit} from '@angular/core';
import {ApiService} from "../../../../core/services/api-service/api.service";
import {ItemListItem, ItemListMapper} from "../../../../shared/item-list/ItemListItem";
import {ListViewAction} from "../../../../shared/models/Actions";
import {ListActionEvent} from "../../../../shared/models/ListActionEvent";
import {TranslateService} from "@ngx-translate/core";
import {FormatterService} from "../../../../core/services/formatter-service/formatter.service";
import {ItemManager} from "../../../../shared/item-list/ItemManager";
import {OngoingJobDto} from "../../../../shared/entities/jobs/OngoingJobDto";
import {JobDistributedDeviceDto} from "../../../../shared/entities/jobs/JobDistributedDeviceDto";
import {firstValueFrom} from "rxjs";
import {ToastService} from "../../../../shared/notification/toast/toast.service";

@Component({
  selector: 'app-ongoing-jobs-form',
  templateUrl: './ongoing-jobs-form.component.html',
  styleUrls: ['./ongoing-jobs-form.component.scss']
})
export class OngoingJobsFormComponent implements OnInit, ItemListMapper<OngoingJobDto> {
  itemManager: ItemManager<OngoingJobDto>
  selectedItem?: OngoingJobDto;
  currentDistributedDevices: JobDistributedDeviceDto[] = [];
  _isLoading: boolean = false;
  get isLoading():boolean {
    return this._isLoading;
  }
  constructor(
    private apiService: ApiService,
    private notification: ToastService,
    private translate: TranslateService,
    private formatter: FormatterService) {
    this.itemManager = new ItemManager<OngoingJobDto>(this, this.notification);
  }

  async ngOnInit() {
    this._isLoading = true;
    this.itemManager.setItems(await firstValueFrom(await this.apiService.job.getAllOngoingJobs()));
    this._isLoading = false;
  }

  mapToItemList(item: OngoingJobDto): ItemListItem<OngoingJobDto> {
    const name = this.translate.instant(
      "JOBS.ITEM_LIST.NAME",
      {id: item.id, name: item.shortName, uid: item.lockingDeviceUid})

    const creationDetails = this.translate.instant(
      "JOBS.ITEM_LIST.CREATED_DETAILS",
      {date: this.formatter.formatTimestamp(item.createdTimestamp, {mode: 'date'}), user: item.createdUsername})

    const distDevices: number = (item.distributedDevices || []).length;
    let transferDetails = ""
    if (distDevices == 0) {
      transferDetails = this.translate.instant("JOBS.ITEM_LIST.DISTRIBUTED_DETAILS.NONE")
    }
    if (distDevices == 1) {
      transferDetails = this.translate.instant("JOBS.ITEM_LIST.DISTRIBUTED_DETAILS.ONE")
    }
    if (distDevices > 1) {
      transferDetails = this.translate.instant("JOBS.ITEM_LIST.DISTRIBUTED_DETAILS.COUNT", {count: distDevices})
    }

    const percentFinished = (item.completedJournals || 0) / (item.totalJournals || 0)

    let imageName = '0'
    if (percentFinished >= 0.25) {
      imageName = '25'
    }
    if (percentFinished >= 0.5) {
      imageName = '50'
    }
    if (percentFinished >= 0.75) {
      imageName = '75'
    }
    if (percentFinished == 1) {
      imageName = '100'
    }


    return new ItemListItem('' + item.id, name, item)
      .addInfo(creationDetails)
      .addInfo(transferDetails)
      .addAction(new ListViewAction())
      .setImage(`assets/ces/job/jobs_ongoing_${imageName}.svg`)
  }

  async onReturnEvent() {
    this.selectedItem = undefined;
    this.currentDistributedDevices = []
  }

  async onListEvent(listActionEvent: ListActionEvent<OngoingJobDto>) {
    this.selectedItem = listActionEvent.item
    this.currentDistributedDevices = (this.selectedItem.distributedDevices || []);
  }
}
