import {UuidName} from "../Basic";

export abstract class AccessProfile extends UuidName {
  description: string | undefined;
  accessTypeId: number;
  officeFunction: boolean;
  pin: boolean;

  protected constructor(uuid: string,
                        name: string,
                        description: string | undefined,
                        accessTypeId: number,
                        officeFunction: boolean,
                        pin: boolean) {
    super(uuid, name);
    this.description = description;
    this.accessTypeId = accessTypeId;
    this.officeFunction = officeFunction;
    this.pin = pin;
  }
}
