import {AfterViewInit, Directive, EventEmitter, Output, TemplateRef, ViewContainerRef} from '@angular/core';

@Directive({
  selector: '[isVisible]'
})
export class IsVisibleDirective implements AfterViewInit {

  @Output() isVisible = new EventEmitter<boolean>()
  lastState = false

  alreadyRendered!: boolean; // checking if visible already

  constructor(
    private viewContainer: ViewContainerRef,
    private template: TemplateRef<any>
  ) {}

  ngAfterViewInit() {
    const commentEl = this.viewContainer.element.nativeElement; // template
    const elToObserve = commentEl.parentElement;

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.renderContents(entry.isIntersecting)

        if(this.lastState != entry.isIntersecting) {
          this.lastState = entry.isIntersecting
          this.isVisible.emit(entry.isIntersecting)
        }
      });
    }, {threshold: [0, .1, .9, 1]});
    observer.observe(elToObserve);
  }

  renderContents(isVisible: boolean) {
    if (isVisible && !this.alreadyRendered) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.template);
      this.alreadyRendered = true;
    }
  }

}
