<app-item-list [items]="itemManager" [enableChangesWarning]="hasPendingChanges()" [showLoading]="isLoading"
               (onListAction)="onSelectEvent($event)" (onItemAction)="onEditEvent($event)" (onItemReturn)="onReturnEvent()"
               [listFilter]="searchFilter" [(itemSelected)]="itemSelected" [viewTypeModuleId]="'5'">

  <ul ngbNav #nav="ngbNav" class="nav nav-tabs nav-tabs-custom nav-justified" [(activeId)]="selectedNavTab" [destroyOnHide]="false">
    <li ngbNavItem="lockingDevice">
      <a ngbNavLink>{{ "LOCKING_DEVICES.DEVICES.TAB.LOCKING_DEVICE" | translate }}</a>
      <ng-template ngbNavContent>
        <app-locking-device-form [lockingDevice]="selectedItem!" [accessReadonly]="accessReadonly"
                                 (onChanges)="handlePendingChanges()" >
        </app-locking-device-form>
      </ng-template>
    </li>
    <li ngbNavItem="deviceParams">
      <a ngbNavLink>{{ "LOCKING_DEVICES.DEVICES.TAB.DEVICE_PARAMS" | translate }}</a>
      <ng-template ngbNavContent>
        <app-locking-device-params-form [lockingDeviceParamsAndTimeModels]="selectedUuid"
                                        [accessReadonly]="accessReadonly" (onChanges)="handlePendingChanges()">
        </app-locking-device-params-form>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" id="navOutlet" class="pt-4"></div>

</app-item-list>
