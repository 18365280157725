<form>
  <div *ngIf="!accessReadonly" class="form-floating form-floating-custom mb-3 d-flex gap-1">

    <date-time-picker
      style="flex-grow: 1"
      [readonly]="accessReadonly"
      [(dateValue)]="startDate">
    </date-time-picker>

    <button class="btn text-nowrap"
            [ngClass]="{'btn-outline-primary': !accessReadonly && addEnabled, 'text-muted btn-outline-light': accessReadonly || !addEnabled}"
            (click)="addDay()" type="button" [disabled]="(accessReadonly || !addEnabled) && isDayAlreadyAdded">
      <i icon="action-add"></i>
      {{ "BUTTON.ADD" | translate }}
    </button>
  </div>
  <div *ngIf="daysLength == 0 || hasDuplicatedDays || daysLength > 100" class="invalid-feedback d-flex mb-3">
    <div *ngIf="daysLength == 0">{{ "TIME_MODEL.LIST_OF_DAYS.REQUIRED" | translate }}</div>
    <div *ngIf="daysLength > 100">{{ "TIME_MODEL.LIST_OF_DAYS.MAX_LENGTH" | translate }}</div>
    <div *ngIf="hasDuplicatedDays">{{ "TIME_MODEL.LIST_OF_DAYS.DUPLICATES" | translate }}</div>
  </div>

  <div class="list-group">

    @for(d of daysValue; track d) {
      <div class="form-floating flex-grow-1 form-floating-custom"
           [ngClass]="d.uuid.length == 0 ? 'newly-added' : ''"
           style="background-color: #313131;">

        <div class="form-floating-icon">
          <i icon="date"></i>
        </div>
        <div class="list-of-days-days">
          <div>
            {{ d.date | dateTimeRecurring:(d.executionTypeId == 9) }}
          </div>
          @if (d.executionTypeId == 9) {
            <div style="max-width: 41px !important; margin-left: 1px;">
              <i class="indication-icon"  icon="check"></i>
            </div>
          } @else {
            <div style="max-width: 40px">
              <i class="indication-icon" style="margin-left: -1px" icon="cross"></i>
            </div>
          }
          <div *ngIf="!accessReadonly" class="d-flex flex-row"
               style="place-content: flex-start; min-height: 40px; flex-grow: 10;">
            <button class="btn"
                    [ngClass]="d.executionTypeId == 9 ? 'btn-outline-primary':'text-muted btn-outline-light'"
                    (click)="changeRecurring(d)"
                    type="button" [disabled]="d.date.day == 29 && d.date.month == 2">
              {{ "TIME_MODELS.FIELD.RECURRING.TEXT" | translate }}
            </button>
          </div>
          <div *ngIf="!accessReadonly" class="d-flex flex-row"
               style="place-content: flex-end; min-height: 40px; margin: 8px">
            <button class="btn text-nowrap"
                    [ngClass]="{'btn-outline-danger': deleteEnabled, 'text-muted btn-outline-light': !deleteEnabled}"
                    (click)="removeDay(d)" type="button" [disabled]="!deleteEnabled">
              <i icon="action-delete"></i>
              {{ "BUTTON.REMOVE" | translate }}
            </button>
          </div>
        </div>
      </div>
    }
  </div>

  <div *ngIf="daysLength == 0" style="padding-top: 20px; padding-bottom: 20px;"
       class="list-group-item text-center text-muted">
    {{ "TIME_MODELS.LIST_OF_DAYS.EMPTY" | translate }}
  </div>

</form>
