import {animate, style, transition, trigger} from "@angular/animations";

export class Trigger {
  static readonly FadeAnimation = trigger('fadeAnimation', [
    transition(':enter', [
      style({ opacity: 0, height: 0 }), animate('300ms 0ms ease-in-out', style({ opacity: 1, height: "*" }))]
    ),
    transition(':leave',
      [style({ opacity: 1, height: "*" }), animate('300ms 0ms ease-in-out', style({ opacity: 0, height: 0 }))]
    )
  ])
}
