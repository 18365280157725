import {NgbDateParserFormatter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {Injectable} from "@angular/core";

@Injectable()
export class DateTimeConverter extends NgbDateParserFormatter {
  readonly DELIMITER = ['/', '.'];

  parse(value: string): NgbDateStruct | null {
    if (value) {
      let date: string[] = ['1', '2', '2000'];
      this.DELIMITER.forEach(delimiter =>
        date = value.includes(delimiter) ? value.split(delimiter) : date
      )
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (date) {
      const day: string = `${date.day < 10 ? '0' + date.day : date.day}`;
      const month: string = `${date.month < 10 ? '0' + date.month : date.month}`;
      return `${day}${this.DELIMITER[1]}${month}${this.DELIMITER[1]}${date.year}`;
    }
    return '';
  }
}
