export class UserSessionInfoDto {
   token: string;
   expireAt: number;
   changePassword: boolean;
   userUUID: string;
   roleId: number;
   clientName: string;
   username: string;
   hasProfileImage: boolean;
   systemIdentifier: any; // Uint
   onlineAvailable: boolean;
   tokenFrom: 'login' | 'refresh';

   constructor(token: string,
               expireAt: number,
               changePassword: boolean,
               userUuid: string,
               roleId: number,
               clientName: string,
               username: string,
               hasProfileImage: boolean,
               systemIdentifier: any,
               onlineAvailable: boolean,
               tokenFrom?: 'login' | 'refresh') {
     this.token = token;
     this.expireAt = expireAt;
     this.changePassword = changePassword;
     this.userUUID = userUuid;
     this.clientName = clientName;
     this.username = username;
     this.hasProfileImage = hasProfileImage;
     this.systemIdentifier = systemIdentifier;
     this.onlineAvailable = onlineAvailable;
     this.roleId = roleId;
     this.tokenFrom = tokenFrom ? tokenFrom : 'refresh';
   }
}
