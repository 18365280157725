import {Injectable} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {ModalContainerComponent} from "./modal-container.component";
import {ModalContainerContent} from "./modal-container.class";

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  constructor(private modalService: NgbModal) {
  }

  async openModal(content: ModalContainerContent): Promise<any> {
    const modalRef = this.modalService.open(ModalContainerComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'modal-dialog-centered',
      centered: true,
      backdropClass: 'backdrop'
    });
    modalRef.componentInstance.content = content;
    return await modalRef.result;
  }

  openModalRef(content: ModalContainerContent): NgbModalRef {
    const modalRef = this.modalService.open(ModalContainerComponent, {
      backdrop: 'static',
      keyboard: false,
      windowClass: 'modal-dialog-centered',
      centered: true,
      backdropClass: 'backdrop'
    });
    modalRef.componentInstance.content = content;
    return modalRef;
  }
}
