import {LookupMapping} from "./lookup";

export const ASSEMBLY_STATE: LookupMapping[] = [
  {
    "id": 1,
    "value": "not_mounted"
  },
  {
    "id": 2,
    "value": "mounted"
  },
  {
    "id": 3,
    "value": "defect"
  }
];
