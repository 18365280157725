import {Injectable} from '@angular/core';
import {LocalStorageSchemaService} from "../json-schema-service/local-storage-schema.service";

const millisecondsPerSecond = 1000
const secondsPerHour = 3600
const secondsPerDay = 86400

@Injectable({
  providedIn: 'root'
})
export class FormatterService {

  constructor() {
  }

  formatToNgbTimePicker(timeValue: number): { hour: number, minute: number } {
    let hours: number = 0;
    if (timeValue >= 3600) {
      hours = Math.floor(timeValue / 3600);
      timeValue = timeValue - (hours * 3600);
    }
    if (timeValue >= 60) {
      return {hour: hours, minute: (timeValue / 60)};
    }
    return {hour: hours, minute: timeValue};
  }

  formatFromNgbTimePicker(timeValue: { hour: number, minute: number }): number {
    return (timeValue.hour % 24) * 3600 + timeValue.minute * 60;
  }

  formatToNgbDatePicker(dateValue: Date | number | undefined | string | {year: number, month: number, day: number}, asUTC: boolean = false): { year: number; month: number; day: number } {
    switch (typeof dateValue) {
      case 'number':
        dateValue = new Date(dateValue * millisecondsPerSecond);
        break;
      case 'string':
        dateValue = new Date(Date.parse(dateValue));
        break;
      case "undefined":
        dateValue = new Date(Date.now());
        break;
      case 'object':
        if (dateValue == null) {
          dateValue = new Date(Date.now()).setHours(0,0,0,0);
        } else if (Object.prototype.hasOwnProperty.call(dateValue, 'year')) {
          return dateValue as { year: number, month: number, day: number };
        }
    }
    let year: number = (dateValue as Date).getFullYear();
    if (asUTC && (dateValue as Date).getFullYear() != (dateValue as Date).getUTCFullYear()) {
      year = (dateValue as Date).getUTCFullYear();
    }
    let month: number = (dateValue as Date).getMonth();
    if (asUTC && (dateValue as Date).getUTCMonth() != (dateValue as Date).getMonth()) {
      month = (dateValue as Date).getUTCMonth();
    }
    month++;
    let day: number = (dateValue as Date).getDate();
    if (asUTC && (dateValue as Date).getUTCDate() != (dateValue as Date).getDate()) {
      day = (dateValue as Date).getUTCDate();
    }
    return { year: year, month: month, day: day };
  }

  formatFromNgbDatePicker(dateValue: { year: number; month: number; day: number }): Date {
    return new Date(dateValue.year, dateValue.month - 1, dateValue.day)
  }

  formatAsUTCFromNgbDatePicker(dateValue: { year: number; month: number; day: number }): Date {
    return new Date(Date.UTC(dateValue.year, dateValue.month - 1, dateValue.day));
  }


  limit(value: number | any, min: number, max: number, replaceOutside?: number): number {

    if (value == null || typeof value != 'number') {
      return replaceOutside != null ? replaceOutside : min
    }

    if (replaceOutside != null && (value < min || value > max)) {
      return replaceOutside
    }

    return Math.min(Math.max(value, min), max);
  }

  getDateSeconds(roundToMinute = false): number {
    return FormatterService.getDateSeconds(roundToMinute)
  }

  addDate(startDate: any, addDays: any = 0, addHours: any = 0, addMinutes: any = 0) {
    return startDate + addDays * secondsPerDay + addHours * secondsPerHour + addMinutes * 60;
  }


  formatTimestamp(
    timestamp: number,
    options?: { mode?: 'date' | 'time' | 'dateTime', hideYear?: boolean, replaceInvalid?: string }
  ): string {
    if (isNaN(timestamp)) {
      return options?.replaceInvalid != null ? options.replaceInvalid : ""
    }

    const dateWithTimezone = new Date(timestamp * millisecondsPerSecond) // date always contains timezone
    const date = new Date(dateWithTimezone.toISOString().slice(0, -1)) // remove timezone
    const lang = new LocalStorageSchemaService().localStorage('lang').plain;

    if (isNaN(date.getTime()) && options?.replaceInvalid != null) {
      return options.replaceInvalid
    }

    if (options?.mode == 'date') {
      return date.toLocaleDateString(lang,
        {day: '2-digit', year: options?.hideYear ? undefined : 'numeric', month: '2-digit'})
    }
    if (options?.mode == 'time') {
      return date.toLocaleTimeString(lang,
        {hour: '2-digit', minute: '2-digit'})
    }

    return date.toLocaleTimeString(lang,
      {
        day: '2-digit',
        year: options?.hideYear ? undefined : 'numeric',
        month: '2-digit',
        hour: '2-digit',
        minute: '2-digit'
      })

  }

  formatArray(array: readonly any[], replaceEmpty: string = ""): string {
    if (array == null || array.length == 0) {
      return replaceEmpty
    }
    return array.join(", ")
  }

  asDatePicker(date: number): string {
    return FormatterService.asDatePicker(date)
  }

  asTimePicker(time: number): string {
    return FormatterService.asTimePicker(time)
  }

  fromPicker(value: string): number {
    return FormatterService.fromPicker(value)
  }

  fromPickerOrNull(value: string): number | undefined {
    return (value == null || value == '') ? undefined : FormatterService.fromPicker(value)
  }

  translateDateRecurring(value: any, recurring: boolean) {
    return FormatterService.translateDateRecurring(value, recurring)
  }

  getUTCMilliseconds(value: number) {
    let date = new Date(value * millisecondsPerSecond)

    return isNaN(date.getTime()) ? 0 : date.getTime()
  }


  static asDatePicker(date: number): string {
    return date != null && date != 0 ? new Date(date * millisecondsPerSecond).toISOString().substring(0, 10) : '';
  }

  static asTimePicker(time: number): string {
    return new Date(time * millisecondsPerSecond).toISOString().substring(11, 19)
  }

  static fromPicker(value: string): number {
    if (value == null) return 0;

    if (value.match(/\d{4}-\d{2}-\d{2}/g) != null) {
      value = `${value}T00:00:00.000Z` // date to utc
    } else if (value.match(/\d{2}:\d{2}:\d{2}/g) != null) {
      value = `1970-01-01T${value}.000Z` // time to utc
    } else {
      return 0
    }

    return Math.floor(new Date(value).getTime() / millisecondsPerSecond)
  }

  /** Translates a date to recurring or non-recurring. If recurring is set the year component will be set to 1970.
   *  Otherwise, if recurring is not set the date will be translated to the current year if it is set to 1970.
   *
   *  This helper function was introduced to better support the time model list of day.
   *
   * @param value Date value as string: yyyy-mm-dd
   * @param recurring Translation to 1970 or back
   */
  static translateDateRecurring(value: string, recurring: boolean): number {
    if (recurring) {
      value = `1970${value.substring(4, value.length)}`;
    } else {
      value = value.replace('1970', `${new Date().getFullYear()}`);
    }
    let date: Date = new Date(value);

    if (isNaN(date.getTime())) {
      date = new Date(FormatterService.getDateSeconds());
    }
    return Math.floor(date.getTime() / millisecondsPerSecond);
  }

  static getDateSeconds(roundToMinute = false): number {
    const seconds = Math.floor(Date.now() / millisecondsPerSecond) - new Date().getTimezoneOffset() * 60
    return (roundToMinute ? seconds - (seconds % 60) : seconds)
  }
}
