<app-pagetitle title="{{'MENU.TIME_MODELS' | translate}}" (onSearch)="onSearch($event)" [searchEntries]="searchEntries"
               [disableButtons]="isLicenseBusiness && isLicenseExpired"
               [pagetitleActions]="pagetitleActions" (onPagetitleAction)="onAddTimeModel()"
               [itemSelected]="itemSelected"></app-pagetitle>

<app-item-list [items]="itemManager" [enableChangesWarning]="hasPendingChanges()" [showLoading]="isLoading"
               [disableButtons]="(isLicenseBusiness && isLicenseExpired) || !intervalSelectorValid"
               (onListAction)="onSelectEvent($event)" (onItemAction)="onEditEvent($event)"
               (onItemReturn)="onResetView()"
               [listFilter]="searchFilter" [(itemSelected)]="itemSelected" [viewTypeModuleId]="'13'"
               [multiSelectActions]="multiSelectActions" (onMultiListAction)="onMultiDeleteAction($event)">

  <div class="d-flex g-4 flex-column">

    <div class="flex-grow-1">
      <div class="card h-100 shadow-none">
        <h6 class="card-header">{{ "TIME_MODELS.SECTION.GENERAL" | translate }}</h6>
        <form class="card-body" >

          <div class="d-flex flex-row gap-3 mb-3">
            <div class="form-floating form-floating-custom flex-grow-1">
              <input [readonly]="accessReadonly"
                     type="text"
                     class="form-control"
                     id="shortName"
                     name="shortName"
                     [(ngModel)]="selectedChangeValue.value.shortName"
                     [ngClass]="{ 'is-invalid': !selectedChangeValue.isSpecificGroupValid('shortName'),
                     'pending-changes' : selectedChangeValue.hasSpecificChanges('shortName') &&  selectedChangeValue.isSpecificGroupValid('shortName')}"
                     placeholder="-">
              <label for="shortName">{{ "TIME_MODELS.FIELD.SHORT_NAME.TEXT" | translate }}*</label>
              <div class="form-floating-icon">
                <i icon="name"></i>
              </div>
              <div *ngIf="!selectedChangeValue.isSpecificGroupValid('shortName')" class="invalid-feedback">
                <div>
                @if(!selectedChangeValue.isSpecificValid('shortNameUnique')) {
                  {{ "TIME_MODELS.FIELD.SHORT_NAME.EXISTS" | translate }}
                } @else if (!selectedChangeValue.isSpecificValid('shortNameRequired')) {
                  {{ "TIME_MODELS.FIELD.SHORT_NAME.REQUIRED" | translate }}
                } @else if (!selectedChangeValue.isSpecificValid('shortNameMax')) {
                  {{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("TIME_MODELS.FIELD.SHORT_NAME.TEXT" | translate) : ('' + validatorMaxLength32) }}
                }
                </div>
              </div>
            </div>

            <div class="form-floating form-floating-custom flex-grow-1">
              <select *ngIf="!accessReadonly; else modelType"
                      class="form-select"
                      id="modelType"
                      name="modelTypeId"
                      [(ngModel)]="selectedChangeValue.value.modelTypeId"
                      (change)="onUpdateModel($event)">
                <option *ngFor="let type of lookupData"
                        [value]="type.id">{{ ('TIME_MODELS.TYPE.' + type.value.toUpperCase()) | translate }}
                </option>
              </select>
              <ng-template #modelType>
                <input readonly id="modelType" class="form-control" [value]="timeModelTypeForTranslation | translate">
              </ng-template>
              <label for="modelType">{{ "TIME_MODELS.FIELD.MODEL_TYPE.TEXT" | translate }}</label>
              <div class="form-floating-icon">
                <i icon="select"></i>
              </div>
            </div>
          </div>

          <div class="form-floating form-floating-custom flex-grow-1">
            <input [readonly]="accessReadonly"
                   type="text"
                   class="form-control"
                   id="description"
                   name="description"
                   [(ngModel)]="selectedChangeValue.value.description"
                   [ngClass]="{ 'is-invalid': !selectedChangeValue.isSpecificGroupValid('description'),
                     'pending-changes' : selectedChangeValue.hasSpecificChanges('description') &&  selectedChangeValue.isSpecificGroupValid('description')}"
                   placeholder="-">
            <label for="description">{{ "TIME_MODELS.FIELD.DESCRIPTION.TEXT" | translate }}</label>
            <div class="form-floating-icon">
              <i icon="description"></i>
            </div>
            <div *ngIf="!selectedChangeValue.isSpecificGroupValid('description')" class="invalid-feedback">
              <div
                *ngIf="selectedChangeValue.isSpecificValid('descriptionMax')">{{ "NOTIFICATION.TOAST.CES_ERROR.cesErrStructTagBadMaxLength" | translate | errorTranslate: ("TIME_MODELS.FIELD.DESCRIPTION.TEXT" | translate) : ('' + validatorMaxLength64) }}
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>

    <div class="d-flex gap-4 flex-row">

      <div [hidden]="!showCustomForm" [ngClass]="showIntervalSelector ? 'col-6 flex-shrink-1' : 'flex-grow-1'"
           @fadeAnimation>
        <div class="card h-100 shadow-none">
          <h6 class="card-header">{{ timeModelTypeForTranslation | translate }}</h6>
          <div class="card-body pb-0">

            <!-- OUTPUT OF SELECTED TIME MODEL COMPONENT -->
            <ng-container #viewContainer></ng-container>

          </div>
        </div>
      </div>

      <div *ngIf="showIntervalSelector" [ngClass]="showCustomForm ? 'col-6 flex-shrink-1' : 'flex-grow-1'"
           @fadeAnimation>
        <app-interval-selector (changes)="changes()"
                               [timeModelInterval]="selectedItem?.intervals || []"
                               [accessReadonly]="accessReadonly">
        </app-interval-selector>
      </div>
    </div>
  </div>
</app-item-list>
