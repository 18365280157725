<app-item-list [items]="itemManager" [enableChangesWarning]="hasPendingChanges()" [showLoading]="isLoading"
               (onListAction)="onSelectEvent($event)" (onItemAction)="onEditEvent($event)" (onItemReturn)="onResetView()"
               [listFilter]="searchFilter" [(itemSelected)]="itemSelected" [viewTypeModuleId]="'4'"
               [multiSelectActions]="multiSelectActions" (onMultiListAction)="onMultiDeleteAction($event)"
               [multiSelectSplitActions]="multiSelectSplittActions" (onMultiSelectSplitActions)="onMultiSplittAction($event)">

  <ul ngbNav #nav="ngbNav" class="nav nav-tabs nav-tabs-custom nav-justified" [(activeId)]="selectedNavTab"
      [destroyOnHide]="false">
    <li ngbNavItem="user">
      <a ngbNavLink>{{ "USERS.USERS.TAB.GENERAL" | translate }}</a>
      <ng-template ngbNavContent>
        <app-user-form [user]="selectedItem!" (onChanges)="checkPendingChanges()"
                       (verificationTriggered)="verificationTriggered()"
                       [canChangeUserRole]="canChangeUserRole"
                       [canChangeUserAccessSettings]="canChangeUserAccessSettings"
                       [licenseExpired]="isLicenseExpiredForUser"></app-user-form>
      </ng-template>
    </li>
    <li ngbNavItem="accessProfiles">
      <a ngbNavLink>{{ "USERS.USERS.TAB.ACCESS_PROFILES" | translate }}</a>
      <ng-template ngbNavContent>
        <app-user-access-profile-form [(userAccessProfiles)]="userAccessProfileUuids"
                                      [readonly]="!canChangeUserAccessSettings"
                                      [licenseExpired]="isLicenseExpiredForUser"
                                      [licenseTypeId]="getLicenseTypeId()">
        </app-user-access-profile-form>
      </ng-template>
    </li>
    <li ngbNavItem="lockingMedia">
      <a ngbNavLink>{{ "USERS.USERS.TAB.LOCKING_MEDIA" | translate }}</a>
      <ng-template ngbNavContent>
        <app-user-locking-media-form [(userLockingMedia)]="userLockingMediaUuids"
                                     [userUuid]="selectedItem?.uuid || ''"
                                     [canChangeUserAccessSettings]="canChangeUserAccessSettings"
                                     [licenseExpired]="isLicenseExpiredForUser">
        </app-user-locking-media-form>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" id="navOutlet" class="pt-4"></div>

</app-item-list>
