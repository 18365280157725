import {Injectable} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {CESService} from "../ces.service";
import {Router} from "@angular/router";
import {IoBridgeStatus} from "../../enums/ioBridgeStatus";
import {HttpClient, HttpResponse} from "@angular/common/http";
import {NodeManager} from "../auth-service/support-services/NodeManager";
import {NodeConfiguration, NodeConfigurationScheme} from "../../interfaces/nodeConfiguration";
import {catchError, debounceTime, firstValueFrom, Observable, throwError} from "rxjs";
import {CommissionManager} from "../auth-service/support-services/CommissionManager";
import {BindingProcess} from "../../../shared/entities/IOB/BindingProcess";
import {IoBridgeProcess} from "../../../shared/util/bindingStates";
import {StatusResponseDto} from "../../../shared/entities/IOB/StatusResponseDto";
import {ClientVersionDto} from "../../../shared/entities/Version/ClientVersionDto";
import {ToastService} from "../../../shared/notification/toast/toast.service";
import {$notifyIOBStatusVersionError, set$notifyIOBStatusVersionError} from "../../../shared/util/FallbackNotifiers";

@Injectable({
  providedIn: 'root'
})
export class IoBridgeService extends CESService {

  running: boolean = false;
  pause: boolean = false;
  versionErrorDetected: boolean = false;

  constructor(router: Router,
              notification: ToastService,
              httpClient: HttpClient,
              private ioBridgeProcess: IoBridgeProcess) {
    super(router, httpClient, notification);
    $notifyIOBStatusVersionError.pipe(debounceTime(500)).subscribe(value => this.versionErrorDetected = value);
  }

  iobConnectionChecker() {
    if (this.running) {
      return;
    }
    this.running = true;
    this.iobRecursiveConnectionUpdater(true);
  }

  async iobConnectionUpdater() {
    this.iobRecursiveConnectionUpdater(false);
    await new Promise(f => setTimeout(f, 100));
    return;
  }

  private iobRecursiveConnectionUpdater(recursion: boolean) {
    if (this.pause) {
      this.running = false;
      setTimeout((): void => recursion ? this.iobConnectionChecker() : undefined, 5000);
      return;
    }
    this.getStatus(environment.ioBridgeUrl)
      .then(status =>
        status.subscribe({
          next: iobResponse => {
            let currentProcess: BindingProcess = CommissionManager.getInstance().bindingProcess;

            if (iobResponse == null) {
              currentProcess.ioBridgeStatus = this.versionErrorDetected ? IoBridgeStatus.UPDATE_REQUIRED : IoBridgeStatus.DISCONNECTED;
              CommissionManager.getInstance().bindingProcess = currentProcess;
              return
            }
            set$notifyIOBStatusVersionError(false);

            CommissionManager.getInstance().bindingProcessState = this.ioBridgeProcess.getStateByName(iobResponse.currentState);
            currentProcess.dtwStatus = iobResponse.usb ? IoBridgeStatus.CONNECTED : IoBridgeStatus.DISCONNECTED;
            this.getVersion(NodeManager.getInstance().dataNodeConfiguration!, new ClientVersionDto(iobResponse.clientIdent, iobResponse.platform, iobResponse.version)).then(version => {
              version.subscribe({
                next: versionResponse => {
                  if (versionResponse.ok) {
                    currentProcess.ioBridgeStatus = IoBridgeStatus.CONNECTED;
                  } else if (versionResponse.status == 426) {
                    currentProcess.ioBridgeStatus = IoBridgeStatus.UPDATE_REQUIRED;
                  } else {
                    currentProcess.ioBridgeStatus = IoBridgeStatus.SERVER_ERROR;
                  }
                },
                error: versionError => {
                  currentProcess.ioBridgeStatus = IoBridgeStatus.SERVER_ERROR;
                },
                complete: () => {
                  CommissionManager.getInstance().bindingProcess = currentProcess;
                }
              });
            });
          }
        })
      )
      .catch(() => {
        let currentProcess: BindingProcess = CommissionManager.getInstance().bindingProcess;
        currentProcess.ioBridgeStatus = IoBridgeStatus.DISCONNECTED;
        CommissionManager.getInstance().bindingProcess = currentProcess;
      }).finally((): void => {
      this.running = false;
      setTimeout((): void => recursion ? this.iobConnectionChecker() : undefined, 5000);
    });
  }

  async getStatus(ioBridge: string): Promise<Observable<StatusResponseDto>> {
    return this.httpClient.get<StatusResponseDto>(`${ioBridge}/status`);
  }

  async getVersion(dataNode: any, body: ClientVersionDto):Promise<Observable<HttpResponse<any>>> {
    dataNode = NodeConfigurationScheme(dataNode as NodeConfiguration);
    return this.httpClient.post<any>(`${dataNode.scheme}://${dataNode.host}${dataNode.path}/clients/version`, JSON.stringify(body), {observe: 'response'});
  }

  async fetchBinding(pin: string, serialNumber: string): Promise<Response> {
    CommissionManager.getInstance().loading = true;
    const url: string = `${environment.ioBridgeUrl}/bind`;
    const bound: Response = await fetch(url, {
      method: "POST",
      body: JSON.stringify({pin, serialNumber} as {
        pin: string,
        serialNumber: string,
      }),
    });
    CommissionManager.getInstance().loading = false;
    return bound;
  }

  async fetchRebinding(pin: string, serialNumber: string, deviceUuid: string): Promise<Response> {
    CommissionManager.getInstance().loading = true;
    const url: string = `${environment.ioBridgeUrl}/bind/${deviceUuid}`;
    const bound: Response = await fetch(url, {
      method: "PATCH",
      body: JSON.stringify({pin, serialNumber} as {
        pin: string,
        serialNumber: string,
      }),
    });
    CommissionManager.getInstance().loading = false;
    return bound;
  }

  async fetchUnbinding(uuid: string): Promise<Response> {
    CommissionManager.getInstance().loading = true;
    const unbind: Response = await fetch(`${environment.ioBridgeUrl}/unbind/${uuid}`, {
      method: "DELETE"
    });
    CommissionManager.getInstance().loading = false;
    return unbind;
  }

  async fetchResetState(): Promise<boolean> {
    CommissionManager.getInstance().loading = true;
    const response: Response = await fetch(`${environment.ioBridgeUrl}/reset`, {
      method: "GET"
    });
    CommissionManager.getInstance().loading = false;
    return response.ok;
  }

  async fetchTestDTW(): Promise<boolean> {
    const response: Response = await fetch(`${environment.ioBridgeUrl}/tester/dtw`, {
      method: "GET"
    });
    return response.ok;
  }

}
