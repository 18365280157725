import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class BlobBase64 {

  constructor() {
  }

  public static BLOB_TO_BASE_64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.readAsDataURL(blob);
    });
  }

  public static BASE_64_TO_BLOB(base64: string): Blob {
    const binaryString = window.atob(base64.split(',')[1]);
    const arrayBuffer = new ArrayBuffer(binaryString.length);
    const uint8Array = new Uint8Array(arrayBuffer);

    for (let i = 0; i < binaryString.length; i++) {
      uint8Array[i] = binaryString.charCodeAt(i);
    }

    return new Blob([arrayBuffer]);
  }

}
