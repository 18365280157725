import {LookupMapping} from "./lookup";

export const COUNTRY: LookupMapping[] = [
  {
    "id": 1,
    "value": "germany"
  },
  {
    "id": 2,
    "value": "netherlands"
  }
];
