import {Directive, HostBinding, Input} from '@angular/core';

@Directive({
  selector: 'input[switchDisabled]'
})
export class SwitchDisabledDirective {

  @HostBinding("disabled")
  _disabled: true | null = null

  @Input() set switchDisabled(disabled: boolean) {
    this._disabled = disabled ? true : null
  }

  constructor() { }

}
