import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {PagetitleAction} from "./pagetitle-action";
import {debounceTime, distinctUntilChanged, Observable, OperatorFunction, Subject} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-pagetitle',
  templateUrl: './pagetitle.component.html',
  styleUrls: ['./pagetitle.component.scss']
})

/**
 * Page Title Component
 */
export class PagetitleComponent implements OnInit {
  protected _shiftRight: boolean = false;

  @Input() title: string | undefined;
  @Input() set shiftRight(shift: boolean) {
    this._shiftRight = shift;
  }
  @Input() disableSearch: boolean = false
  @Input() itemSelected: boolean = false

  _pagetitleActions: PagetitleAction[] = [];

  @Input() set pagetitleActions(actions: PagetitleAction[] | undefined) {
    if (actions && actions.length > 0) {
      this._pagetitleActions = actions;
      this._pagetitleActions.forEach((action,index) => {
        if (action.disabledObserver) {
          action.disabledObserver.pipe(debounceTime(50)).subscribe({
            next: value =>  this._pagetitleActions[index].disabled = value
          });
        }
      })
    }
  }
  @Input() searchEntries: string[] = [];

  @Input() disableButtons: boolean = false;

  @Output() onSearch = new EventEmitter<string>()

  @Output() onPagetitleAction = new EventEmitter<string>()

  searchForm!: FormGroup

  // Search
  private searchDebouncer = new Subject<string>()

  constructor(private formBuilder: FormBuilder) {
    this.searchDebouncer
      .pipe(debounceTime(500))
      .subscribe((value) => this.onSearch.emit(value));
  }

  ngOnInit(): void {
    this.searchForm = this.formBuilder.group({
      searchText: ['', []]
    })
    this.backgroundHelperOnScrollBecauseOfGradientBackgroundDueToBlackDesign();
  }

  reInit(): void {
    //@ts-ignore
    document.getElementById('search-bar-input').value = '';
    this.searchEntries = [];
    this.onSearch = new EventEmitter<string>();
    this.onPagetitleAction = new EventEmitter<string>();
    this.itemSelected = false;
    this.searchDebouncer = new Subject<string>();
    this.ngOnInit();
  }

  typeahead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.searchEntries.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  updateSearch(event?: any) {
    let search = this.searchForm.get("searchText")?.value || ""
    if (event instanceof InputEvent) {
      search = (<HTMLInputElement>event.target).value
    }
    if (event.item != null) {
      search = event.item
    }

    this.searchDebouncer.next(search)
  }

  onClickAction(pagetitleAction: PagetitleAction) {
    this.onPagetitleAction.emit(pagetitleAction.actionKey)
  }

  get isBlackDesign(): boolean {
    const themeDesign = document.body.getAttribute("data-bs-theme");
    if (themeDesign != null) {
      return themeDesign.includes('black');
    }
    return false;
  }

  backgroundHelperOnScrollBecauseOfGradientBackgroundDueToBlackDesign() {
    if (!this.isBlackDesign) {
      return
    }
    const HTML = document.documentElement;
    const getMaxScroll = () => HTML.scrollHeight - window.innerHeight;

    const getCurrentScroll = () => HTML.scrollTop;

    const calcRotation = (pos: any, max: any) => {
      return pos / max * 100;
    };

    const backgroundColorMix = (ratio: any) => {
      return `color-mix(in srgb, #1c1c1c ${ratio}%, #292827);`;
    };

    document.addEventListener('scroll', () => {
      const bg = Math.floor(calcRotation(getCurrentScroll(), getMaxScroll()));
      const element = document.getElementById('page-title-div');
      if (element != null) {
        element.setAttribute('style','background-color: ' + backgroundColorMix(bg));
      }
    });
  }

}
