import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {ItemListComponent} from "../../../shared/item-list/item-list.component";
import {ItemManager} from "../../../shared/item-list/ItemManager";
import {
  ItemSaveAction,
  ListEditAction, ListViewAction,
  MultiDeleteAction,
  MultiListAction
} from "../../../shared/models/Actions";
import {ItemListFilter, ItemListItem, ItemListMapper} from "../../../shared/item-list/ItemListItem";
import {ApiService} from "../../../core/services/api-service/api.service";
import {TranslateService} from "@ngx-translate/core";
import {MultiListActionEvent} from "../../../shared/models/MultiListActionEvent";
import {ListActionEvent} from "../../../shared/models/ListActionEvent";
import {ItemActionEvent} from "../../../shared/models/ItemActionEvent";
import {SubmitUtils} from "../../../shared/util/SubmitUtils";
import {PendingChangesBlocker} from "../../../core/guards/pending-changes-view.guard";
import {UserGroupFormComponent} from "./user-group-forms/user-group-form/user-group-form.component";
import {
  UserGroupAssignmentFormComponent
} from "./user-group-forms/user-group-assignment-form/user-group-assignment-form.component";
import {
  UserGroupAccessProfileFormComponent
} from "./user-group-forms/user-group-access-profile-form/user-group-access-profile-form.component";
import {NgbNavChangeEvent} from "@ng-bootstrap/ng-bootstrap";
import {firstValueFrom} from "rxjs";
import {AccessProfileDto} from "../../../shared/entities/accessProfile/AccessProfileDto";
import {UserDto} from "../../../shared/entities/user/UserDto";
import {UserGroupDto} from "../../../shared/entities/user-group/UserGroupDto";
import {UserGroupDetailsDto} from "../../../shared/entities/user-group/UserGroupDetailsDto";
import {UserGroupAssignmentDto} from "../../../shared/entities/user-group/UserGroupAssignmentDto";
import {UserGroupAccessProfileDto} from "../../../shared/entities/user-group/UserGroupAccessProfileDto";
import {ToastService} from "../../../shared/notification/toast/toast.service";

@Component({
  selector: 'app-user-groups',
  templateUrl: './user-groups.component.html',
  styleUrls: ['./user-groups.component.scss']
})
export class UserGroupsComponent implements OnInit,ItemListMapper<UserGroupDto>, PendingChangesBlocker {
  _isLoading: boolean = false;
  get isLoading(): boolean {
    return this._isLoading;
  }

  @ViewChild(ItemListComponent) appItemList!: ItemListComponent<UserGroupDto>

  @Input() set onSearch(search: string) {
    this.searchQuery = search
    this.searchFilter.triggerItemUpdate()
  }

  @Output() onUpdate = new EventEmitter<void>();

  itemManager: ItemManager<UserGroupDto>
  selectedItem: UserGroupDetailsDto | undefined;
  itemSelected: boolean = false
  accessReadonly: boolean = true
  canChangeUserGroupAssignmentSettings: boolean = false
  canChangeUserGroupAccessSettings: boolean = false

  // Actions
  saveAction = new ItemSaveAction()

  multiSelectActions: MultiListAction[] = []
  multiDeleteAction = new MultiDeleteAction()

  // Data
  accessProfiles: AccessProfileDto[] = [];
  users: UserDto[] = [];

  // Search
  typeaheadIndex = new Set<string>()

  get searchEntries() {
    return [...this.typeaheadIndex.values()]
  }

  searchQuery: string = ""
  searchFilter = new ItemListFilter<UserGroupDto>(item => {
    return String(`${item.name}`).toLowerCase().includes(this.searchQuery.toLowerCase())
  })

  // Tabs
  selectedNavTab: 'group' | 'users' | 'accessProfiles' = 'group'

  pendingFormChanges = false

  // Forms
  @ViewChild(UserGroupFormComponent) userGroupForm!: UserGroupFormComponent
  @ViewChild(UserGroupAssignmentFormComponent) userGroupAssignmentForm!: UserGroupAssignmentFormComponent
  @ViewChild(UserGroupAccessProfileFormComponent) userGroupAccessProfileForm!: UserGroupAccessProfileFormComponent

  constructor(
    private apiService: ApiService,
    private notification: ToastService,
    private translate: TranslateService
  ) {
    this.saveAction.disabled = true;
    this.itemManager = new ItemManager<UserGroupDto>(this, notification);
  }

  async ngOnInit() {
    this._isLoading = true;
    this.accessReadonly = this.getAccessReadonly();
    this.canChangeUserGroupAssignmentSettings = this.getCanChangeUserGroupAssignmentSettings();
    this.canChangeUserGroupAccessSettings = this.getCanChangeUserGroupAccessSettings();
    await this.loadItemsAndBuildTypeahead();

    if(this.apiService.userGroup.canDelete) {
      this.multiSelectActions.push(this.multiDeleteAction);
    }

    this._isLoading = false;
  }

  async loadItemsAndBuildTypeahead() {
    this.itemManager.setItems(await firstValueFrom(await this.apiService.userGroup.getAll()))

    this.typeaheadIndex.clear()
    this.itemManager.forEach(value => {
      this.typeaheadIndex.add(`${value.item.name}`)
    })

    this.onUpdate.emit()
  }

  getAccessReadonly(): boolean {
    return this.apiService.userGroup.accessIsReadOnly;
  }

  getCanChangeUserGroupAssignmentSettings(): boolean {
    return this.apiService.userGroup.canChange;
  }

  getCanChangeUserGroupAccessSettings(): boolean {
    return this.apiService.userGroup.canChange;
  }


  // Interfaces
  checkPendingChanges() {
    const hasChanged =
      this.userGroupForm.changed ||
      this.userGroupAssignmentForm.changed ||
      this.userGroupAccessProfileForm.changed

    this.pendingFormChanges = hasChanged
    this.saveAction.disabled = !hasChanged

    this.onUpdate.emit()
  }

  hasPendingChanges(): boolean {
    return this.pendingFormChanges
  }

  mapToItemList(item: UserGroupDto): ItemListItem<UserGroupDto> {

    let listItem = new ItemListItem<UserGroupDto>(item.uuid, item.name, item)
    listItem.addInfo(item.description || '');
    const themeDesign = document.body.getAttribute("data-bs-theme");
    if (themeDesign != null && themeDesign.includes('black')) {
      listItem.setImage("assets/ces/user/user-group-black.svg")
    } else {
      listItem.setImage("assets/ces/user/user-group.svg")
    }
    listItem.addAction(this.accessReadonly ? new ListViewAction() : new ListEditAction())

    return listItem
  }

  async onMultiDeleteAction(event: MultiListActionEvent<UserGroupDto>) {
    let allDeleted: boolean = true;
    for (const item of event.items) {
     if(!(await firstValueFrom(await this.apiService.userGroup.delete(item.uuid))).ok) {
       this.notification.showError('NOTIFICATION.TOAST.CES_ERROR.cesErrExecution');
       allDeleted = false;
       break;
     }
    }
    if (allDeleted) {
      await this.loadItemsAndBuildTypeahead();
      this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.DELETE');
    }
  }

  async onAddUserGroup() {
    const group: UserGroupDto = UserGroupDto.emptyUserGroupDto();
    // @ts-ignore
    this.selectedItem = group;
    this.onUpdate.emit();

    const listItem = new ItemListItem<UserGroupDto>(group.uuid, this.translate.instant("USERS.GROUPS.ACTION.NEW_USER_GROUP_NAME"), group)

    this.appItemList.setSelectedListItem(listItem, new ItemSaveAction());
    await this.setAccessGroups();
  }

  onResetView() {
    this.selectedNavTab = 'group'

    this.selectedItem = undefined
    this.pendingFormChanges = false;

    this.onUpdate.emit()
  }

  async onSelectEvent(actionEvent: ListActionEvent<UserGroupDto>) {
    if (actionEvent == undefined) return

    this.selectedItem = await firstValueFrom(await this.apiService.userGroup.getSpecific(actionEvent.item.uuid));
    this.onUpdate.emit();

    if(!this.apiService.userGroup.accessIsReadOnly) {
      actionEvent.addItemAction(this.saveAction);
      await this.setAccessGroups();
    }
  }

  async onEditEvent(actionEvent: ItemActionEvent<UserGroupDto>) {
    if (actionEvent == undefined) return;

    if (actionEvent.action instanceof ItemSaveAction) {
      await this.onSubmit(actionEvent.returnToList)
    }
  }

  private async setAccessGroups() {
    this._isLoading = true;
    this.users = await firstValueFrom(await this.apiService.user.getAll()) as UserDto[];
    this.accessProfiles = await firstValueFrom(await this.apiService.accessProfile.getAll());
    this._isLoading = false;
  }

  async onSubmit(returnToList: () => void) {

    if (SubmitUtils.checkControls(this.notification, true, this.userGroupForm.groupForm)) {
      return
    }

    let userGroupDto: UserGroupDto = this.userGroupForm.getUserGroupDto();
    userGroupDto.users = this.userGroupAssignmentForm.getSelectedUsers();
    userGroupDto.accessProfiles = this.userGroupAccessProfileForm.getSelectedAccessProfilesUuids();
    // add or update userGroup
    if (this.userGroupForm.changed || this.userGroupAssignmentForm.changed || this.userGroupAccessProfileForm.changed) {
      if (userGroupDto.id > 0) {
        userGroupDto = await firstValueFrom(await this.apiService.userGroup.update(userGroupDto));
      } else {
        userGroupDto = await firstValueFrom(await this.apiService.userGroup.add(userGroupDto));
      }
    }

    if (userGroupDto == null) {
      return;
    }

    this.itemManager.updateItem(userGroupDto, true);

    returnToList()
    this.onResetView()
  }

  get userGroupAssignments(): UserGroupAssignmentDto[] {
    return this.selectedItem?.users || [];
  }

  get userGroupAccessProfiles(): UserGroupAccessProfileDto[] {
    return (this.selectedItem?.accessProfiles || []);
  }

  async onNavigation(event: NgbNavChangeEvent) {
    if (event.nextId == 'users') {

    } else if (event.nextId == 'accessProfiles') {

    }
  }
}
