import {Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';

import {SelectListComponent} from "../../../../../shared/select-list/select-list.component";
import {SelectListItem} from "../../../../../shared/select-list/SelectListItem";
import {ApiService} from "../../../../../core/services/api-service/api.service";
import {TranslateService} from "@ngx-translate/core";
import {AccessProfileDto} from "../../../../../shared/entities/accessProfile/AccessProfileDto";
import {UserGroupAccessProfileDto} from "../../../../../shared/entities/user-group/UserGroupAccessProfileDto";

@Component({
  selector: 'app-user-group-access-profile-form',
  templateUrl: './user-group-access-profile-form.component.html',
  styleUrls: ['./user-group-access-profile-form.component.scss']
})
export class UserGroupAccessProfileFormComponent {

  @ViewChild(SelectListComponent) selectListComponent!: SelectListComponent

  items: SelectListItem[] = [];
  selectedItems = new Set<string>()

  @Input() readonly = false

  @Input() set accessProfiles(accessProfiles: AccessProfileDto[]) {
    this.items = (accessProfiles || []).map<SelectListItem>((entry: AccessProfileDto) => {
      return new SelectListItem(entry.uuid, entry.name)
        .addInfo(this.translate.instant("ACCESS_PROFILES.LIST.TIME_MODEL_COUNT", {count: entry.timeModelCount || 0}))
        .addInfo(this.translate.instant("ACCESS_PROFILES.LIST.LOCKING_DEVICE_COUNT", {lockingDevices: entry.lockingDeviceCount || 0, groups: entry.groupLockingDeviceCount || 0}))
        .setImage(`assets/ces/access-profiles/${this.apiService.accessProfile.getImageNameForAccessProfileType(entry.accessTypeId)}.svg`)
    })
  }

  @Input() set assignedAccessProfiles(assignedAccessProfiles: UserGroupAccessProfileDto[]) {
    this.selectedItems = assignedAccessProfiles.reduce<Set<string>>((set, entry) => {
      set.add(entry.uuid);
      return set;
    }, new Set<string>());
  }

  // Changes
  _changed = false
  @Output() onChanges = new EventEmitter<void>()

  constructor(
    private apiService: ApiService,
    private translate: TranslateService
  ) { }

  onSelectionChanges(changed: boolean) {
    this._changed = changed
    this.onChanges.emit()
  }

  get changed(): boolean {
    return this._changed
  }

  getSelectedAccessProfilesUuids(): string[] {
    return Array.from(this.selectListComponent.selectedItems);
  }

  reset() {
    this.items = [];
    this.selectedItems.clear()
    this.selectListComponent.resetChangeDetection()
  }

}
