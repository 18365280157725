export class LogbookDto {
  // title not part of response
  title: string | undefined;
  uuid: string;
  eventTypeId: number;
  eventId: number;
  logbookSummary: string;
  timestamp: number;

  constructor(uuid: string, eventTypeId: number, eventId: number, logbookSummary: string, timestamp: number) {
    this.uuid = uuid;
    this.eventTypeId = eventTypeId;
    this.eventId = eventId;
    this.logbookSummary = logbookSummary;
    this.timestamp = timestamp;
  }

  static emptyLogbookDto(): LogbookDto {
    return new LogbookDto("", 0, 1, "",0);
  }

}
