import {LookupMapping} from "./lookup";

export const ACCESS_TYPE: LookupMapping[] = [
  {
    "id": 1,
    "value": "denied"
  },
  {
    "id": 2,
    "value": "allowed"
  }
];

export const ACCESS_MODE: LookupMapping[] = [
  {
    "id": 1,
    "value": "one_open"
  },
  {
    "id": 2,
    "value": "all_open"
  },
  {
    "id": 3,
    "value": "one_open_plus"
  }
];
