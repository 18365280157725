<div [loadingCes]="loading" id="content">
  <div class="modal-header border-bottom-0">
    <h4 class="modal-title" id="modal-basic-title">{{ "USERMENU.CHANGE_PASSWORD.TITLE" | translate }}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="onClose()"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="changePasswordForm">

      <p class="text-muted">{{ "AUTH.CHANGE_PASSWORD.INFO" | translate }}</p>

      <div class="form-floating form-floating-custom mb-4">
        <input type="password" class="form-control" id="old-password"
               formControlName="oldPassword" placeholder="-" viewablePassword
               [ngClass]="{ 'is-invalid': f['oldPassword'].hasError('required') && f['oldPassword'].touched }">
        <label for="old-password">{{ "AUTH.FIELD.PASSWORD.TITLE" | translate }}</label>
        <div class="form-floating-icon">
          <i icon="password-old"></i>
        </div>
        <div *ngIf="f['oldPassword'].hasError('required') && f['oldPassword'].touched " class="invalid-feedback">
          <div *ngIf="f['oldPassword'].hasError('required') && f['oldPassword'].touched ">{{ "AUTH.FIELD.PASSWORD.FORM.REQUIRED" | translate }}</div>
        </div>
      </div>

      <p class="text-muted">{{ "AUTH.CHANGE_PASSWORD.INFO_SECTION" | translate }}</p>

      <div class="form-floating form-floating-custom mb-3">
        <input type="password" class="form-control" id="new-password" compare="oldPassword" [matching]="false"
               formControlName="newPassword" placeholder="-" viewablePassword
               [ngClass]="{ 'is-invalid': f['newPassword'] | hasErrors }">
        <label for="new-password">{{ "AUTH.FIELD.NEW_PASSWORD.TITLE" | translate }}</label>
        <div class="form-floating-icon">
          <i icon="password"></i>
        </div>
        <div *ngIf="f['newPassword'] | hasErrors" class="invalid-feedback">
          <span *ngIf="f['newPassword'] | hasErrors:'required'">{{ "AUTH.FIELD.NEW_PASSWORD.FORM.REQUIRED" | translate }}</span>
          <span *ngIf="f['newPassword'] | hasErrors:'mustNotMatch'">{{ "AUTH.FIELD.NEW_PASSWORD.FORM.INVALID" | translate }}</span>
        </div>
      </div>

      <div class="form-floating form-floating-custom mb-3">
        <input type="password" class="form-control" id="confirm-password" compare="newPassword"
               formControlName="confirmPassword" placeholder="-" viewablePassword
               [ngClass]="{ 'is-invalid': f['confirmPassword'] | hasErrors }">
        <label for="confirm-password">{{ "AUTH.FIELD.CONFIRM_PASSWORD.TITLE" | translate }}</label>
        <div class="form-floating-icon">
          <i icon="password-confirm"></i>
        </div>
        <div *ngIf="f['confirmPassword'] | hasErrors" class="invalid-feedback">
          <span *ngIf="f['confirmPassword'] | hasErrors:'required'">{{ "AUTH.FIELD.CONFIRM_PASSWORD.FORM.REQUIRED" | translate }}</span>
          <span *ngIf="f['confirmPassword'] | hasErrors:'mustMatch'">{{ "AUTH.FIELD.CONFIRM_PASSWORD.FORM.INVALID" | translate }}</span>
        </div>
      </div>
    </form>

    <app-password-complexity style="text-align: center;"
      [showHeadline]="true"
      [formControlName]="'newPassword'"
      [changePasswordForm]="changePasswordForm">
    </app-password-complexity>

  </div>
  <div class="modal-footer border-top-0">
    <button type="button" class="btn text-primary btn-outline-light" (click)="onSubmit()">{{ "USERMENU.CHANGE_PASSWORD.TITLE" | translate }}</button>
    <button type="button" class="btn text-secondary btn-outline-light" (click)="onClose()">{{ "NOTIFICATION.ALERT.BUTTON.CANCEL" | translate }}</button>
  </div>
</div>
