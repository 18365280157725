import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsVisibleDirective } from './directives/is-visible.directive';
import { IconDirective } from './directives/icon.directive';
import { TrustPipe } from './pipes/trust.pipe';
import { DateTimePipe } from './pipes/date-time.pipe';
import { HasErrorsPipe } from './pipes/has-errors.pipe';
import { DefaultOrderAndSearchKeyValuePipe } from './pipes/default-order-key-value.pipe';
import { CesDefaultImageDirective } from './directives/ces-default-image.directive';
import { SwitchDisabledDirective } from './directives/switch-disabled.directive';
import { ValueFormatterPipe } from './pipes/value-formatter.pipe';
import { ErrorTranslatePipe } from './pipes/error-translate.pipe';
import { NonNullAsyncPipe } from './pipes/non-null-async.pipe';
import {SkeletonDirective} from "./directives/skeleton-directive";
import {LoadingDirective} from "./directives/loading-directive";
import {DateTimeRecurringPipe} from "./pipes/date-time-recurring.pipe";

@NgModule({
  declarations: [
    IsVisibleDirective,
    SkeletonDirective,
    LoadingDirective,
    IconDirective,
    TrustPipe,
    DateTimePipe,
    DateTimeRecurringPipe,
    DefaultOrderAndSearchKeyValuePipe,
    HasErrorsPipe,
    CesDefaultImageDirective,
    SwitchDisabledDirective,
    ValueFormatterPipe,
    ErrorTranslatePipe,
    NonNullAsyncPipe
  ],
  exports: [
    IsVisibleDirective,
    IconDirective,
    DateTimePipe,
    DateTimeRecurringPipe,
    DefaultOrderAndSearchKeyValuePipe,
    HasErrorsPipe,
    TrustPipe,
    CesDefaultImageDirective,
    SwitchDisabledDirective,
    ValueFormatterPipe,
    ErrorTranslatePipe,
    SkeletonDirective,
    LoadingDirective,
    NonNullAsyncPipe
  ],
  imports: [
    CommonModule
  ],
})
export class CoreModule {}
