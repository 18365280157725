import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild
} from '@angular/core';

import {SelectListItem} from "../../../../shared/select-list/SelectListItem";
import {ApiService} from "../../../../core/services/api-service/api.service";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {FormBuilder, FormGroup} from "@angular/forms";
import {take} from "rxjs";
import {ChangeDetectorValue} from "../../../../shared/util/change-detector/ChangeDetectorValue";

export interface AccordionItem {
  title: string;
  selectedItems: Set<string>;
  count?: number;
  map: Map<string, SelectListItem>;
  button: string,
  id: string;
}

export interface ItemsDetectorValue {
  selectedItems: Set<string>;
  allItemsMap: Map<string, SelectListItem>;
}

@Component({
  selector: 'app-access-profile-locking-device-form',
  templateUrl: './access-profile-locking-device-form.component.html',
  styleUrls: ['./access-profile-locking-device-form.component.scss'],
})
export class AccessProfileLockingDeviceFormComponent implements OnInit {

  @ViewChild("itemSelector") itemSelector?: ElementRef;
  protected devices: ChangeDetectorValue = new ChangeDetectorValue({
    selectedItems: new Set<string>(),
    allItemsMap: new Map<string, SelectListItem>()
  } as ItemsDetectorValue);
  protected groups: ChangeDetectorValue = new ChangeDetectorValue({
    selectedItems: new Set<string>(),
    allItemsMap: new Map<string, SelectListItem>()
  } as ItemsDetectorValue);


  // Search
  searchForm!: FormGroup;
  searchText: string = '';

  _items: AccordionItem[] = [];
  _modalItems: AccordionItem[] = [];

  // add and multiselect
  addSelection = new Set<string>();

  get addSelectionGetter() {
    return this.addSelection;
  }

  removeSelection = new Set<string>();

  get removeSelectionGetter() {
    return this.removeSelection;
  }

  // License
  @Input() licenseTypeId: number = 1
  @Input() accessReadonly: boolean = true;

  get accessReadonlyGetter(): boolean {
    return this.accessReadonly || this.licenseNotValid;
  }

  @Input() licenseNotValid: boolean = false;

  @Input() set assigned(value: ChangeDetectorValue) {
    if (value == undefined || value.value == undefined) {
      return;
    }

    let allDeviceItems: Map<string, SelectListItem> = new Map<string, SelectListItem>();
    let allGroupItems: Map<string, SelectListItem> = new Map<string, SelectListItem>();

    let selectedDevices: Set<string> = new Set((value.value?.lockingDevices || [])
      .map((d: { uuid: string; }) => d.uuid as string)
      .sort((a: string, b: string) => a.localeCompare(b)));
    let selectedGroups: Set<string> = new Set((value.value?.lockingDeviceGroups || [])
      .map((d: { uuid: string; }) => d.uuid)
      .sort((a: string, b: string) => a.localeCompare(b)));

    this.apiService.accessProfile.getAllAssignableLockingDevicesAndGroups(value.value?.uuid).then(
      observe => {
        observe.pipe(take(1)).subscribe({
          next: response => {
            // Setting Devices
            [...(response?.lockingDevices || []), ...(value.value?.lockingDevices || [])].forEach((entry: any) => {
              const typeId: number = entry.typeId ? entry.typeId : entry.deviceTypeId ? entry.deviceTypeId : 1;
              const item = new SelectListItem(entry.uuid, entry.name)
                .setImage(`assets/ces/locking-device/${this.apiService.lockingDevice.getImageNameForDeviceType(typeId)}.svg`);
              allDeviceItems.set(entry.uuid, item);
            });
            // Setting Groups
            [...(response?.lockingDeviceGroups || []), ...(value.value?.lockingDeviceGroups || [])].forEach((entry: any) => {
              const item = new SelectListItem(entry.uuid, entry.name)
                .setImage(`assets/ces/locking-device/group/locking-device-access-group.svg`);
              allGroupItems.set(entry.uuid, item);
            });
          },
          complete: () => {
            // Setting Devices
            this.devices = new ChangeDetectorValue({
              selectedItems: selectedDevices,
              allItemsMap: allDeviceItems
            } as ItemsDetectorValue);
            // Setting Groups
            this.groups = new ChangeDetectorValue({
              selectedItems: selectedGroups,
              allItemsMap: allGroupItems
            } as ItemsDetectorValue);
            this._items = this.items;
          }
        });
      }
    );
  }

  constructor(
    private apiService: ApiService,
    private modalService: NgbModal,
    private formBuilder: FormBuilder
  ) {
  }

  async ngOnInit() {
    this.searchForm = this.formBuilder.group({
      searchText: ['', []]
    });
    this._items = this.items;
  }

  updateSearch(event?: any) {
    let search = this.searchForm.get("searchText")?.value || '';
    if (event instanceof InputEvent) {
      search = (<HTMLInputElement>event.target).value;
    }
    if (event.item != null) {
      search = event.item;
    }
    this.searchText = search;
  }

  sizeWithSearchFilter(elements: Map<string, SelectListItem>, elementsToRemove: Map<string, SelectListItem> | undefined): number {
    (elementsToRemove || new Map<string, SelectListItem>).forEach((value, key) => {
      if (elements.has(key)) {
        elements.delete(key);
      }
    });
    return [...elements.entries()].filter(([_key, value]) => {
        return value.name.toLowerCase().includes(this.searchText.toLowerCase());
      }
    ).length
  }

  // Remove-selection
  onRemoveSelectionChange(event: any, key: string) {
    if (this.accessReadonlyGetter) return;
    if (event?.currentTarget?.checked || event == true) {
      this.removeSelection.add(key)
    } else {
      this.removeSelection.delete(key)
    }
  }

  onRemoveSelectionChangeAll(event: any) {
    if (this.accessReadonlyGetter) return;
    if (event?.currentTarget?.checked) {
      (this.devices.value as ItemsDetectorValue).selectedItems.forEach(item => {
        this.removeSelection.add(item);
      });
      (this.groups.value as ItemsDetectorValue).selectedItems.forEach(item => {
        this.removeSelection.add(item);
      });
    } else {
      this.removeSelection.clear();
    }
  }

  onRemoveSelected() {
    if (this.accessReadonlyGetter) return;
    this.removeSelection.forEach((key => {
      if ((this.devices.value as ItemsDetectorValue).selectedItems.has(key)) {
        (this.devices.value as ItemsDetectorValue).selectedItems.delete(key);
      }
      if ((this.groups.value as ItemsDetectorValue).selectedItems.has(key)) {
        (this.groups.value as ItemsDetectorValue).selectedItems.delete(key);
      }
    }));
    this.removeSelection.clear();
    this.sortSets();
    this._items = this.items;
  }

  // Add-selection
  onAddSelectionChange(event: any, key: string) {
    if (this.accessReadonlyGetter) return;
    if (event?.currentTarget?.checked || event == true) {
      this.addSelection.add(key)
    } else {
      this.addSelection.delete(key)
    }
  }

  onAddSelected() {
    if (this.accessReadonlyGetter) return;
    this.addSelection.forEach((key => {
      if ((this.devices.value as ItemsDetectorValue).allItemsMap.has(key)) {
        (this.devices.value as ItemsDetectorValue).selectedItems.add(key);
      }
      if ((this.groups.value as ItemsDetectorValue).allItemsMap.has(key)) {
        (this.groups.value as ItemsDetectorValue).selectedItems.add(key);
      }
    }));
    this.addSelection.clear();

    this.sortSets();
    this._items = this.items;
  }

  isLast(i: number): boolean {
    return i == this._items.length - 1 && i != 0;
  };

  notSelected(input: ItemsDetectorValue): Set<string> {
    let result: Set<string> = new Set<string>();
    input.allItemsMap.forEach((value, key) => {
      if (!(input.selectedItems as Set<string>).has(key)) {
        result.add(key);
      }
    });
    return result;
  }

  // Select Popup
  openItemSelector() {
    if (this.accessReadonlyGetter) return;
    this._modalItems = this.modalItems;
    this.modalService.open(this.itemSelector, {centered: true, scrollable: true});
  }

  reset() {
    this.devices.reset();
    this.groups.reset();
  }

  private sortSets() {
    this.devices.value.selectedItems = new Set(Array.from((this.devices.value as ItemsDetectorValue).selectedItems).sort((a, b) => a.localeCompare(b)));
    this.groups.value.selectedItems = new Set(Array.from((this.groups.value as ItemsDetectorValue).selectedItems).sort((a, b) => a.localeCompare(b)));
  }

  get items(): AccordionItem[] {
    const deviceItem: AccordionItem = {
      id: 'devices-item',
      title: 'ACCESS_PROFILES.SELECT_DEVICES.ACCORDION.DEVICES',
      button: 'ACCESS_PROFILES.SELECT_DEVICES.NO_LOCKING_DEVICES_SELECTED',
      selectedItems: new Set((this.devices.value as ItemsDetectorValue).selectedItems),
      map: (this.devices.value as ItemsDetectorValue).allItemsMap,
    };
    if (this.licenseTypeId > 1) {
      return [{
        id: 'groups-item',
        title: 'ACCESS_PROFILES.SELECT_DEVICES.ACCORDION.GROUPS',
        button: 'ACCESS_PROFILES.SELECT_DEVICES.NO_LOCKING_DEVICE_GROUPS_SELECTED',
        selectedItems: new Set(this.groups.value.selectedItems),
        map: (this.groups.value as ItemsDetectorValue).allItemsMap,
      }, deviceItem];
    }
    return [deviceItem];
  }

  get modalItems(): AccordionItem[] {
    const deviceItem: AccordionItem = {
      id: 'devices-modal-item',
      title: 'ACCESS_PROFILES.SELECT_DEVICES.ACCORDION.DEVICES',
      button: 'ACCESS_PROFILES.SELECT_DEVICES.NO_LOCKING_DEVICES',
      selectedItems: this.notSelected(this.devices.value),
      map: (this.devices.value as ItemsDetectorValue).allItemsMap,
    };
    if (this.licenseTypeId > 1) {
      return [{
        id: 'groups-item',
        title: 'ACCESS_PROFILES.SELECT_DEVICES.ACCORDION.GROUPS',
        button: 'ACCESS_PROFILES.SELECT_DEVICES.NO_LOCKING_DEVICE_GROUPS',
        selectedItems: this.notSelected(this.groups.value),
        map: (this.groups.value as ItemsDetectorValue).allItemsMap,
      }, deviceItem];
    }
    return [deviceItem];
  }

  get changed(): boolean {
    return this.devices.hasChanges || this.groups.hasChanges;
  }

  get selectedUuids(): { device: Array<string>, groups: Array<string> } {
    return {
      device: Array.from((this.devices.value as ItemsDetectorValue).selectedItems),
      groups: Array.from((this.groups.value as ItemsDetectorValue).selectedItems),
    };
  }
}
