import {LookupMapping} from "./lookup";

export const VARIANT: LookupMapping[] = [
  {
    "id": 1,
    "value": "offline"
  },
  {
    "id": 2,
    "value": "online"
  }
]
