import {LockingDeviceParamsDto} from "./LockingDeviceParamsDto";
import {UuidName} from "../Basic";

export class LockingDeviceGroupDto extends UuidName {
  id: number;
  description: string | undefined;
  groupTypeId: number;
  params: LockingDeviceParamsDto | undefined;
  devices: string[];

  constructor(uuid: string, id: number, name: string, description: string | undefined, groupTypeId: number, params: LockingDeviceParamsDto | undefined, devices: string[]) {
    super(uuid, name);
    this.id = id;
    this.description = description;
    this.groupTypeId = groupTypeId;
    this.devices = devices;
    this.params = params;
  }

}
