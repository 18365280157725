import { Pipe, PipeTransform } from '@angular/core';
import {FormatterService} from "../services/formatter-service/formatter.service";

@Pipe({
  name: 'dateTime'
})
export class DateTimePipe implements PipeTransform {

  constructor(private formatter: FormatterService) {
  }

  transform(
    value: number | string,
    options: {format?: 'time' | 'date' | 'dateTime', replaceInvalid?: string, fromPicker?: boolean} = {format: 'dateTime', replaceInvalid: '', fromPicker: false}
  ): String {

    if(typeof value == 'object') {
      value = `${value}`
    }

    let timestamp = 0
    if(typeof value == "number") {
      timestamp = value
    }
    if(typeof value == "string") {
      timestamp = options?.fromPicker? this.formatter.fromPicker(value) : parseInt(value)
    }

    return this.formatter.formatTimestamp(
      timestamp,
      {mode: options?.format || 'dateTime', replaceInvalid: options?.replaceInvalid}
    );
  }

}
