import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {IoBridgeProcess} from "../../../../shared/util/bindingStates";
import {TranslateService} from "@ngx-translate/core";
import {IoBridgeService} from "../../../../core/services/io-bridge-service/io-bridge.service";
import {debounceTime} from "rxjs";
import {ModalCloseCause} from "../../../../core/enums/modalCloseCause";
import {IoBridgeFlow} from "../../../../core/enums/ioBridgeFlow";
import {SessionManager} from "../../../../core/services/auth-service/support-services/SessionManager";
import { HttpClient } from "@angular/common/http";
import {CommissionManager} from "../../../../core/services/auth-service/support-services/CommissionManager";
import {BindingProcessError} from "../../../../shared/entities/IOB/BindingProcessError";
import {ToastService} from "../../../../shared/notification/toast/toast.service";
import {ModalService} from "../../../../shared/notification/modal/modal.service";
import {ModalContainerContent} from "../../../../shared/notification/modal/modal-container.class";

@Component({
  selector: 'locking-device-commissioning',
  templateUrl: './locking-device-commissioning.component.html',
  styleUrls: ['./locking-device-commissioning.component.scss']
})
export class LockingDeviceCommissioningComponent implements OnInit, OnDestroy {
  private commissionManager: CommissionManager = CommissionManager.getInstance();

  private globalBindingErrorProcess: BindingProcessError;
  private _isLoading: boolean = false;

  providePin: string = '';
  provideSN: string = '';
  deviceName: string = '';
  processAndPollingRunning: boolean = false;
  startButtonClicked: boolean = false;

  constructor(private ngbModal: NgbActiveModal,
              private translate: TranslateService,
              private notification: ToastService,
              private alertService: ModalService,
              private ioBridgeService: IoBridgeService,
              protected ioBridgeProcess: IoBridgeProcess,
              private httpClient: HttpClient) {
    this.globalBindingErrorProcess = this.commissionManager.bindingProcessError;
    this.commissionManager.bindingProcessErrorObservable.pipe(debounceTime(500)).subscribe({
      next: value => this.globalBindingErrorProcess = value
    });
  }

  ngOnInit(): void {
    this.providePin = this.commissionManager.bindingProcess.pin;
    this.provideSN = this.commissionManager.bindingProcess.serialNumber;
    this.deviceName = this.commissionManager.bindingProcess.deviceName;

    if (!this.globalBindingErrorProcess.blockTillReset) {
      this._isLoading = true;
      this.ioBridgeService.iobConnectionUpdater().then(() => {
        this._isLoading = false;
      });
    }
  }

  ngOnDestroy() {
    if (this.commissionManager.bindingProcessState.order == 0 && !this.globalBindingErrorProcess.blockTillReset) {
      this.commissionManager.reset();
    }
    this.ioBridgeService.iobConnectionUpdater();
  }

  get currentOrderNumber(): number {
    return this.ioBridgeProcess.getStateByName(this.commissionManager.bindingProcessState.name).order;
  }

  async startProcessIOBridge() {
    this.startButtonClicked = true;

    switch (this.commissionManager.bindingProcess.flow) {
      case IoBridgeFlow.UNBIND:
      case 1:
        await this.unbind();
        break;
      case IoBridgeFlow.REBIND:
      case 2:
        await this.rebind();
        break;
      case IoBridgeFlow.BIND:
      case 0:
        await this.bind();
        break;
      case IoBridgeFlow.UNKNOWN:
      default:
        this.processAndPollingRunning = false;
        throw Error("Unknown flow");
    }
    this.startButtonClicked = false;
  }

  private async prepare() {
    this.commissionManager.bindingProcess.serialNumber = this.provideSN;
    this.commissionManager.bindingProcess.pin = this.providePin;

    await SessionManager.getInstance().forceNewToken(this.httpClient);
    await this.ioBridgeService.iobConnectionUpdater();

    this.processAndPollingRunning = true;
  }

  private async pollFetch() {
    for (; this.processAndPollingRunning && !this.commissionManager.bindingProcessError.blockTillReset;) {
      this.ioBridgeService.iobConnectionUpdater().catch(() => this.processAndPollingRunning = false);
      await new Promise(f => setTimeout(f, 500));
    }
  }

  private async bind() {
    await this.prepare();
    this.pollFetch().catch(() => this.processAndPollingRunning = false);
    const bind: Response = await this.ioBridgeService.fetchBinding(this.commissionManager.bindingProcess.pin, this.provideSN);
    if (bind.ok) {
      let body: any = undefined;
      if (bind.status != 204 && bind.bodyUsed) {
        body = await bind.json();
      }
      await this.finishSuccessAndClose((body == undefined || body.uuid == undefined) ? (ModalCloseCause.SUCCESS || 3) : body);
    } else {
      await this.finishError(bind);
    }
  }

  private async rebind() {
    await this.prepare();
    this.pollFetch().catch(() => this.processAndPollingRunning = false);
    const rebind: Response = await this.ioBridgeService.fetchRebinding(this.commissionManager.bindingProcess.pin, this.provideSN, this.commissionManager.bindingProcess.deviceUuid);
    if (rebind.ok) {
      await this.finishSuccessAndClose(this.provideSN);
    } else {
      await this.finishError(rebind);
    }
  }

  private async unbind() {
    const modalContent: ModalContainerContent = new ModalContainerContent(
      this.translate.instant("LOCKING_DEVICES.DEVICES.ITEM_LIST.UNBIND.TITLE"),
      this.translate.instant('LOCKING_DEVICES.DEVICES.ITEM_LIST.UNBIND.MESSAGE', {name: this.commissionManager.bindingProcess.deviceName})
    );
    if (await this.alertService.openModal(modalContent)) {
      await this.prepare();
      this.pollFetch().catch(() => this.processAndPollingRunning = false);
      const unbind: Response = await this.ioBridgeService.fetchUnbinding(this.commissionManager.bindingProcess.deviceUuid!);
      if (unbind.ok) {
        await this.finishSuccessAndClose(ModalCloseCause.SUCCESS || 3);
      } else {
        await this.finishError(unbind);
      }
    }
  }

  private async finishError(error: Response) {
    this.processAndPollingRunning = false;

    let errorString: string = 'ErrGeneral';
    let msg: string = 'ErrGeneral';
    let errorCode: number | string = 0;

    if (error.body != null) {
      try {
        const body = await error.json();
        switch (typeof body.error) {
          case "string":
            errorString = body.error;
            msg = body.statusText;
            break;
          default:
            errorString = 'ErrGeneral';
            msg = body.statusText;
            break;
        }
        switch (typeof body.code) {
          case "string":
            errorCode = (body.code as string).replaceAll('\"', '');
            break
          case "number":
            errorCode = body.code as number;
            break;
        }
      } catch (ex) {
      }
    }
    this.commissionManager.bindingProcessError = new BindingProcessError(true, errorString, msg, errorCode);
    this.notification.showError('NOTIFICATION.TOAST.CES_ERROR.cesErrExecution');
  }

  private async finishSuccessAndClose(returnVal: any) {
    this.processAndPollingRunning = false;
    this.notification.showSuccess(`PROGRAMMING.STATES.SUCCESS`);
    this.onClose(returnVal);
    return new Promise(() => setTimeout(() => this.commissionManager.reset(), 300));
  }

  onClose(cause: ModalCloseCause) {
    this.ngbModal.close(cause);
  }

  onAbort(): void {
    this._isLoading =true;
    this.ioBridgeService.fetchResetState()
      .then(() => {
        this.ioBridgeService.iobConnectionUpdater();
        this.commissionManager.reset();
        this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.DEFAULT');
      })
      .catch(() => this.notification.showError('NOTIFICATION.TOAST.ERROR.DEFAULT'))
      .finally(() => this._isLoading = false);
  }

  // FE CNT

  get isLoading():boolean {
    return this._isLoading;
  }

  get isFlowUnbind(): boolean {
    return this.commissionManager.bindingProcess.flow == IoBridgeFlow.UNBIND;
  }

  get isFlowBind(): boolean {
    return this.commissionManager.bindingProcess.flow == IoBridgeFlow.BIND;
  }

  get isFlowRebind(): boolean {
    return this.commissionManager.bindingProcess.flow == IoBridgeFlow.REBIND;
  }

  get serialNumberInputEnabled(): boolean {
    return (!this.startButtonClicked && !this.isLoading && !this.isFlowUnbind);
  }

  get pinInputEnabled(): boolean {
    return !((this.providePin.length != 6 && !this.isFlowUnbind) ||
        (this.provideSN.length < 5 && !this.isFlowUnbind) || this.commissionManager.bindingProcessState.order! != 0)
      && !this.errorIsPresent;
  }

  get startButtonEnabled(): boolean {
    return this.commissionManager.bindingProcessState.order! == 0 &&
      !this.errorIsPresent && !this.startButtonClicked;
  }

  get error(): string {
    return this.commissionManager.bindingProcessError.error;
  }

  get state(): string {
    return this.commissionManager.bindingProcessState.translationKey;
  }

  get errorIsPresent(): boolean {
    return this.commissionManager.bindingProcessError.blockTillReset;
  }

  get errorCode(): number | string {
    return this.commissionManager.bindingProcessError.code;
  }

  protected readonly ModalCloseCause = ModalCloseCause;
}
