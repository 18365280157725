import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {RefreshTokenDto} from "../../../shared/entities/RefreshTokenDto";
import {set$notifyNewToken} from "../../../shared/util/FallbackNotifiers";

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenPollingService {
  private worker: Worker | undefined ;
  private static instance: RefreshTokenPollingService;

  static getInstance(value: RefreshTokenDto): RefreshTokenPollingService {
    if (this.instance == undefined) {
      this.instance = new RefreshTokenPollingService(value);
    } else if (value.userInfo?.token != this.instance.value.userInfo?.token) {
      this.instance.stopPolling();
      this.instance = new RefreshTokenPollingService(value);
    }
    return this.instance;
  }

  private constructor(private value: RefreshTokenDto) {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('../../worker/refreshToken.worker.ts', import.meta.url));
      this.startPolling(value);
    }
  }

  adjustExpireAt(expireAt: number) {
    this.worker?.postMessage(expireAt);
  }

  private startPolling(refresh: RefreshTokenDto) {
    new Observable(observer => {
      if (this.worker) {
        const userInfo = refresh.userInfo;
        const url = refresh.url;
        const apiKey = refresh.apiKey;
        const durationToWait: number = refresh.durationToWait;
        this.worker.postMessage({ userInfo, url, apiKey, durationToWait });

        this.worker.onmessage = ({ data }) => {
          observer.next(data);
        };

        this.worker.onerror = (error) => {
          observer.error(error);
        };
      } else {
        observer.error('Web Worker is not supported in this environment');
      }

      return () => {
        this.stopPolling();
      };
    }).subscribe(value => set$notifyNewToken(value));
  }

  stopPolling(): void {
    if (this.worker) {
      this.worker.postMessage('stop');
      this.worker = undefined;
    }
  }

}
