import {UuidShortName} from "../Basic";

export class LockingDeviceGroupAssignmentDto extends UuidShortName {
  uid: number;
  deviceTypeId: number;

  constructor(uuid: string, uid: number, shortName: string, deviceTypeId: number) {
    super(uuid, shortName);
    this.uid = uid;
    this.deviceTypeId = deviceTypeId;
  }

}
