import { Injectable } from '@angular/core';
import packageJson from '../../../../../package.json';
import {Title} from "@angular/platform-browser";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs/operators";
import {MENU} from "../../../app.menu";
import {TranslateService} from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AppService {

  get version(): string { return packageJson.version }
  private readonly menu = MENU

  constructor(
    private title: Title,
    router: Router,
    translate: TranslateService) {
    router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event) => {
      const url = (event as NavigationEnd).urlAfterRedirects

      for(let entry of this.menu) {
        if(url == entry.link) {
          this.currentPage = translate.instant(entry.label)
          return
        }
      }

      this.currentPage = ""
    });
  }

  private _appName = "CESentry Web-Client"
  set appName(appName: string) {
    this._appName = appName
    this.updateTitle()
  }

  private _currentPage = ""
  set currentPage(currentPage: string) {
    this._currentPage = currentPage
    this.updateTitle()
  }

  private updateTitle() {
    this.title.setTitle(
      this._currentPage == "" ?
        this._appName :
        `${this._appName} | ${this._currentPage}`
    )
  }

}
