import {AbstractControl} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ToastService} from "../notification/toast/toast.service";
import {ModalService} from "../notification/modal/modal.service";
import {ModalContainerContent} from "../notification/modal/modal-container.class";

export class SubmitUtils {

  // helper for submit
  /** Returns true if forms are invalid and shows message toast.
   *
   * @param notification
   * @param showWarning
   * @param controls
   */
  static checkControls(notification: ToastService, showWarning: boolean = true, ...controls: AbstractControl[]): boolean {

    let invalid = false

    controls.forEach(control => {
      if (control.invalid) {
        invalid = true
        control.markAllAsTouched()
        control.updateValueAndValidity()
      }
    })

    if (invalid && showWarning) {
      notification.showWarning(`NOTIFICATION.TOAST.WARNING.${'invalid_forms'.toUpperCase()}`);
    }
    return invalid
  }

  // helper for submit
  /** Returns true if forms are invalid and shows message toast.
   *
   * @param notification
   * @param showWarning
   * @param controls
   */
  static reflectCheck(notification: ToastService, showWarning: boolean = true, ...controls: boolean[]): boolean {
    const invalid: boolean = controls.filter(value => !value).length > 0;
    if (invalid && showWarning) {
      notification.showWarning(`NOTIFICATION.TOAST.WARNING.${'invalid_forms'.toUpperCase()}`);
    }
    return invalid;
  }

  static async warnPendingChanges(modalService: ModalService): Promise<boolean> {
    let modal: ModalContainerContent = new ModalContainerContent(
      'ITEM_LIST.ALERT.CHANGES.TITLE',
      'ITEM_LIST.ALERT.CHANGES.TEXT',
    );
    const puffer = modal.submit;
    modal.submit = modal.close;
    modal.close = puffer;
    modal.submitClass = 'btn-outline-danger';
    modal.closeClass = 'btn-outline-light';
    return modalService.openModal(modal);
  }
}
