import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from "@angular/core";
import {
  animate,
  AnimationBuilder,
  AnimationFactory,
  AnimationMetadata,
  style,
} from "@angular/animations";

@Directive({selector: '[loadingCes]'})
export class LoadingDirective implements OnChanges {
  @Input('loadingCes') loading: boolean | string = false;
  @Input('loadingCesAnimationDuration') animationDuration: number = 1500;
  @Input('loadingCesSvg') svg: string = '<svg width="400" height="400" xmlns="http://www.w3.org/2000/svg" version="1.1" xml:space="preserve">\n' +
    ' <g>\n' +
    '  <title>Layer 1</title>\n' +
    '  <g stroke="null" id="svg_1">\n' +
    '   <g stroke="null" id="svg_2">\n' +
    '    <g stroke="null" id="svg_3">\n' +
    '     <path stroke="null" class="st0" d="m199.995,91.77c-59.77,0 -108.22,48.45 -108.22,108.23c0,59.78 48.46,108.23 108.22,108.23c59.77,0 108.23,-48.45 108.23,-108.23c0,-59.78 -48.46,-108.23 -108.23,-108.23zm0,204.22c-53.02,0 -95.99,-42.98 -95.99,-96s42.97,-96 95.99,-96s96,42.98 96,96s-42.98,96 -96,96z" fill="rgba(25, 25, 25, 1)" id="svg_4"/>\n' +
    '     <path stroke="null" class="st0" d="m167.185,249.48l-0.59,-18.92c-3.71,3.29 -7.63,5.01 -12.13,5.01c-13.5,0 -18.24,-12.83 -18.24,-35.82c0,-20.5 4.73,-35.36 17.43,-35.36c4.9,0 8.23,1.25 12.53,3.76l0.51,-19.09c-4.41,-1.88 -9.1,-2.81 -13.91,-2.81c-22.6,0 -34.25,23.47 -34.25,56.94c0,26.29 8.05,50.84 31.65,50.84c6.3,-0.02 12.09,-1.72 17,-4.55" fill="rgba(25, 25, 25, 1)" id="svg_5"/>\n' +
    '     <polyline stroke="null" class="st0" points="230.43499755859375,274.9800033569336 230.43499755859375,256.87999725341797 192.06500244140625,256.87999725341797 192.06500244140625,199.45000457763672 225.7550048828125,199.45000457763672 225.7550048828125,182.37999725341797 192.06500244140625,182.37999725341797 192.06500244140625,141.55001068115234 230.43499755859375,141.55001068115234 230.43499755859375,124.41000366210938 173.0449981689453,124.41000366210938 173.0449981689453,274.9800033569336 230.43499755859375,274.9800033569336 " fill="rgba(25, 25, 25, 1)" id="svg_6"/>\n' +
    '     <path stroke="null" class="st0" d="m278.845,220c0,-32.62 -32.6,-25.67 -32.6,-44.53c0,-7.73 3.8,-12.52 13.04,-12.52c4.33,0 8.08,1.66 12.75,3.82l0,-17.94c-4.79,-1.39 -8.2,-2.59 -13.22,-2.59c-17.79,0 -29.38,12.83 -29.38,31.39c0,34.64 31.34,25.05 31.34,45.15c0,9.58 -4.98,13.76 -13.19,13.76c-5.36,0 -10.56,-1.7 -15.23,-4.18l0,19.03c4.68,1.86 9.8,2.62 16.08,2.62c18.26,0 30.41,-14.36 30.41,-34.01" fill="rgba(25, 25, 25, 1)" id="svg_7"/>\n' +
    '    </g>\n' +
    '   </g>\n' +
    '  </g>\n' +
    ' </g>\n' +
    '\n' +
    '</svg>';
  @Input('loadingUseGreyscale') useGreyscale: boolean = true;

  private svgElement: any;
  private overlayWrapper: any;

  private alreadyStarted: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2, private builder: AnimationBuilder) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.convertToBoolean(this.loading)) {
      if (this.alreadyStarted) {
        return;
      } else {
        this.alreadyStarted = true;
      }
      this.overlayWrapper = this.renderer.createElement('div');
      this.renderer.setStyle(this.overlayWrapper, 'position', 'absolute');
      this.renderer.setStyle(this.overlayWrapper, 'z-index', '1000');
      this.renderer.setStyle(this.overlayWrapper, 'top', '0');
      this.renderer.setStyle(this.overlayWrapper, 'left', '0');
      this.renderer.setStyle(this.overlayWrapper, 'width', '100%');
      this.renderer.setStyle(this.overlayWrapper, 'height', '100%');
      this.renderer.setStyle(this.overlayWrapper, 'display', 'flex');
      this.renderer.setStyle(this.overlayWrapper, 'align-items', 'center');
      this.renderer.setStyle(this.overlayWrapper, 'justify-content', 'center');
      this.renderer.setStyle(this.overlayWrapper, 'background-color', 'rgba(40,40,40,0.9)');

      const svgElement = this.renderer.createElement('div');
      svgElement.innerHTML = this.svg;
      this.svgElement = svgElement.firstChild;
      this.renderer.setStyle(this.svgElement, 'position', 'relative');
      this.renderer.setStyle(this.svgElement, 'z-index', '2000');
      if (this.useGreyscale) {
        this.renderer.setStyle(this.svgElement, 'filter', 'grayscale(100%) brightness(200)');
      }
      this.renderer.setStyle(this.svgElement, 'pointer-events', 'none');
      this.renderer.appendChild(this.overlayWrapper, this.svgElement);
      this.renderer.appendChild(this.el.nativeElement, this.overlayWrapper);
      this.builder.build(this.fadeIn(this.animationDuration / 2)).create(this.overlayWrapper).play();
      this.playAnimation();

    } else {
      this.alreadyStarted = false;
      try {
        setTimeout(() => {
          if (this.svgElement) {
            this.renderer.removeChild(this.el.nativeElement, this.svgElement);
          }
          if (this.overlayWrapper) {
            this.renderer.removeChild(this.el.nativeElement, this.overlayWrapper);
          }
        }, 500)
      } catch (e) {
      }
    }
  }

  private animationToPlay: number = 1;

  private playAnimation() {
    if (this.loading) {
      const animation: AnimationFactory = this.builder.build(this.animationToPlay % 2 !== 0 ? this.fadeIn(this.animationDuration / 2) : this.fadeOut(this.animationDuration / 2));
      this.animationToPlay++;
      const player = animation.create(this.svgElement);
      player.play();
      setTimeout(() => this.playAnimation(), this.animationDuration / 2);
    }
  }

  private convertToBoolean(bool: any): boolean {
    if (typeof bool == 'boolean') {
      return bool as boolean;
    }
    if (typeof bool == 'string') {
      return (bool.toLowerCase() == 'true');
    }
    return false;
  }

  private fadeIn(animationDuration: number): AnimationMetadata[] {
    return [
      style({opacity: 0.1}),
      animate(`${animationDuration}ms ease-in`, style(
        {opacity: 1},
      )),
    ];
  }

  private fadeOut(animationDuration: number): AnimationMetadata[] {
    return [
      style({opacity: '*'}),
      animate(`${animationDuration}ms ease-in`, style({opacity: 0.1})),
    ];
  }
}
