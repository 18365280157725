import {Component, Input, OnInit} from '@angular/core';
import {JobDistributedDeviceDto} from "../../../../shared/entities/jobs/JobDistributedDeviceDto";
@Component({
  selector: 'app-jobs-distributed-device-list',
  templateUrl: './jobs-distributed-device-list.component.html',
  styleUrls: ['./jobs-distributed-device-list.component.scss'],
  host: {'class': 'd-flex flex-grow-1'}
})
export class JobsDistributedDeviceListComponent implements OnInit {

  @Input() distributedDevices?: JobDistributedDeviceDto[] = []
  @Input() dynamicList = false

  constructor() { }

  ngOnInit(): void {
  }

}
