import { Directive, Input } from '@angular/core';
import { Validator, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[compare]',
  standalone: true,
  providers: [{provide: NG_VALIDATORS, useExisting: CompareValidatorDirective, multi: true}]
})
export class CompareValidatorDirective implements Validator {
  @Input('compare') controlNameToCompare: any;
  @Input('matching') valueMustMatch: boolean = true;

  validate(c: AbstractControl): ValidationErrors | null {
    const controlToCompare = c.root.get(this.controlNameToCompare);

    if (controlToCompare) {
      const subscription: Subscription = controlToCompare.valueChanges.subscribe(() => {
        c.updateValueAndValidity();
        subscription.unsubscribe();
      });
    }

    return this.mustOrNotMatch(controlToCompare && controlToCompare.value == c.value);
  }

  private mustOrNotMatch(valueMatch: boolean | null) {
    if (valueMatch == null) {
      return null;
    }

    if ((valueMatch && this.valueMustMatch) || (!valueMatch && !this.valueMustMatch)) {
      return null;
    }
    if (!valueMatch && this.valueMustMatch) {
      return {'mustMatch': true }
    }
    if (valueMatch && !this.valueMustMatch) {
      return {'mustNotMatch': false }
    }
    return null;
  }

}
