import {Component, OnInit, ViewChild} from '@angular/core';

import {ItemListFilter, ItemListItem, ItemListMapper} from "../../shared/item-list/ItemListItem";
import {PendingChangesBlocker} from "../../core/guards/pending-changes-view.guard";
import {ApiService} from "../../core/services/api-service/api.service";
import {ListActionEvent} from "../../shared/models/ListActionEvent";
import {ItemActionEvent} from "../../shared/models/ItemActionEvent";
import {
  ItemSaveAction,
  ListEditAction,
  ListViewAction,
  MultiDeleteAction,
  MultiListAction
} from "../../shared/models/Actions";
import {PagetitleAction} from "../../shared/pagetitle/pagetitle-action";
import {ItemListComponent} from "../../shared/item-list/item-list.component";
import {MultiListActionEvent} from "../../shared/models/MultiListActionEvent";
import {TranslateService} from "@ngx-translate/core";
import {AccessProfileFormComponent} from "./access-profile-forms/access-profile-form/access-profile-form.component";
import {
  AccessProfileTimeModelFormComponent
} from "./access-profile-forms/access-profile-time-model-form/access-profile-time-model-form.component";
import {
  AccessProfileLockingDeviceFormComponent
} from "./access-profile-forms/access-profile-locking-device-form/access-profile-locking-device-form.component";
import {ItemManager} from "../../shared/item-list/ItemManager";
import {SubmitUtils} from "../../shared/util/SubmitUtils";
import {TimeModelDto} from "../../shared/entities/TimeModel/TimeModelDto";
import {SessionManager} from "../../core/services/auth-service/support-services/SessionManager";
import {AccessProfileDto} from "../../shared/entities/accessProfile/AccessProfileDto";
import {UpdateAccessProfileDto} from "../../shared/entities/accessProfile/UpdateAccessProfileDto";
import {firstValueFrom, take} from "rxjs";
import {AccessProfileDetailDto} from "../../shared/entities/accessProfile/AccessProfileDetailDto";
import {ToastService} from "../../shared/notification/toast/toast.service";
import {ModalService} from "../../shared/notification/modal/modal.service";
import {ChangeDetectorValue} from "../../shared/util/change-detector/ChangeDetectorValue";
import {cloneDeep} from "lodash";

@Component({
  selector: 'app-access-profiles',
  templateUrl: './access-profiles.component.html',
  styleUrls: ['./access-profiles.component.scss']
})
export class AccessProfilesComponent implements OnInit, ItemListMapper<AccessProfileDto | UpdateAccessProfileDto>, PendingChangesBlocker {
  private readonly readonly247AccessProfile: string = '24/7';

  accessReadonly: boolean = false

  @ViewChild(ItemListComponent) appItemList!: ItemListComponent<any>;

  state: 'edit' | 'create' | 'view' | '' = '';

  // Items
  itemManager: ItemManager<AccessProfileDto>;
  selectedItem?: AccessProfileDto | UpdateAccessProfileDto | AccessProfileDetailDto;
  selectedDetailItem?: AccessProfileDetailDto;
  itemSelected = false;
  itemForm: ChangeDetectorValue = new ChangeDetectorValue(AccessProfileDto.emptyAccessProfileDto());

  // Actions
  saveAction = new ItemSaveAction()

  pagetitleActions: PagetitleAction[] = []
  addAction = new PagetitleAction("BUTTON.ADD", "add-access-profile", "btn-outline-primary", "mdi mdi-plus-circle-outline")

  multiSelectActions: MultiListAction[] = []
  multiDeleteAction = new MultiDeleteAction()

  // Data
  deviceUuid: string | undefined = undefined;

  timeModels?: TimeModelDto[];

  // Search
  typeaheadIndex = new Set<string>()

  get searchEntries() {
    return [...this.typeaheadIndex.values()]
  }

  searchQuery: string = ""
  searchFilter = new ItemListFilter<AccessProfileDto>(item => {
    return String(item.name).toLowerCase().includes(this.searchQuery.toLowerCase());
  });

  // Tabs
  selectedNavTab: 'accessProfile' | 'timeModels' | 'lockingDevices' = 'accessProfile';

  pendingFormChanges = false

  // Forms
  @ViewChild(AccessProfileFormComponent) accessProfileComponent!: AccessProfileFormComponent;
  @ViewChild(AccessProfileTimeModelFormComponent) accessProfileTimeModelComponent!: AccessProfileTimeModelFormComponent;
  @ViewChild(AccessProfileLockingDeviceFormComponent) accessProfileLockingDeviceComponent!: AccessProfileLockingDeviceFormComponent;

  licenseTypeId: number = 1;
  licenseIsValidForBusiness: boolean = true;

  constructor(
    private apiService: ApiService,
    private notification: ToastService,
    private modalService: ModalService,
    private translate: TranslateService
  ) {
    this.itemManager = new ItemManager<AccessProfileDto>(this, notification);
  }

  async ngOnInit() {
    this.apiService.accessProfile.getAll().then(observer => {
      observer.pipe(take(1)).subscribe(accessProfiles => {
        this.itemManager.setItems(accessProfiles);
        this.typeaheadIndex.clear();
        this.itemManager.forEach(item => {
          this.typeaheadIndex.add(item.name);
        })
      });
    });

    if (this.apiService.accessProfile.canAdd) {
      this.pagetitleActions.push(this.addAction)
    }

    if (this.apiService.accessProfile.canDelete) {
      this.multiSelectActions.push(this.multiDeleteAction)
    }

    const licenseValid: boolean = !SessionManager.getInstance().isLicenseExpired;
    const businessLicense: boolean = SessionManager.getInstance().isLicenseBusiness;
    this.licenseIsValidForBusiness = licenseValid && businessLicense;
    this.licenseTypeId = SessionManager.getInstance().isLicenseBusiness ? 2 : 1;
    this.accessReadonly = this.getAccessReadonly()
  }

  // Access
  getAccessReadonly(): boolean {
    return this.apiService.accessProfile.accessIsReadOnly && this.licenseIsValidForBusiness;
  }

  // Interfaces
  checkPendingChanges() {
    const hasChanged = this.accessProfileComponent?.changed == true ||
      this.accessProfileTimeModelComponent?.changed == true ||
      this.accessProfileLockingDeviceComponent?.changed == true;

    this.pendingFormChanges = hasChanged
    this.saveAction.disabled = !hasChanged || this.itemForm?.isValid == false;
  }

  hasPendingChanges(): boolean {
    this.checkPendingChanges();
    return this.pendingFormChanges || this.state == 'create';
  }

  mapToItemList(item: AccessProfileDto | UpdateAccessProfileDto | AccessProfileDetailDto): ItemListItem<AccessProfileDto> {
    let timeModelCount: number;
    let lockingDeviceCount: number;
    let groupLockingDeviceCount: number;


    if (item instanceof UpdateAccessProfileDto) {
      timeModelCount = item.timeModels?.length || 0;
      lockingDeviceCount = item.lockingDevices?.length || 0;
      groupLockingDeviceCount = item.lockingDeviceGroups?.length || 0;
    } else if (item instanceof AccessProfileDetailDto) {
      timeModelCount = item.timeModels?.map(val => val.uuid).length || 0;
      lockingDeviceCount = item.lockingDevices?.map(val => val.uuid).length || 0;
      groupLockingDeviceCount = item.lockingDeviceGroups?.map(val => val.uuid).length || 0;
    } else {
      timeModelCount = item.timeModelCount || 0;
      lockingDeviceCount = item.lockingDeviceCount || 0;
      groupLockingDeviceCount = item.groupLockingDeviceCount || 0;
    }

    let mappedItem: AccessProfileDto = new AccessProfileDto(
      item.uuid,
      item.name,
      item.description || '',
      item.accessTypeId,
      timeModelCount,
      lockingDeviceCount,
      groupLockingDeviceCount,
      item.officeFunction,
      item.pin,
      0
    );

    const itemListItem = new ItemListItem<AccessProfileDto>(item.uuid, item.name, mappedItem)
      .addInfo(this.translate.instant("ACCESS_PROFILES.LIST.TIME_MODEL_COUNT", {count: timeModelCount || 0}))
      .addInfo(this.translate.instant("ACCESS_PROFILES.LIST.LOCKING_DEVICE_COUNT", {lockingDevices: lockingDeviceCount || 0}))
    if (this.licenseTypeId > 1) {
      itemListItem.addInfo(this.translate.instant("ACCESS_PROFILES.LIST.LOCKING_DEVICE_GROUPS_COUNT", {groups: groupLockingDeviceCount || 0}))
    }
    itemListItem
      .addAction(this.accessReadonly ? new ListViewAction() : new ListEditAction())
      .setImage(`assets/ces/access-profiles/${this.apiService.accessProfile.getImageNameForAccessProfileType(item.accessTypeId)}.svg`)

    return itemListItem;

  }

  // Events
  onSearch(search: string) {
    this.searchQuery = search
    this.searchFilter.triggerItemUpdate()
  }

  async onMultiDeleteAction(event: MultiListActionEvent<AccessProfileDto>) {
    let allSuccess = true;
    this.isLoading = true;

    for (const item of event.items) {
      if (!(await firstValueFrom(await this.apiService.accessProfile.delete(item.uuid, true))).ok) {
        allSuccess = false;
      } else {
        this.itemManager.removeItems(item);
      }
    }

    this.isLoading = false;

    if (allSuccess) {
      this.notification.showSuccess('NOTIFICATION.TOAST.SUCCESS.DELETE');
      return
    }

    this.notification.showWarning('ACCESS_PROFILES.ACTION.DELETE_WARNING');
  }

  async onAddAccessProfile() {
    if (this.hasPendingChanges() && await SubmitUtils.warnPendingChanges(this.modalService)) {
      return;
    }

    this.state = 'create';
    let listItem;
    let typeId;
    this.deviceUuid = undefined;
    if (this.selectedDetailItem) {
      this.selectedItem!.uuid = '-1';
      this.selectedDetailItem.uuid = '-1';
      this.initItemForm(this.selectedDetailItem);
    } else {
      this.initItemForm();
    }

    listItem =
      new ItemListItem<AccessProfileDetailDto>(
        "-1",
        this.translate.instant("ACCESS_PROFILES.ACTION.NEW_ACCESS_PROFILE_NAME"),
        this.itemForm!.value,
      );
    typeId = this.itemForm!.value.accessTypeId;


    listItem.setImage(
      `assets/ces/access-profiles/${this.apiService.accessProfile.getImageNameForAccessProfileType(typeId)}.svg`
    );

    this.appItemList.setSelectedListItem(listItem, new ItemSaveAction());
  }

  onResetView() {
    this.state = '';
    this.selectedNavTab = 'accessProfile'

    this.accessProfileTimeModelComponent.reset();
    this.accessProfileLockingDeviceComponent.reset();
    this.accessProfileComponent.reset();

    this.selectedItem = undefined;
    this.selectedDetailItem = undefined;
  }

  async onSelectEvent(actionEvent: ListActionEvent<AccessProfileDto | UpdateAccessProfileDto>) {
    if (actionEvent == undefined) return
    this.accessProfileTimeModelComponent.selectListComponent.selectedItems = new Set<string>();

    this.state = 'edit';
    this.deviceUuid = actionEvent.item.uuid;

    this.apiService.accessProfile.get(actionEvent.item.uuid).then(observer => {
      observer.pipe(take(1)).subscribe(accessProfile => {
        this.initItemForm(accessProfile);
        this.selectedItem = accessProfile;
        this.selectedDetailItem = accessProfile;
        if (this.apiService.accessProfile.canAdd) {
          actionEvent.addItemAction(this.saveAction)
        }
      });
    });
  }

  async onEditEvent(actionEvent: ItemActionEvent<AccessProfileDto | UpdateAccessProfileDto>) {
    this.state = 'view';
    if (actionEvent == undefined) return;

    if (actionEvent.action instanceof ItemSaveAction) {
      await this.onSubmit(actionEvent.returnToList)
    }
  }

  async onSubmit(returnToList: () => void) {

    if (SubmitUtils.reflectCheck(this.notification, true, this.hasPendingChanges())) {
      return
    }

    let accessProfile: UpdateAccessProfileDto = this.itemForm?.value!;
    accessProfile.accessTypeId = parseInt(this.itemForm?.value!.accessTypeId);
    accessProfile.lockingDevices = this.accessProfileLockingDeviceComponent.selectedUuids.device;
    accessProfile.lockingDeviceGroups = this.accessProfileLockingDeviceComponent.selectedUuids.groups;
    accessProfile.timeModels = this.accessProfileTimeModelComponent.getSelectedTimeModelsUuids();

    if (accessProfile!.uuid.length > 3) {
      accessProfile = await firstValueFrom(await this.apiService.accessProfile.updateAccessProfile(accessProfile!));
    } else {
      accessProfile = await firstValueFrom(await this.apiService.accessProfile.addAccessProfile(accessProfile!));
    }

    this.itemManager.updateItem(new AccessProfileDto(
      accessProfile.uuid,
      accessProfile.name,
      accessProfile.description,
      accessProfile.accessTypeId,
      accessProfile.timeModels?.length,
      accessProfile.lockingDevices?.length,
      accessProfile.lockingDeviceGroups?.length,
      accessProfile.officeFunction,
      accessProfile.pin,
      0
    ), true);

    returnToList();
    this.onResetView();
  }

  async onDelete(returnToList: () => void) {
    if (!(await firstValueFrom(await this.apiService.accessProfile.delete(this.selectedItem!.uuid)))) {
      return;
    }

    this.itemManager.removeItemsById(this.selectedItem!.uuid);
    returnToList()
  }

  initItemForm(accessProfile: AccessProfileDetailDto = AccessProfileDetailDto.emptyAccessProfileDetailDto()): void {
    this.itemForm = new ChangeDetectorValue(cloneDeep(accessProfile), () => {
        this.checkPendingChanges()
      }, new Map<string, (input: any) => boolean>()
        .set('requiredName', (input: any) => {
          return (input.name as string).length != 0;
        }).set('maxLengthName', (input: any) => {
          return (input.name as string).length <= 32;
        }).set('maxLengthDescription', (input: any) => {
          return input.description == undefined || (input.description as string).length <= 64;
        })
    );
    if (this.state == 'create') {
      this.itemForm.initialValue = AccessProfileDetailDto.emptyAccessProfileDetailDto();
      // Todo if add should also be copy then delete following line // decide pending: Maik Felchner
      this.itemForm.value = AccessProfileDetailDto.emptyAccessProfileDetailDto();
    }
  }

  // Getter & Setter
  isLoading: boolean = false;
}
