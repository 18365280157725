import {IoBridgeStatus} from "../../../core/enums/ioBridgeStatus";
import {StatusResponseDto} from "./StatusResponseDto";
import {IoBridgeFlow} from "../../../core/enums/ioBridgeFlow";

export class BindingProcess {
  private _flow: IoBridgeFlow;
  private _isLoading: boolean;
  private _ioBridgeStatus: IoBridgeStatus;
  private _dtwStatus: IoBridgeStatus;
  private _pin: string;
  private _deviceName: string;
  private _deviceUuid: string;
  private _deviceUid: string;
  private _serialNumber: string;
  private _iOBridgeResponseDto: StatusResponseDto;

  constructor(flow: IoBridgeFlow,
              isLoading: boolean,
              ioBridgeStatus: IoBridgeStatus,
              dtwStatus: IoBridgeStatus,
              pin: string,
              deviceName: string,
              deviceUuid: string,
              deviceUid: string,
              serialNumber: string,
              iOBridgeResponseDto: StatusResponseDto) {
    this._flow = flow;
    this._isLoading = isLoading;
    this._ioBridgeStatus = ioBridgeStatus;
    this._dtwStatus = dtwStatus;
    this._pin = pin;
    this._deviceUuid = deviceUuid;
    this._deviceUid = deviceUid;
    this._serialNumber = serialNumber;
    this._iOBridgeResponseDto = iOBridgeResponseDto;
    this._deviceName = deviceName
  }

  static emptyBindingProcess(): BindingProcess {
    return new this(
      IoBridgeFlow.UNKNOWN,
      false,
      IoBridgeStatus.DISCONNECTED,
      IoBridgeStatus.DISCONNECTED,
      '',
      '',
      '',
      '',
      '',
      StatusResponseDto.emptyStatusResponseDto()
    )
  }

  resetBindingProcess() {
    const current: BindingProcess = this;
    let that: BindingProcess = BindingProcess.emptyBindingProcess();
    that.flow = current.flow;
    that.dtwStatus = current.dtwStatus;
    that.ioBridgeStatus = current.ioBridgeStatus;
    return that;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  set isLoading(value: boolean) {
    this._isLoading = value;
  }

  get ioBridgeStatus(): IoBridgeStatus {
    return this._ioBridgeStatus;
  }

  set ioBridgeStatus(value: IoBridgeStatus) {
    this._ioBridgeStatus = value;
  }

  get dtwStatus(): IoBridgeStatus {
    return this._dtwStatus;
  }

  set dtwStatus(value: IoBridgeStatus) {
    this._dtwStatus = value;
  }

  get pin(): string {
    return this._pin;
  }

  set pin(value: string) {
    this._pin = value;
  }

  get deviceName(): string {
    return this._deviceName;
  }

  set deviceName(value: string) {
    this._deviceName = value;
  }

  get deviceUuid(): string {
    return this._deviceUuid;
  }

  set deviceUuid(value: string) {
    this._deviceUuid = value;
  }

  get deviceUid(): string {
    return this._deviceUid;
  }

  set deviceUid(value: string) {
    this._deviceUid = value;
  }

  get serialNumber(): string {
    return this._serialNumber;
  }

  set serialNumber(value: string) {
    this._serialNumber = value;
  }

  get iOBridgeResponseDto(): StatusResponseDto {
    return this._iOBridgeResponseDto;
  }

  set iOBridgeResponseDto(value: StatusResponseDto) {
    this._iOBridgeResponseDto = value;
  }

  get flow(): IoBridgeFlow {
    return this._flow;
  }

  set flow(value: IoBridgeFlow) {
    this._flow = value;
  }
}
