<app-item-list [items]="itemManager" [enableChangesWarning]="hasPendingChanges()" [showLoading]="isLoading"
               (onListAction)="onSelectEvent($event)" (onItemAction)="onEditEvent($event)" (onItemReturn)="onResetView()"
               [listFilter]="searchFilter" [(itemSelected)]="itemSelected" [viewTypeModuleId]="'20'"
               [multiSelectActions]="multiSelectActions" (onMultiListAction)="onMultiDeleteAction($event)">

  <ul ngbNav #nav="ngbNav" class="nav nav-tabs nav-tabs-custom nav-justified" [(activeId)]="selectedNavTab"
      [destroyOnHide]="false" (navChange)="onNavigation($event)">
    <li ngbNavItem="group">
      <a ngbNavLink>{{ "USERS.GROUPS.TAB.GENERAL" | translate }}</a>
      <ng-template ngbNavContent>
        <app-user-group-form [userGroup]="selectedItem!" [accessReadonly]="accessReadonly" (onChanges)="checkPendingChanges()">
        </app-user-group-form>
      </ng-template>
    </li>
    <li ngbNavItem="users">
      <a ngbNavLink>{{ "USERS.GROUPS.TAB.ASSIGNED_USERS" | translate }}</a>
      <ng-template ngbNavContent>
        <app-user-group-assignment-form [assignedUsers]="userGroupAssignments"
                                        [users]="users"
                                        [readonly]="accessReadonly && !canChangeUserGroupAssignmentSettings"
                                        (onChanges)="checkPendingChanges()">
        </app-user-group-assignment-form>
      </ng-template>
    </li>
    <li ngbNavItem="accessProfiles">
      <a ngbNavLink>{{ "USERS.GROUPS.TAB.ACCESS_PROFILES" | translate }}</a>
      <ng-template ngbNavContent>
        <app-user-group-access-profile-form [assignedAccessProfiles]="userGroupAccessProfiles"
                                            [accessProfiles]="accessProfiles"
                                            [readonly]="accessReadonly && !canChangeUserGroupAccessSettings"
                                            (onChanges)="checkPendingChanges()">
        </app-user-group-access-profile-form>
      </ng-template>
    </li>
  </ul>

  <div [ngbNavOutlet]="nav" id="navOutlet" class="pt-4"></div>

</app-item-list>
