import {LookupMapping} from "./lookup";

export const LICENSE: LookupMapping[] = [
  {
    "id": 1,
    "value": "basic"
  },
  {
    "id": 2,
    "value": "business"
  },
  {
    "id": 3,
    "value": "extension"
  }
];
