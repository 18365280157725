<div [loadingCes]="isLoading" id="content">
  <div class="modal-header border-bottom-0">
    <h4 class="modal-title center" id="modal-basic-title">
      {{ (isFlowBind ? "PROGRAMMING.MODAL.ADD_LOCKING_DEVICE" : isFlowRebind ? "PROGRAMMING.MODAL.REPAIR_LOCKING_DEVICE" : "PROGRAMMING.MODAL.REMOVE_LOCKING_DEVICE") | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <!-- SERIAL NUMBER INPUT -->
    <div class="dtw-input">
      <div class="form-floating form-floating-custom flex-grow-1">
        <input type="text" class="form-control" id="sn-id"
               [(ngModel)]="provideSN"
               [disabled]="!serialNumberInputEnabled || errorIsPresent">
        <label for="sn-id"
               class="text-truncate">{{ (isFlowRebind ? "PROGRAMMING.MODAL.INPUT_FIELDS.NEW_SERIAL_NUMBER": "PROGRAMMING.MODAL.INPUT_FIELDS.SERIAL_NUMBER") | translate }}</label>
        <div class="form-floating-icon">
          <i icon="io-sn"></i>
        </div>
      </div>
    </div>
    <!-- PIN INPUT -->
    <div *ngIf="isFlowBind || isFlowRebind" class="dtw-input">
      <div class="form-floating form-floating-custom flex-grow-1">
        <input type="text" class="form-control" id="pin-id"
               [(ngModel)]="providePin"
               [disabled]="processAndPollingRunning || isLoading || errorIsPresent">
        <label for="pin-id" class="text-truncate">{{ "PROGRAMMING.MODAL.INPUT_FIELDS.PAIRING_ID" | translate }}</label>
        <div class="form-floating-icon">
          <i icon="io-pin"></i>
        </div>
      </div>
    </div>
    <!-- NAME OUTPUT -->
    <div *ngIf="isFlowUnbind" class="dtw-input">
      <div class="form-floating form-floating-custom flex-grow-1">
        <input type="text" class="form-control" id="name-id"
               [(ngModel)]="deviceName"
               [disabled]="true">
        <label for="name-id" class="text-truncate">{{ "TIME_MODELS.FIELD.SHORT_NAME.TEXT" | translate }}</label>
        <div class="form-floating-icon">
          <i icon="name"></i>
        </div>
      </div>
    </div>
    <!-- Add / REMOVE DEVICE BUTTON -->
    <div class="dtw-cta">
      <div class="start-binding">
        <button type="button"
                [ngClass]="(isFlowBind || isFlowRebind) ? 'm-1 btn btn-outline-primary' : 'm-1 btn btn-outline-danger'"
                data-testid="item-list-action-buttons"
                style="width: 99%; height: 52px;"
                (click)="startButtonClicked = true; startProcessIOBridge()"
                [disabled]="startButtonClicked || !this.pinInputEnabled">
          <i *ngIf="!this.startButtonEnabled" icon="io-start" style="padding-right: 8px;"></i>
          {{ (isFlowRebind ? "PROGRAMMING.MODAL.SEND_AND_REPAIR" : isFlowBind ? "PROGRAMMING.MODAL.SEND_AND_BIND" : "PROGRAMMING.MODAL.SEND_AND_UNBIND") | translate }}
        </button>
      </div>
    </div>

    <div id="progress-container" class="progress-container">
      <h3 class="progress-headline">{{ "PROGRAMMING.MODAL.PROGRESS" | translate }}
      <i *ngIf="processAndPollingRunning" icon="io-loading" class="spin"></i></h3>
      <div class="progress">
        <ngb-progressbar *ngIf="errorIsPresent" class="w-100 mb-1 border-primary progress-red" [value]="1" [max]="1"></ngb-progressbar>
        <ngb-progressbar *ngIf="!errorIsPresent" class="w-100 mb-1 border-primary" [value]="currentOrderNumber" [max]="ioBridgeProcess.stateFinalize.order"></ngb-progressbar>
      </div>
      <div class="progress-steps">
        <ul style="padding-right: 2rem">
          <li class="center">{{ error | iob:state:{errorIsPresent:errorIsPresent,code:errorCode} }}</li>
        </ul>
      </div>
    </div>
  </div>
  <div class="modal-footer border-top-0">
    <button type="button" class="btn text-secondary btn-outline-danger" [hidden]="!errorIsPresent"
            (click)="onAbort()">{{ "PROGRAMMING.MODAL.BUTTON.CANCEL" | translate }}
    </button>
    <button type="button" class="btn text-secondary btn-outline-light"
            (click)="onClose(ModalCloseCause.CLOSED)">{{ (isFlowBind ? "PROGRAMMING.MODAL.BUTTON.CLOSE_LIST" : "PROGRAMMING.MODAL.BUTTON.CLOSE_DETAIL") | translate }}
    </button>
  </div>
</div>
