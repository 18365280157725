export class ClientVersionDto {
  clientIdent: string;
  platform: string;
  version: string;

  constructor(clientIdent: string, platform: string, version: string) {
    this.clientIdent = clientIdent;
    this.platform = platform;
    this.version = version;
  }

  static emptyClientVersionDto():ClientVersionDto {
    return new this('', '', '');
  }
}
