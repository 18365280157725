import { Component, OnInit } from '@angular/core';

import {ITimeModelDynamicForm} from "../ITimeModelDynamicForm";
import {ApiService} from "../../../../core/services/api-service/api.service";
import {AbstractControl} from "@angular/forms";
import {TimeModelDto} from "../../../../shared/entities/TimeModel/TimeModelDto";

@Component({
  selector: 'app-time-model-daily-basis-form',
  template: '',
  styles: []
})
export class TimeModelDailyBasisFormComponent implements OnInit, ITimeModelDynamicForm {

  readonly intervalEnabled = true;
  readonly customFormEnabled = false;

  get dynamicForm(): AbstractControl | null {
    return null;
  }

  constructor(private apiService: ApiService) { }

  ngOnInit(): void {
  }

  initialize(timeModel: TimeModelDto, accessReadonly: boolean): void {
  }

  getTimeModel(): TimeModelDto {
    return TimeModelDto.emptyTimeModelDto();
  }

  get invalid(): boolean {
    return false;
  }

  onChanges(): void {
  }

  get changed(): boolean {
    return false;
  }

}
