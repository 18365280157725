export class TimeModelIntervalDto {
  uuid?: string;
  id: number;
  timeStart: number;
  timeEnd: number;

  constructor(uuid: string | undefined, id: number, timeStart: number, timeEnd: number) {
    this.uuid = uuid;
    this.id = id;
    this.timeStart = timeStart;
    this.timeEnd = timeEnd;
  }
}
