import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";
import {NodeManager} from "../services/auth-service/support-services/NodeManager";
import {NodeConfiguration} from "../interfaces/nodeConfiguration";
import {LocalStorageSchemaService} from "../services/json-schema-service/local-storage-schema.service";
import {SessionManager} from "../services/auth-service/support-services/SessionManager";
import {UserSessionInfoDto} from "../../shared/entities/user/UserSessionInfoDto";

@Injectable()
export class AddHeaderInterceptor implements HttpInterceptor {
  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isNeedToIntercept(req.url)) {
      return next.handle(req.clone());
    }

    let headers: { [name: string]: string | string[] } = {};
    headers = this.addApiKeyMasterNode(req.url, headers);
    headers = this.addApiKeyDataNode(req.url, headers);
    headers = this.addHeadersDataNode(req.url, headers);
    headers = this.addHeadersIOBridge(req.url, headers);

    if (req.method.toUpperCase() == 'POST' && !req.url.includes('/image')) {
      headers = this.append(headers, 'Content-Type', 'application/json');
    }

    return next.handle(req.clone({setHeaders: headers}));
  }

  private addApiKeyMasterNode(url: string, headers: { [name: string]: string | string[] }): {
    [name: string]: string | string[]
  } {
    if (url.includes('CESMaster')) {
      headers = this.append(headers, 'API-Key', `${environment.masterNodeKey}`);
    }
    return headers;
  }

  private addApiKeyDataNode(url: string, headers: { [name: string]: string | string[] }): {
    [name: string]: string | string[]
  } {
    if (url.includes('CESAdmin') || (url.includes('/status') && url.includes(environment.ioBridgeUrl))) {
      const localDataNode: NodeConfiguration | undefined = NodeManager.getInstance().dataNodeConfiguration;
      if (localDataNode != undefined) {
        headers = this.append(headers, 'API-Key', `${localDataNode.apiKey}`);
      }
    }
    return headers;
  }

  private addHeadersDataNode(url: string, headers: { [name: string]: string | string[] }): {
    [name: string]: string | string[]
  } {
    if (url.includes('CESAdmin')) {
      if (!url.includes('/image')) {
        //headers = this.append(headers, 'Content-Type:', 'application/json');
      }
      const localSessionCredentials: UserSessionInfoDto | undefined = SessionManager.getInstance().sessionCredentials;
      if (localSessionCredentials) {
        headers = this.append(headers, 'Authorization', `Bearer ${localSessionCredentials.token}`);
        if (url.includes('refreshToken')) {
          headers = this.append(headers, 'New-Token', `${localSessionCredentials.token}`);
          headers = this.append(headers, 'New-Expire-At', `${localSessionCredentials.expireAt}`);
        }
      }
      headers = this.append(headers, 'Localization', `${new Map([
        [ 'de', 'de-DE' ],
        [ 'en', 'en-GB' ],
        [ 'nl', 'nl-NL' ],
        [ 'fr', 'fr-FR' ]
      ]).get(new LocalStorageSchemaService().localStorage('lang').plain || 'en') || 'en-GB'}`);
    }
    return headers;
  }

  private addHeadersIOBridge(url: string, headers: { [name: string]: string | string[] }): {
    [name: string]: string | string[]
  } {
    if (url.includes('/status') && url.includes(environment.ioBridgeUrl)) {
      //headers = this.append(headers, 'Content-Type:', 'application/json');
      const localDataNode: NodeConfiguration | undefined = NodeManager.getInstance().dataNodeConfiguration;
      if (localDataNode != undefined) {
        headers = this.append(headers, 'Data-Node-Url', `${localDataNode.scheme}://${localDataNode.host}${localDataNode.path}`);
      }
      const localSessionCredentials: UserSessionInfoDto | undefined = SessionManager.getInstance().sessionCredentials;
      if (localSessionCredentials) {
        headers = this.append(headers, 'New-Token', `${localSessionCredentials.token}`);
        headers = this.append(headers, 'New-Expire-At', `${localSessionCredentials.expireAt}`);
      }
    }
    return headers;
  }

  private append(obj: { [name: string]: string | string[] }, key: string, value: string): {
    [name: string]: string | string[]
  } {
    if (key in obj) {
      const existingValue: string | string[] = obj[key];
      if (Array.isArray(existingValue)) {
        existingValue.push(value);
      } else {
        obj[key] = [existingValue, value];
      }
    } else {
      obj[key] = value;
    }
    return obj;
  }

  private isNeedToIntercept(url: string): boolean {
    return (url.includes(environment.ioBridgeUrl) || url.includes('CESAdmin') || url.includes('CESMaster'));
  }

}
