import {
  Directive,
  Input,
  OnChanges,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from "@angular/core";
import {SkeletonComponent} from "../../shared/skeleton/skeleton.component";

@Directive({selector: '[skeleton]'})
export class SkeletonDirective implements OnChanges {
  @Input('skeleton') loading: boolean = false;
  @Input('skeletonRepeat') size = 1;
  @Input('skeletonClassName') className: string[] = [''];
  @Input('skeletonWidth') width: string = '';
  @Input('skeletonHeight') height: string = '';
  @Input('skeletonAcceptedLoadingDuration') acceptedLoadingDuration: number = 500;
  private templateRef: TemplateRef<any>;
  private lastChange: number = Date.now();

  constructor(tpl: TemplateRef<any>,
              private viewContainerRef: ViewContainerRef) {
    this.templateRef = tpl;
  }

  ngOnChanges(changes: SimpleChanges) {
    const timePassed = (Date.now() - this.lastChange)
    if (timePassed < this.acceptedLoadingDuration) {
      this.viewContainerRef.clear();
      this.viewContainerRef.createEmbeddedView(this.templateRef)
      return;
    }
    if (changes.loading) {
      this.viewContainerRef.clear();
      this.addLoadingSkeleton();
      if (!changes.loading.currentValue) {
        setTimeout(() => {
          this.viewContainerRef.clear();
          this.viewContainerRef.createEmbeddedView(this.templateRef)
        }, 500);
      }
    }
  }

  private addLoadingSkeleton() {
    Array.from({length: this.size}).forEach(() => {
      const ref = this.viewContainerRef.createComponent(SkeletonComponent);

      Object.assign(ref.instance, {
        className: this.className,
        width: this.width,
        height: this.height,
      });
    });
    this.lastChange = Date.now();
  }

}
