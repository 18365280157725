import {Pipe, PipeTransform} from '@angular/core';
import {AbstractControl, ValidationErrors} from "@angular/forms";

@Pipe({
  name: 'hasErrors',
  pure: false
})
export class HasErrorsPipe implements PipeTransform {

  transform(control: AbstractControl | AbstractControl[] | null, ...errors: string[]): boolean {

    if(control == null) {
      return false;
    }

    if(Array.isArray(control)) {
      return control.some((ctrl) => this.validateControl(ctrl, ...errors))
    }

    return this.validateControl(control, ...errors)
  }

  private validateControl(control: AbstractControl, ...errors: string[]): boolean {
    if(control == null || control.errors == null) {
      return false
    }
    if(errors == null || errors.length == 0) {
      return control.touched
    }

    return Object.keys(control.errors as Object).every(value => errors.includes(value))
      || errors.every(value => (control.errors as ValidationErrors)[value] != undefined);
  }
}
