<div class="io-download-wrapper">
  <div>
    <a type="button" class="m-1 btn btn-outline-secondary" href="{{downloadLink}}" download="IOBridge">
      <i icon="io-download"></i>
      {{ translatedButtonText }}
    </a>
  </div>
  <div>
    <button type="button" class="m-1 btn btn-outline-secondary" (click)="openInfo()">
      <i icon="io-instruction"></i>
      {{ "PROGRAMMING.INSTALLATION_INFO.TITLE" | translate }}
    </button>
  </div>
</div>
