import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Trigger} from "../../../../shared/util/Trigger";
import {FormatterService} from "../../../../core/services/formatter-service/formatter.service";
import {ChangeDetectorValue} from "../../../../shared/util/change-detector/ChangeDetectorValue";
import {TimeModelIntervalDto} from "../../../../shared/entities/TimeModel/TimeModelIntervalDto";

const maxIntervalCount = 3
const minIntervalCount = 1

export interface TimeModelIntervalUnique {
  timeModelUuid: string,
  id: number,
  timeStart: ChangeDetectorValue,
  timeEnd: ChangeDetectorValue,
  unique: number,
}

@Component({
  selector: 'app-interval-selector',
  templateUrl: './interval-selector.component.html',
  styleUrls: ['./interval-selector.component.scss'],
  animations: [Trigger.FadeAnimation],
})
export class IntervalSelectorComponent {

  private _uniqueCounter: number = 0;
  _timeModelInterval: TimeModelIntervalUnique[] = [];
  invalidFrameUnique: number[] = [];

  @Output() changes = new EventEmitter<boolean>();

  @Input() accessReadonly = false;
  @Input() set timeModelInterval(timeModelIntervals: TimeModelIntervalDto[]) {
    this._timeModelInterval = timeModelIntervals.map(value => {
      return {
        timeModelUuid: value.uuid || '',
        id: value.id,
        timeStart: new ChangeDetectorValue(this.formatter.formatToNgbTimePicker(value.timeStart), () => {
          this.onChanges()
        }),
        timeEnd: new ChangeDetectorValue(this.formatter.formatToNgbTimePicker(value.timeEnd), () => {
          this.onChanges()
        }),
        unique: value.id == 0 ? this.uniqueCounter : value.id,
      } as TimeModelIntervalUnique
    });
    this._timeModelInterval.forEach(value => {
      value.timeStart.reset();
      value.timeEnd.reset();
    });
  }

  onChanges() {
    // Trigger invalid check
    this.isInvalid;
    this.changes.emit(true);
  }

  constructor(private formatter: FormatterService) {
  }

  get addEnabled(): boolean {
    return this._timeModelInterval.length < maxIntervalCount && !this.accessReadonly;
  }

  get deleteEnabled(): boolean {
    return this._timeModelInterval.length > minIntervalCount && !this.accessReadonly;
  }

  addInterval() {
    if (this.addEnabled) {
      this._timeModelInterval.push({
        id: 0,
        timeModelUuid: '',
        timeStart: new ChangeDetectorValue(this.newTime(), () => {
          this.onChanges()
        }),
        timeEnd: new ChangeDetectorValue(this.newTime(false), () => {
          this.onChanges()
        }),
        unique: this.uniqueCounter,
      });
      this._timeModelInterval = this.timeModelIntervalSorted;
      this.onChanges();
    }
  }

  newTime(start: boolean = true, newTime: {hour: number, minute: number} = {hour: 0, minute: 0}): {hour: number, minute: number} {
    this._timeModelInterval.forEach((value, index) => {
      if (value.timeStart.value.hour == newTime.hour) {
        newTime = this.newTime(start, {hour: newTime.hour +1, minute: 0});
      }
    });
    if (!start) {
      return {hour: newTime.hour, minute: 10};
    }
    return newTime;
  }

  removeInterval(unique: number) {
    if (this.deleteEnabled) {
      this._timeModelInterval = this._timeModelInterval.filter(value => value.unique != unique);
      this._timeModelInterval = this.timeModelIntervalSorted;
      this.onChanges();
    }
  }

  isInInvalidFrameUnique(unique: number): boolean {
    return this.invalidFrameUnique.indexOf(unique) != -1;
  }

  get isInvalid() {
    this.invalidFrameUnique = [];
    let invalid: boolean = false;
    this._timeModelInterval.forEach((outer) => {
      this._timeModelInterval.forEach((inner) => {
        const innerStart: number = this.formatter.formatFromNgbTimePicker(inner.timeStart.value);
        const innerEnd: number = this.formatter.formatFromNgbTimePicker(inner.timeEnd.value);
        if (outer.unique != inner.unique) {
          const outerStart: number = this.formatter.formatFromNgbTimePicker(outer.timeStart.value);
          if (outerStart >= innerStart && innerEnd >= outerStart) {
            this.invalidFrameUnique.push(inner.unique);
          }
          if (!invalid) {
            invalid = this.isOverlapping(inner.timeStart, inner.timeEnd) || this.invalidFrameUnique.length > 0;
          }
        } else {
          if (!invalid) {
            invalid = this.isOverlapping(inner.timeStart, inner.timeEnd) || this.invalidFrameUnique.length > 0;
          }
        }
      });
    });
    return invalid;
  }

  private get uniqueCounter(): number {
    this._uniqueCounter -= 1;
    return this._uniqueCounter;
  }

  get timeModelIntervalSorted(): TimeModelIntervalUnique[] {
    return this._timeModelInterval.sort((a, b) => this.formatter.formatFromNgbTimePicker(a.timeStart.value) -
      this.formatter.formatFromNgbTimePicker(b.timeStart.value));
  }

  get timeModelInterval(): TimeModelIntervalUnique[] {
    return this._timeModelInterval;
  }

  isOverlapping(start: any, end: any): boolean {
    return this.formatter.formatFromNgbTimePicker(start.value) >= this.formatter.formatFromNgbTimePicker(end.value);
  }
}
