import {FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";

export class ChangeDetectorForm {

  private records = new Map<string, string>()
  private _changed = false
  private subscription?: Subscription

  constructor(private formGroup: FormGroup, public changeCallback: (changed: boolean) => void = () => {}) {
    this.refresh()
    this.subscription = formGroup.valueChanges.subscribe((() => { this.onChange() }))
  }

  subscribeTo(formGroup: FormGroup) {
    this.subscription?.unsubscribe()
    this.formGroup = formGroup
    this.refresh()
    this.subscription = formGroup.valueChanges.subscribe((() => { this.onChange() }))
  }

  private onChange() {
    this.detectChanges()
    this.changeCallback(this._changed)
  }

  refresh() {
    this._changed = false
    this.records.clear()

    Object.keys(this.formGroup.controls).forEach(key => {
      this.records.set(key, String(JSON.stringify(this.formGroup.get(key)?.value)).normalize())
    });

    if(this.changeCallback == null) {
      return
    }

    try {
      this.changeCallback(this._changed)
    } catch (err) {

    }
  }

  get(key: string): any {
    return this.records.get(key)
  }

  public get changed(): boolean {
    return this._changed
  }

  public detectChanges() {
    for(let [key, value] of this.records) {
      if(value !== JSON.stringify(this.formGroup.get(key)?.value)?.normalize()) {
        this._changed = true
        return
      }
    }
    this._changed = false
  }
}
