import {ItemAction} from "./Actions";

export class ItemActionEvent<T> {
  constructor(public readonly action: ItemAction, public readonly item:T, public returnToList: () => void) {
  }
}
export class ItemReturnEvent<T> {
  constructor(public readonly item?: T) {
  }
}
