<div class="container">
  <div *ngIf="!accessReadonly"  class="d-flex mb-3">
    <div *ngIf="allowDelete" class="d-flex justify-content-start gap-2">
      <div class="align-self-center">
        <input id="selectAllItemsRemove-{{uniqueId}}" type="checkbox" class="form-check-input"
               [checked]="removeSelection.size == selectedItems.size && removeSelection.size != 0"
               (change)="onRemoveSelectionChangeAll($event)" [attr.disabled]="selectedItems.size == 0 ? true : undefined">
        <label for="selectAllItemsRemove-{{uniqueId}}" class="ps-1 m-0" [ngClass]="{'text-muted': selectedItems.size == 0}">{{ "BUTTON.MULTI.SELECT_ALL" | translate }}</label>
      </div>
      <button type="button" [disabled]="removeSelection.size == 0" (click)="onRemoveSelected()"
              [ngClass]="removeSelection.size == 0 ? 'btn-outline-light text-muted' : 'btn-outline-danger'"
              class="btn">{{ "BUTTON.MULTI.REMOVE_COUNT" | translate:{count: removeSelection.size} }}</button>
    </div>
    <div *ngIf="allowAdd" class="d-flex justify-content-end flex-grow-1">
      <button type="button" class="btn btn-outline-primary" (click)="openItemSelector()">
        {{ "BUTTON.ADD" | translate }}
      </button>
    </div>
  </div>

  <div class="card shadow-none p-2">
    <div class="list-group list-group-flush border-top-0">

      <ng-container *ngFor="let key of sortSelectedItems">

        <div *ngIf="items.get(key) != null" (click)="onRemoveSelectionChange(!removeSelection.has(key), key)"
             [ngClass]="{'list-group-item-action cursor-pointer': !accessReadonly}"
             class="list-group-item d-flex align-items-start flex-grow-1 gap-2">

          <!-- IMAGE -->
          <div *ngIf="items.get(key)!.image != null" class="d-flex avatar flex-shrink-0">
            <img [src]="items.get(key)!.image! | Trust:'resourceUrl'" alt="icon" cesDefaultImage>
          </div>

          <div class="flex-grow-1 overflow-hidden">
            <div class="d-flex mb-2">
              <h6 class="font-size-15 mb-0 text-truncate"><span class="text-dark">{{items.get(key)!.name}}</span></h6>
            </div>
            <p *ngFor="let info of items.get(key)!.info" class="text-muted text-truncate mb-0">{{info}}</p>
          </div>

          <div *ngFor="let badge of items.get(key)!.badges" class="badge" [ngClass]="badge.style"
               placement="left"
               [ngbTooltip]="badge.description"
               triggers="click mouseenter:mouseleave">
            @if (badge.imgUrl) {
              <img style="margin: 0.2em 0.3em 0.2em 0.5em; max-width: 20px; max-height: 20px;" src="{{badge.imgUrl}}">
            } @else {
              <i [ngClass]="badge.icon"></i>
            }
          </div>

          <input *ngIf="!accessReadonly" type="checkbox" class="form-check-input item-selector align-self-start mt-0 font-size-18 flex-shrink-0"
                 [checked]="removeSelection.has(key)" (change)="onRemoveSelectionChange($event, key)">
        </div>

      </ng-container>

      <div *ngIf="selectedItems.size == 0 || items.size == 0" class="p-2">
        <p class="m-2 text-center">
          {{ noSelectedItemsMessage != null ? noSelectedItemsMessage : ( "SELECT_LIST.EMPTY_SELECTION" | translate ) }}
        </p>
      </div>
    </div>
  </div>

  <!-- ADD MODAL -->
  <ng-template #itemSelector let-modal>
    <div class="modal-header">
      <h4 class="modal-title">
        {{ addItemsTitle != null ? addItemsTitle : ( "SELECT_LIST.ADD_ITEMS_TITLE" | translate ) }}
      </h4>
      <button type="button" class="btn-close" aria-label="Close" (click)="modal.close()"></button>
    </div>
    <div id="search-bar" class="d-flex justify-content-between flex-grow-1">
      <form class="search-box w-100 h-100 position-relative" [formGroup]="searchForm" (change)="updateSearch($event)">
        <i class="mdi mdi-magnify search-icon d-flex align-items-center h-100"></i>
        <input id="search-bar-input" data-testid="search-bar-input" type="text" class="form-control rounded h-100" formControlName="searchText"
               placeholder="{{ 'PAGETITLE.SEARCH' | translate }}" (change)="updateSearch($event)" (input)="updateSearch($event)">
      </form>
    </div>

    <div class="modal-body">
      <div class="list-group list-group-flush border-top-0">

        <ng-container *ngFor="let element of sortItems | defaultOrderAndSearchKeyValue: searchText">

          <div *ngIf="!selectedItems.has(element.key)"
               class="list-group-item list-group-item-action d-flex align-items-start flex-grow-1 gap-2 cursor-pointer"
               (click)="onAddSelectionChange(!addSelection.has(element.key), element.key)">

            <!-- IMAGE -->
            <div *ngIf="element.value.image != null" class="d-flex avatar flex-shrink-0 {{extraCssClasses}}">
              <img [src]="element.value.image! | Trust:'resourceUrl'" alt="icon" cesDefaultImage>
            </div>

            <div class="flex-grow-1 overflow-hidden">
              <div class="d-flex mb-2">
                <h6 class="font-size-15 mb-0 text-truncate"><span class="text-dark">{{element.value.name}}</span></h6>
              </div>
              <p *ngFor="let info of element.value.info" class="text-muted text-truncate mb-0">{{info}}</p>
            </div>

            <div *ngIf="element.value.badges.length != 0" class="d-flex gap-1"
                 [ngStyle]="{'margin-right.px' : 30, 'margin-top.px': -9}">
              <div *ngFor="let badge of element.value.badges" class="badge" [ngClass]="badge.style"
                   placement="left"
                   [ngbTooltip]="badge.description"
                   triggers="click mouseenter:mouseleave">
                @if (badge.imgUrl) {
                  <img style="margin: 0.2em 0.3em 0.2em 0.5em; max-width: 20px; max-height: 20px;" src="{{badge.imgUrl}}">
                } @else {
                  <i [ngClass]="badge.icon"></i>
                }
              </div>
            </div>

            <input type="checkbox" class="form-check-input item-selector align-self-start mt-0 font-size-18 flex-shrink-0"
                   [checked]="addSelection.has(element.key)" (change)="onAddSelectionChange($event, element.key)"
                   [disabled]="maxLimitReached && !addSelection.has(element.key)">
          </div>

        </ng-container>

        <div *ngIf="(items.size - selectedItems.size) <= 0" class="p-2">
          <p class="m-2 text-center">
            {{ noItemsMessage != null ? noItemsMessage : ( "SELECT_LIST.EMPTY_ALL" | translate ) }}
          </p>
        </div>

      </div>
    </div>
    <div class="modal-footer">
      <button type="button" [disabled]="addSelection.size == 0" (click)="modal.close(); onAddSelected(); resetSearch();"
              [ngClass]="addSelection.size == 0 ? 'text-muted' : 'text-primary btn-outline-light'"
              class="btn">
        {{ "BUTTON.MULTI.ADD_COUNT" | translate:{count: addSelection.size} }}
      </button>
      <button type="button" class="btn btn-outline-light text-secondary" (click)="modal.close(); resetSearch();">
        {{ "BUTTON.CANCEL" | translate }}
      </button>
    </div>
  </ng-template>

</div>
